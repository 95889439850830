// Page1.js
import React, { useState, useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import {
  Typography,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Button,
  makeStyles,
} from '@material-ui/core';
import useGlobalStyles from '../../../../opengrowth/styles/CourseStyles';

// Define the styles using makeStyles
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    height: '100%', // Ensure the container takes at least the full height
    paddingBottom: theme.spacing(6), // Add space at the bottom for buttons
  },
  container: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: theme.spacing(2),
  },
  content: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(5),
  },
  buttonContainer: {
    position: 'absolute',
    bottom: theme.spacing(2),
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 2),
  },
  rightButtons: {
    display: 'flex',
    gap: theme.spacing(1),
  },
}));

/**
 * Page1 Component
 *
 * This component presents the user with a question to select their role:
 * "I'm a learner", "I'm an entrepreneur", or "I'm an expert".
 * The selection is saved to localStorage under the key 'signing_as'.
 *
 * Props:
 * - data: Initial selected value (optional).
 * - onDataChange: Callback when the selected value changes.
 * - reset: Boolean to reset the selection.
 * - onNext: Callback for the "Next" button.
 * - onSkip: Callback for the "Skip" button.
 * - onSkipAll: Callback for the "Skip All" button.
 * - onBack: Callback for the "Back" button.
 * - showBackButton: Boolean to show/hide the "Back" button.
 */
function Page1({
  data,
  onDataChange,
  reset,
  onNext,
  onSkip,
  onSkipAll,
  onBack,
  showBackButton,
}) {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();
  const theme = useTheme();

  // Initialize selectedValue from props.data or localStorage
  const initialSelectedValue = data || localStorage.getItem('signing_as') || '';
  const [selectedValue, setSelectedValue] = useState(initialSelectedValue);

  /**
   * Handle radio button selection change
   * - Updates the local state
   * - Calls the onDataChange callback
   * - Saves the selection to localStorage
   */
  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    onDataChange(value);
    localStorage.setItem('signing_as', value); // Save to localStorage
  };

  /**
   * Effect to reset the selection if the 'reset' prop changes to true
   */
  useEffect(() => {
    if (reset) {
      setSelectedValue('');
      localStorage.removeItem('signing_as'); // Remove from localStorage on reset
    }
  }, [reset]);

  /**
   * Effect to update the selectedValue when the 'data' prop changes
   * Also updates localStorage accordingly
   */
  useEffect(() => {
    if (data) {
      setSelectedValue(data);
      localStorage.setItem('signing_as', data); // Sync with localStorage
    }
  }, [data]);

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Typography
          variant="h5"
          component="h1"
          style={{
            paddingTop: theme.spacing(1),
            fontWeight: 500,
            color: '#303030',
          }}
          className={styleClasses.h5fontPara}
          gutterBottom
        >
          Which of these best describes you?
        </Typography>
        <RadioGroup
          aria-labelledby="user-type-question"
          value={selectedValue}
          onChange={handleChange}
          name="user-type"
          row
        >
          <Grid
            container
            className={classes.container}
            style={{
              margin: theme.spacing(2),
            }}
            spacing={2}
          >
            {/* Learner Option */}
            <Grid
              item
              xs={12}
              sm={4}
              style={{
                border: '0.5px solid #e0e0e0',
                borderRadius: '0.375rem',
                backgroundColor:
                  selectedValue === 'learner' ? '#f7f7f7' : '#ffffff',
              }}
            >
              <FormControlLabel
                value="learner"
                control={<Radio />}
                label="I'm a learner"
                style={{
                  height: '4em',
                  padding: theme.spacing(3),
                  marginLeft: 0,
                  width: '100%',
                  borderRadius: '0.375rem',
                }}
              />
            </Grid>

            {/* Entrepreneur Option */}
            <Grid
              item
              xs={12}
              sm={4}
              style={{
                border: '0.5px solid #e0e0e0',
                borderRadius: '0.375rem',
                backgroundColor:
                  selectedValue === 'entrepreneur' ? '#f7f7f7' : '#ffffff',
              }}
            >
              <FormControlLabel
                value="entrepreneur"
                control={<Radio />}
                label="I'm an entrepreneur"
                style={{
                  height: '4em',
                  padding: theme.spacing(3),
                  marginLeft: 0,
                  width: '100%',
                  borderRadius: '0.375rem',
                }}
              />
            </Grid>

            {/* Expert Option */}
            <Grid
              item
              xs={12}
              sm={4}
              style={{
                border: '0.5px solid #e0e0e0',
                borderRadius: '0.375rem',
                backgroundColor:
                  selectedValue === 'expert' ? '#f7f7f7' : '#ffffff',
              }}
            >
              <FormControlLabel
                value="expert"
                control={<Radio />}
                label="I'm an expert"
                style={{
                  height: '4em',
                  padding: theme.spacing(3),
                  marginLeft: 0,
                  width: '100%',
                  borderRadius: '0.375rem',
                }}
              />
            </Grid>
          </Grid>
        </RadioGroup>
      </Box>

      {/* Buttons Section */}
      <Box className={classes.buttonContainer}>
        {showBackButton && (
          <Button variant="text" onClick={onBack}>
            Back
          </Button>
        )}
        <Box className={classes.rightButtons}>
          <Button variant="text" onClick={onSkipAll}>
            Skip All
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onNext}
            disabled={!selectedValue}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default Page1;

// src/components/GrowthStudioHome.jsx

import React, { useState, useRef } from 'react';
import {
  Box,
  Typography,
  Card,
  Button,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  CircularProgress,
  Grid,
  InputAdornment,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import useGlobalStyles from '../styles/CourseStyles';
import promoImageSrc from '../assets/expert_banner2.png';

// Import the video for the banner
import bannerVideoSrc from '../assets/Startup_Service.mp4'; // Renamed for clarity
import contentVideo from '../assets/Contentmarketing.mp4'; // Renamed for clarity

// Import slick-carousel CSS
import 'slick-carousel/slick/slick.css';
import BenefitsCard from './BenefitsCard';
import FAQSection from '../Experts/FaqSection';

// Import the AlertBox component
import AlertBox from '../Courses/AlertBox';
import PricingCards from './PricingCard';
import VenturePricingPlan from './VenturePricingPlan';
import {generateID,
} from "../../../components/functions/Helper";
// Import Axios
import axios from 'axios';

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  mainCard: {
    boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
    borderRadius: '8px',
    overflow: 'hidden',
    margin: 'auto',
    border: 'none',
    padding: theme.spacing(3),
    paddingTop: theme.spacing(8),
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: 8,
    },
  },
  // Updated banner styles
  bannerContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    border: 'none',
    paddingLeft: '0rem',
    borderRadius: "4px",
    alignItems: 'flex-start',
    overflow: "hidden",
    width: '100%',
    [theme.breakpoints.up("md")]: {
      height: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      flexDirection: 'column', // Ensure vertical stacking on small screens
      alignItems: 'left',
    },
  },
  // Remove or adjust imageBox since we'll use video
  imageBox: {
    width: '100%',
    height: '500px', // Adjust the height as needed
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      height: '900px' // Adjust height for smaller screens
    },
    position: 'relative',
    // Add responsive styles for video
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  // Remove bannerImage styles since we're using video
  // Add styles for the video
  bannerVideo: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  contentOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      backgroundColor: 'rgba(37, 56, 124, 0.6)',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: theme.spacing(0, 2),
    },
    [theme.breakpoints.between("sm", "md")]: {
      backgroundColor: 'rgba(37, 56, 124, 0.6)',
      flexDirection: 'column',
      justifyContent: 'space-around',
      padding: theme.spacing(0, 2),
    },
  },
  contentBox: {
    backgroundColor: 'transparent',
    color: "white", // Adjust text color based on video
    marginTop: 32,
    maxWidth: '60%', // Adjust as needed

    paddingRight: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      maxWidth: '100% !important',
      width: '100%',
      textAlign: 'left',
      marginTop: theme.spacing(0),
      paddingRight: 0,
    },
  },
  formBox: {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    borderRadius: '8px',
    padding: theme.spacing(2), // Reduced padding
    maxWidth: '25%', // Reduced width from 30% to 25%
    [theme.breakpoints.down("sm")]: {
      maxWidth: '100%',
      marginTop: theme.spacing(2),
    },
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center the form elements
  },
  bannerButton: {
    marginTop: theme.spacing(1), // Reduced margin-top
    borderRadius: "50px",
    backgroundColor: "#f9bb02",
    color: "black",
    fontWeight: 600,
    fontSize: '0.9rem', // Slightly smaller font
    padding: '6px 16px', // Reduced padding
    textTransform: "none",
    cursor: 'pointer',
    "&:hover": {
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
      backgroundColor: "#f9bb02",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
      padding: "6px 12px",
    },
  },
  // New styles for smaller TextFields
  textField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '50px',
      height: '2.5em', // Reduced height
      padding: '0 12px', // Reduced horizontal padding
    },
    '& .MuiInputBase-input': {
      padding: 0,
      height: '100%',
      fontSize: '0.8rem', // Smaller font size
    },
  },
  subscribeRibbon: {
    backgroundColor: '#25387c', // Standout color for the ribbon
    width: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: theme.spacing(-4), // Adjust to overlay on the image
    marginBottom: theme.spacing(2),
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 4, // Higher z-index to appear above image and content
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      textAlign: 'center',
      padding: theme.spacing(2),
      marginTop: theme.spacing(-2),
    },
  },
  h3font: {
    fontWeight: "500",
    color: '#f9bb02',
    border: 'none'
  },
  h6fontheading: {
    color: '#fff',
  },
  h4font: {
    padding: '8px 12px',
    fontWeight: "600",
    fontSize: '49px',
    border: 'none'
  },
  h6font: {
    color: '#25387c',
    marginBottom: theme.spacing(2),
  },
  featureCard: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    padding: theme.spacing(3),
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    borderRadius: '8px',
    backgroundColor: '#ffffff',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    width: '100%',
    '&:hover': {
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      transform: 'translateY(-2px)',
      backgroundColor: '#0000000a',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: '2.6rem',
    fontFamily: 'MyCustomFont, sans-serif',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8em',
    },
  },
  searchBar: {
    backgroundColor: '#fff',
    borderRadius: '50px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '400px',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '50px',
      height: '2.1em',
      padding: '0 14px',
    },
    '& .MuiInputBase-input': {
      padding: 0,
      height: '100%',
      fontSize: '0.9rem',
    },
  },
  searchBarBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '2.5rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '16px',
    },
  },
  chipsearchbox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingLeft: '4px',
      paddingRight: '0px',
    },
  },
  searchButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: '#f9bb02',
    color: '#000',
    height: '2.4em',
    fontWeight: 'bold',
    borderRadius: '50px',
    '&:hover': {
      backgroundColor: '#d6a302',
    },
    whiteSpace: 'nowrap',
  },
  newHeading: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  filterChips: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
    },
  },
  filterChip: {
    [theme.breakpoints.down('sm')]: {
      flex: '0 0 40%',
      boxSizing: 'border-box',
      marginBottom: theme.spacing(1),
      paddingLeft: theme.spacing(0.4),
      paddingRight: theme.spacing(0.4),
    },
  },
  loadMoreButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  loadMoreButton: {
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    borderRadius: '50px',
    padding: '8px 24px',
    minWidth: '150px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
  loadingIndicator: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
  promoCardContainer: {
    display: 'flex',
    backgroundColor: '#25387c',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderRadius: '4px',
    border: 'none',
    marginTop: theme.spacing(6),
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  promoCard: {
    display: 'flex',
    border: 'none',
    flexDirection: 'column',
    backgroundColor: '#25387c',
    color: '#fff',
    padding: theme.spacing(4),
    borderRadius: '12px',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: theme.spacing(1.5),
      textAlign: 'center',
    },
  },
  promoImage: {
    width: '300px',
    height: '50%',
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      width: '200px',
      marginLeft: 0,
      marginTop: theme.spacing(3),
    },
    objectFit: 'cover',
  },
  promoTitle: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem',
    },
  },
  promoSubtitle: {
    fontSize: '1rem',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
  },
  promoButton: {
    marginTop: '16px',
    alignSelf: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'center',
    },
  },
  h6: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  // New styles for the video section
  videoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  video: {
    width: '100%',
    maxWidth: '100%',
    height: 'auto',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  span: {
    fontSize: '35px',
  }
}));

// BannerSlide Component
const BannerSlide = ({ onExplorePricingPlansClick }) => {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  // State for subscribe modal
  const [openSubscribe, setOpenSubscribe] = useState(false);
  const [subscribeEmail, setEmail] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] = useState(null); // null, 'success', 'error'
  const [isLoading, setIsLoading] = useState(false); // Loading state

  // States for Contact Us form
  const [name, setFullName] = useState('');
  const [email, setBusinessEmail] = useState('');
  const [number, setPhoneNumber] = useState('');
  const [company, setOrganization] = useState(''); // New State for Organization
  const [message, setMessage] = useState('');
  const [isFormLoading, setIsFormLoading] = useState(false);

  // States for AlertBox
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [alertType, setAlertType] = useState('info'); // 'success' or 'error'

  const handleOpenSubscribe = () => {
    setOpenSubscribe(true);
  };

  const handleCloseSubscribe = () => {
    setOpenSubscribe(false);
    setSubscriptionStatus(null);
    setEmail('');
    setIsLoading(false);
  };

  const handleSubscribe = () => {
    setIsLoading(true);
    // Make the API call to /api/subscribe using Axios
    axios.post('/api/send/email', { email })
      .then((response) => {
        console.log(response);
        
        setSubscriptionStatus('success');
        setEmail('');
      })
      .catch((error) => {
        console.error('Subscription error:', error);
        setSubscriptionStatus('error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Updated Handle Contact Us form submission using Axios
  const handleContactSubmit = (e) => {
    e.preventDefault();
    setIsFormLoading(true);

    // Prepare the data to send
    const formData = {
      name,
      email,
      number,
      company,
      message,
    };
    console.log(formData);
    

    // Make the API call to /api/startupService using Axios
    axios.post('/api/startupService', formData)
      .then((response) => {
        console.log(response.data);

        // Handle loading state
        setIsFormLoading(false);

        if (response.data === 'Insert successful') {
          // Show success message
          setAlertMsg("Thank you for signing up! You’re one step closer to sharing your expertise and making a meaningful impact. Our team will review your details and get back to you shortly.");
          setAlertType('success');
          setAlertOpen(true);

          // Optionally, redirect after a short delay
          // setTimeout(() => { window.location = '/dashboard'; }, 6000);
        }
        else {
          // Show warning message
          setAlertMsg("Email address already exists!");
          setAlertType('warning');
          setAlertOpen(true);
        }

        // Optionally, close the form or perform other actions
        // Currently, AlertBox handles the message display
      })
      .catch((error) => {
        console.error('Form submission error:', error);

        // Show error message
        setAlertMsg("Registration failed. Please try again.");
        setAlertType('error');
        setAlertOpen(true);

        // Optionally, perform other actions
      })
      .finally(() => {
        setIsFormLoading(false);
      });
  };

  // Handle closing the AlertBox
  const handleAlertClose = () => {
    setAlertOpen(false);
    setAlertMsg('');
    setAlertType('info');
  };

  return (
    <>
      <Box className={`${styleClasses.bannerContainer} ${classes.bannerContainer}`}>
        {/* Banner Video */}
        <Box className={classes.imageBox}>
          <video
            src={bannerVideoSrc} // Use the video source instead of image
            autoPlay
            muted
            loop
            playsInline
            className={classes.bannerVideo}
          >
            Your browser does not support the video tag.
          </video>

          {/* Content Overlay */}
          <Box className={classes.contentOverlay}>
            <Box className={classes.contentBox}>
              <Typography
                variant={isSmall ? "h4" : "h1"}
                gutterBottom
                className={`${styleClasses.h3font} ${classes.h3font}`}
              >
                Startup Services to Build, Engage, and Elevate
              </Typography>
              <Typography
                variant={isSmall ? "h6" : "h6"}
                gutterBottom
                className={`${styleClasses.h6banner} ${classes.h6fontheading}`}
              >
                Our <span style={{color: '#f9bb02'}}>Growth Expert</span> and <span style={{color: '#f9bb02'}}>Venture Assistance</span> services, powered by AI and driven by experts ensure your brand shines online.
              </Typography>
              <Button
                variant="contained"
                className={`${styleClasses.individualButton} ${classes.promoButton}`}
                onClick={onExplorePricingPlansClick}
              >
                Explore Pricing Plans
              </Button>
            </Box>

            {/* Contact Us Form */}
            <Box className={classes.formBox}>
              <Typography variant="h6" gutterBottom className={styleClasses.h6font} align='center'>
                Contact Us
              </Typography>
              <form onSubmit={handleContactSubmit} style={{ width: '100%' }}>
                <Grid container spacing={1}> {/* Reduced spacing from 2 to 1 */}
                  <Grid item xs={12}>
                    <TextField
                      label="Full Name"
                      variant="outlined"
                      fullWidth
                      required
                      value={name}
                      onChange={(e) => setFullName(e.target.value)}
                      aria-label="Full Name"
                      size="small" // Added size
                      className={classes.textField} // Applied custom styles
                      margin="dense" // Reduced margin
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Business Email"
                      type="email"
                      variant="outlined"
                      fullWidth
                      required
                      value={email}
                      onChange={(e) => setBusinessEmail(e.target.value)}
                      aria-label="Business Email"
                      size="small"
                      className={classes.textField}
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Phone Number"
                      variant="outlined"
                      fullWidth
                      required
                      value={number}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      aria-label="Phone Number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            +91 {/* You can integrate a country code selector if needed */}
                          </InputAdornment>
                        ),
                      }}
                      size="small"
                      className={classes.textField}
                      margin="dense"
                    />
                  </Grid>
                  {/* Organization Field Placed Before Message */}
                  <Grid item xs={12}>
                    <TextField
                      label="Organization"
                      variant="outlined"
                      fullWidth
                      required
                      value={company}
                      onChange={(e) => setOrganization(e.target.value)}
                      aria-label="Organization"
                      size="small"
                      className={classes.textField}
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Message"
                      variant="outlined"
                      fullWidth
                      required
                      multiline
                      rows={2} // Increased number of rows for taller text box
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      aria-label="Message"
                      size="small"
                      className={classes.textField}
                      margin="dense"
                    />
                  </Grid>
                  {isFormLoading && (
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="center">
                        <CircularProgress size={24} /> {/* Reduced size */}
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      className={classes.bannerButton}
                      disabled={
                        !name || !email || !number || !company || !message || isFormLoading
                      }
                    >
                      Get Started
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Box>
        </Box>

        {/* Subscribe Modal */}
        <Dialog open={openSubscribe} onClose={handleCloseSubscribe}>
          <DialogTitle>Subscribe to Our Newsletter</DialogTitle>
          <DialogContent>
            <FormControl fullWidth>
              <TextField
                label="Email Address"
                type="email"
                variant="outlined"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                aria-label="Email Address"
                size="small"
                className={classes.textField}
                margin="dense"
              />
            </FormControl>
            {isLoading && (
              <Box display="flex" justifyContent="center" mt={2}>
                <CircularProgress size={24} /> {/* Reduced size */}
              </Box>
            )}
            {subscriptionStatus === 'success' && (
              <Typography color="primary" align="center" style={{ marginTop: theme.spacing(2) }}>
                Successfully subscribed!
              </Typography>
            )}
            {subscriptionStatus === 'error' && (
              <Typography color="error" align="center" style={{ marginTop: theme.spacing(2) }}>
                Subscription failed. Please try again.
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSubscribe} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleSubscribe}
              color="primary"
              variant="contained"
              disabled={!email || isLoading}
            >
              Subscribe
            </Button>
          </DialogActions>
        </Dialog>

        {/* AlertBox Component */}
        <AlertBox
          open={alertOpen}
          onClose={handleAlertClose}
          msg={alertMsg}
          msg_type={alertType}
        />
        </Box>
      </>
  )
};

// ContentMarketingHome Component
const ContentMarketingHome = () => {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();
  const theme = useTheme();
  const history = useHistory();
  const pricingCardsRef = useRef(null);

  // Define the scroll handler
  const handleExplorePricingPlansClick = () => {
    if (pricingCardsRef.current) {
      pricingCardsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Box style={{ padding: '8px', paddingTop: 0 }}>
      {/* Header Component (if any) */}
      {/* <Header /> */}

      {/* Banner Carousel */}
      <BannerSlide onExplorePricingPlansClick={handleExplorePricingPlansClick}/>

      <BenefitsCard />
      <Box ref={pricingCardsRef}>
        <PricingCards />
      </Box>
      <VenturePricingPlan />

      {/* New Video Section */}
      <Box className={classes.videoContainer}>
        <video
          src={contentVideo} // Changed to bannerVideoSrc if you want to reuse the same video
          controls
          autoPlay
          muted
          playsInline
          className={classes.video}
        >
          Your browser does not support the video tag.
        </video>
      </Box>

      <FAQSection context="contentMarketing" />

      {/* Promotional Card */}
      <Box className={classes.promoCardContainer}>
        {/* Promotional Text Content */}
        <Card className={classes.promoCard}>
          <Box>
            <Typography
              variant="h4"
              className={`${styleClasses.h4font}`}
              style={{ color: 'white' }}
            >
              Scale Smarter, Not Harder—Leverage Fractional
              Experts Today!
            </Typography>
            <Typography variant="h6" className={`${styleClasses.description} ${classes.h6}`}>
              Drive growth with flexible, high-impact experts that adapts to your business needs.
            </Typography>
            <Button
              variant="contained"
              className={`${styleClasses.individualButton} ${classes.promoButton}`}
              onClick={() => {
                window.location.href =
                  'https://calendly.com/vedika-5m0y/30min?month=2024-10'; // Replace with your desired URL
              }}
            >
              Book a Discovery Call
            </Button>
          </Box>
        </Card>

        {/* Promotional Image */}
        <img
          src={promoImageSrc} // Ensure this path is correct or replace with your desired image URL
          alt="Growth Associate"
          className={classes.promoImage}
        />
      </Box>
    </Box>
  );
};

export default ContentMarketingHome;

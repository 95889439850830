
import React from 'react';
//export const Header = React.lazy(() => import('./sidebars/Header'));

export const ProfessorRegister = React.lazy(() => import('./users/register/professor/ProfessorRegister'));
export const ProfessorDashboard = React.lazy(() => import('./dashboard/ProfessorDashboard'));

export const StudentRegister = React.lazy(() => import('./users/register/student/StudentRegister'));
export const ExpertConnectRegister = React.lazy(() => import('../opengrowth/Experts/ExpertConnectRegister'));
export const CourseRegister = React.lazy(() => import('../opengrowth/Courses/CourseRegister'));
export const StudentDashboard = React.lazy(() => import('./dashboard/StudentDashboard'));
export const EntrepreneurDashboard = React.lazy(() => import('./dashboard/Entrepreneur/EntrepreneurDashboard'));
export const ExpertDashboard = React.lazy(() => import('./dashboard/Expert/ExpertDashboard'));
export const ProfessorCourseBlock = React.lazy(() => import('./dashboard/ProfessorCourseBlock'));
export const LoginCard = React.lazy(() => import('./users/login/LoginCard'));
export const ForgotPassCard = React.lazy(() => import('./users/forgot/ForgotPassCard'));
export const Scroll = React.lazy(() => import('./dashboard/Scroll'));

export const Build_Course_Structure = React.lazy(() => import('./courses/structure/Build_Course_Structure'));
export const CourseComponents = React.lazy(() => import('./courses/units'));
export const CourseAccordian = React.lazy(() => import('./courses/stepper/CourseAccordian'));
export const CourseAddStepper = React.lazy(() => import('./courses/stepper/CourseAddStepper'));
//export const CourseView = React.lazy(() => import('./courses/courseshow/CourseView'));
export const CourseViewNewPage = React.lazy(() => import('./courses/courseshownew/CourseView'));
export const CourseViewTop = React.lazy(() => import('./courses/courseshownew/CourseViewTop'));
export const ViewedRecently = React.lazy(() => import('./courses/ViewedRecently'));
export const PopularCourse = React.lazy(() => import('./courses/PopularCourse'));
export const CoursesBookmarked = React.lazy(() => import('./courses/CoursesBookmarked'));
export const CourseTagsSearch = React.lazy(() => import('./courses/CourseTagsSearch'));
export const TrendingCourses = React.lazy(() => import('./courses/TrendingCourses'));

export const CourseSort = React.lazy(() => import('./courses/CourseSort'));
export const MyCourses = React.lazy(() => import('./courses/MyCourses'));
export const CourseEnrolled = React.lazy(() => import('./courses/CourseEnrolled'));
export const CalenderCard = React.lazy(() => import('./courses/CalenderCard'));
export const ProfileView = React.lazy(() => import('./profile/ProfileView'));

export const Quiz = React.lazy(() => import('./quiz/Quizs'));
export const QuizStart = React.lazy(() => import('./quiz/QuizrWelCard'));
export const QuizProgress = React.lazy(() => import('./quiz/QuizProgress'));
export const CartStepForm = React.lazy(() => import('./cart/CartStepForm'));





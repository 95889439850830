// src/components/Banner.jsx

import React from 'react';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { PopupButton } from 'react-calendly';
import Slider from 'react-slick';

// Import slick-carousel CSS
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Import global styles if any
import useGlobalStyles from '../styles/CourseStyles';

// Import banner images
import expert_banner1 from '../assets/expert_banner1.png';
import expert_banner2 from '../assets/expert_banner2.png';
// Add more images as needed

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  bannerWrapper: {
    background: 'linear-gradient(to right, #525252, #212121)', // Background gradient
    width: '100%',
    padding: theme.spacing(0, 0),
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: theme.spacing(1, 2, 0, 2),
    },
  },
  slider: {
    width: '100%', // Ensure slider takes full width
    '& .slick-slide': {
      display: 'flex !important',
      justifyContent: 'center',
      flexWrap: 'nowrap',
    },
    '& .slick-dots': {
      bottom: theme.spacing(2),
    },
    '& .slick-arrow': {
      zIndex: 1, // Ensure arrows are above other elements
    },
    // Customize dots and arrows if needed
    '& .slick-dots li button:before': {
      color: '#f9bb02', // Dot color
    },
    '& .slick-prev:before, .slick-next:before': {
      color: '#f9bb02', // Arrow color
    },
  },
  slide: {
    display: 'flex !important',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 6),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      padding: theme.spacing(1, 0),
    },
  },
  content: {
    width: '55%',
    flex: 1,
    color: '#fff',
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
      textAlign: 'left',
      width: '100%',
    },
  },
  title: {
    color: '#f9bb02', // Matches the h3font color
    lineHeight: '75px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8rem',
      lineHeight: '1.3',
    },
  },
  bannerButton: {
    padding: '12px 24px',
    cursor: "pointer",
    '&:hover': {
      backgroundColor: '#d6a302',
    },
    [theme.breakpoints.down('md')]: {
      alignSelf: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '10px 20px',
    },
  },
  imageContainer: {
    flex: 1,
    width: '100%',
    textAlign: 'right',
    marginLeft: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
      marginLeft: theme.spacing(0),
      marginTop: theme.spacing(0),
    },
  },
  image: {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
    borderRadius: '8px',
    maxWidth: '500px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '300px',
    },
  },
}));

// Banner Component with Carousel
const Banner = () => {
  const classes = useStyles();
  const styleClasses = useGlobalStyles(); // Ensure this hook exists and is correctly implemented
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  // Slider settings
  const settings = {
    infinite: true, // Loop through slides
    speed: 500, // Transition speed in milliseconds
    slidesToShow: 1, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll on each action
    autoplay: true, // Enable autoplay
    arrows: false,
    autoplaySpeed: 5000, // Autoplay speed in milliseconds
    adaptiveHeight: true, // Adjust slide height based on content
  };

  // Define slides data with different headings and content
  const slides = [
    {
      title: 'Unlock Expert Knowledge from Those Who’ve Mastered the Process',
      subtitle:
        'Get a Growth Expert to Lead Your Team. Our fractional experts bring AI startup experience, cultural adaptability, and the specific skills your team needs to boost performance and efficiency.',
      calendlyUrl: 'https://calendly.com/vedika-5m0y/30min?month=2024-10', // Replace with your actual Calendly URL
      imageUrl: expert_banner2,
    },
    // Add more slides with different headings and images as needed
  ];

  return (
    <Box className={classes.bannerWrapper}>
      <Slider {...settings} className={classes.slider}>
        {slides.map((slide, index) => (
          <Box key={index} className={classes.slide}>
            {/* Content Section */}
            <Box className={classes.content}>
              <Typography
                className={`${styleClasses.h3font} ${classes.title}`}
                variant={isSmall ? 'h5' : 'h1'}
              >
                {slide.title}
              </Typography>
              <Typography
                variant={isSmall ? 'subtitle1' : 'h6'}
                className={`${styleClasses.h6banner} ${styleClasses.h6HeadingDesc}`}
                align={isSmall ? 'left' : 'left'}
              >
                {slide.subtitle}
              </Typography>
              <PopupButton
                url={slide.calendlyUrl}
                rootElement={document.getElementById('root') || undefined}
                text="Book a call with our team"
                className={`${styleClasses.individualButton} ${classes.bannerButton}`}
                variant="contained"
              />
            </Box>

            {/* Image Section */}
            <Box className={classes.imageContainer}>
              <img
                src={slide.imageUrl}
                alt={`Expert Banner ${index + 1}`}
                className={classes.image}
                loading="lazy" // Improves performance by lazy loading images
              />
            </Box>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default Banner;

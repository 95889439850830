// src/components/admin/dashboard/AdminDashboard.jsx

import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { Academytheme } from '../useStyles'; // Adjust the path as necessary
import axios from 'axios';

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
  },
  table: {
    minWidth: 650,
  },
  approved: {
    color: 'green',
    fontWeight: 'bold',
  },
  rejected: {
    color: 'red',
    fontWeight: 'bold',
  },
  pending: {
    color: 'orange',
    fontWeight: 'bold',
  },
  actionButton: {
    marginRight: theme.spacing(1),
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  textarea: {
    width: '100%',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    resize: 'vertical',
  },
}));

// Utility function to generate time slots
const generateTimeSlots = (startTime = '09:00', endTime = '17:00', interval = 30) => {
  const slots = [];
  let [startHour, startMinute] = startTime.split(':').map(Number);
  const [endHour, endMinute] = endTime.split(':').map(Number);

  while (
    startHour < endHour ||
    (startHour === endHour && startMinute < endMinute)
  ) {
    const start = new Date();
    start.setHours(startHour, startMinute, 0, 0);

    let slotEndHour = startHour;
    let slotEndMinute = startMinute + interval;
    if (slotEndMinute >= 60) {
      slotEndHour += 1;
      slotEndMinute -= 60;
    }

    const end = new Date();
    end.setHours(slotEndHour, slotEndMinute, 0, 0);

    const options = { hour: 'numeric', minute: 'numeric' };
    const slotString = `${start.toLocaleTimeString([], options)} - ${end.toLocaleTimeString([], options)}`;
    slots.push(slotString);

    startHour = slotEndHour;
    startMinute = slotEndMinute;
  }

  return slots;
};

const AdminDashboard = () => {
  const classes = useStyles();

  const [callRequests, setCallRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  // Rejection States
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const [requestToReject, setRequestToReject] = useState(null);

  // Approval States
  const [approveDialogOpen, setApproveDialogOpen] = useState(false);
  const [approveRequest, setApproveRequest] = useState(null);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
  const [expertName, setExpertName] = useState('');

  // Loading and Error States
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);


  const formatDate = (date) => {
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are 0-based in JS
    const day = ('0' + date.getDate()).slice(-2);
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };
  // Fetch call requests from API on component mount
  useEffect(() => {
    setLoading(true);
    setError(null);

    axios.get("/api/get/admin/call_request")
      .then((response) => {
        console.log(response);
        if (response.data && Array.isArray(response.data)) {
          setCallRequests(response.data);
        } else {
          setError('Invalid data format received.');
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching call_requests:", error);
        if (error.response) {
          console.error('Error data:', error.response.data);
          setError(error.response.data.message || 'Failed to fetch call requests.');
        } else {
          setError('Failed to fetch call requests.');
        }
        setLoading(false);
      });
  }, []);

  // Handle Approve action - Open Approval Dialog
  const handleApprove = (request_id) => {
    setApproveRequest(request_id);
    setSelectedDate(new Date()); // Initialize with current date
    setSelectedTimeSlot(''); // Reset previous time slot
    setApproveDialogOpen(true);
  };

  // Submit Approval with selected date and time slot
  const submitApprove = () => {
    if (!selectedDate || !selectedTimeSlot) {
      alert('Please select date, time slot, and enter expert name for approval.');
      return;
    }

    const formattedScheduledDate = formatDate(selectedDate);
   

    // Make API call to approve the request
    axios.post('/api/update/call_request', {
      request_id : approveRequest,
      scheduledDate : formattedScheduledDate,
      scheduledTimeSlot : selectedTimeSlot,
      status : 'approved'
    })
      .then(function (response) {
        console.log(response);
        
        if (response.status === 200) {
          // Update local state
          const updatedRequests = callRequests.map((request) => {
            if (request.id === approveRequest.id) {
              return {
                ...request,
              };
            }
            return request;
          });

          console.log('Updated Requests after Approval:', updatedRequests);
          setCallRequests(updatedRequests);
          window.location.reload();
        } else {
          throw new Error('Failed to approve the call request.');
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.error('Error data:', error.response.data);
          console.error('Error status:', error.response.status);
          console.error('Error headers:', error.response.headers);
          alert(`Error: ${error.response.data.message || 'An error occurred.'}`);
        } else if (error.request) {
          console.error('No response received:', error.request);
          alert('No response from the server. Please try again later.');
        } else {
          console.error('Error:', error.message);
          alert(`Error: ${error.message}`);
        }
      })
      .finally(function () {
        // Close the dialog and reset states
        setApproveDialogOpen(false);
        setApproveRequest(null);
        setSelectedDate(new Date());
        setSelectedTimeSlot('');
        setExpertName('');
      });
  };

  // Handle Reject action
  const handleReject = (request) => {
    console.log(request);
    
    setRequestToReject(request);
    setRejectReason(''); // Reset previous reason
    setRejectDialogOpen(true);
    
  };

  // Submit Rejection Reason
  const submitReject = () => {
    if (!rejectReason.trim()) {
      alert('Please provide a reason for rejection.');
      return;
    }

    console.log(rejectReason);
    
    axios.post('/api/update/call_request', {
      request_id : requestToReject,
      reason : rejectReason,
      status : 'rejected'
    })
      .then(function (response) {
        console.log(response);
        
        if (response.status === 200) {
          // Update local state
          const updatedRequests = callRequests.map((request) => {
            if (request.id === requestToReject.id) {
              return {
                ...request,
              };
            }
            return request;
          });

          console.log('Updated Requests after rejected:', updatedRequests);
          setCallRequests(updatedRequests);
          // window.location.reload();
        } else {
          throw new Error('Failed to approve the call request.');
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.error('Error data:', error.response.data);
          console.error('Error status:', error.response.status);
          console.error('Error headers:', error.response.headers);
          alert(`Error: ${error.response.data.message || 'An error occurred.'}`);
        } else if (error.request) {
          console.error('No response received:', error.request);
          alert('No response from the server. Please try again later.');
        } else {
          console.error('Error:', error.message);
          alert(`Error: ${error.message}`);
        }
      })
      .finally(function () {
        // Close the dialog and reset states
        setRejectDialogOpen(false);
        setRequestToReject(null);
        // setRejectReason('');

      });
  };

  // Close Rejection Dialog
  const closeRejectDialog = () => {
    console.log('Closing rejection dialog without submitting.');
    setRejectDialogOpen(false);
    setRequestToReject(null);
    setRejectReason('');
  };

  // Handle View Details (optional)
  const handleViewDetails = (request) => {
    console.log('Viewing details for request:', request);
    setSelectedRequest(request);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedRequest(null);
  };

  
  return (
    <ThemeProvider theme={Academytheme}>
      <div className={classes.root}>
        <Typography variant="h4" className={classes.header}>
          Admin Dashboard
        </Typography>

        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error" align="center">
            {error}
          </Typography>
        ) : (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="call requests table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>User Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Scheduled Date</TableCell>
                  <TableCell>Scheduled Time Slot</TableCell>
                  <TableCell>Expert</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Reason</TableCell> {/* New Column for Reason */}
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {callRequests.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      No Call Requests Found
                    </TableCell>
                  </TableRow>
                ) : (
                  callRequests.map((request) => (
                    <TableRow key={request.id}>
                      <TableCell>{request.request_id}</TableCell>
                      <TableCell>{`${request.fname} ${request.lname}`}</TableCell>
                      <TableCell>{request.email}</TableCell>
                      <TableCell>
                        {request.scheduledDate
                          ? request.scheduledDate
                          : '-'}
                      </TableCell>
                      <TableCell>
                        {request.scheduledTimeSlot
                          ? request.scheduledTimeSlot
                          : '-'}
                      </TableCell>
                      <TableCell>{request.expertName || '-'}</TableCell>
                      <TableCell>
                        <Typography
                          className={
                            request.status && request.status.toLowerCase() === 'Approved'
                              ? classes.approved
                              : request.status && request.status.toLowerCase() === 'Rejected'
                              ? classes.rejected
                              : classes.pending
                          }
                        >
                          {request.status}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {console.log(request.reason)}
                        {request.status && request.status.toLowerCase() === 'rejected' ? request.reason : ''}
                      </TableCell>
                      <TableCell>
                        {request.status && request.status.toLowerCase() === 'pending' ? (
                          <>
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              className={classes.actionButton}
                              startIcon={<FaCheck />}
                              onClick={() => handleApprove(request.request_id)} // Pass the entire request
                            >
                              Approve
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              size="small"
                              startIcon={<FaTimes />}
                              onClick={() => handleReject(request.request_id)}
                            >
                              Reject
                            </Button>
                          </>
                        ) : (
                          <Typography variant="body2">
                            {request.status}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {/* Optional: Dialog to view request details */}
        <Dialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          aria-labelledby="request-details-dialog"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="request-details-dialog">Call Request Details</DialogTitle>
          <DialogContent>
            {selectedRequest && (
              <Box>
                <Typography>
                  <strong>ID:</strong> {selectedRequest.id}
                </Typography>
                <Typography>
                  <strong>Name:</strong> {selectedRequest.fname} {selectedRequest.lname}
                </Typography>
                <Typography>
                  <strong>Email:</strong> {selectedRequest.email}
                </Typography>
                <Typography>
                  <strong>Scheduled Date:</strong>{' '}
                  {selectedRequest.scheduledDate
                    ? selectedRequest.scheduledDate
                    : '-'}
                </Typography>
                <Typography>
                  <strong>Scheduled Time Slot:</strong>{' '}
                  {selectedRequest.scheduledTimeSlot
                    ? selectedRequest.scheduledTimeSlot
                    : '-'}
                </Typography>
                <Typography>
                  <strong>Expert:</strong> {selectedRequest.expertName || '-'}
                </Typography>
                <Typography>
                  <strong>Status:</strong> {selectedRequest.status}
                </Typography>
                <Typography>
                  <strong>Requested At:</strong>{' '}
                  {new Date(selectedRequest.requestedAt).toLocaleString()}
                </Typography>
                {selectedRequest.status && selectedRequest.status.toLowerCase() === 'Rejected' &&
                  selectedRequest.reason && (
                    <Typography>
                      <strong>Reason for Rejection:</strong> {selectedRequest.reason}
                    </Typography>
                  )}
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* Rejection Reason Dialog */}
        <Dialog
          open={rejectDialogOpen}
          onClose={closeRejectDialog}
          aria-labelledby="reject-dialog-title"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="reject-dialog-title">Reject Call Request</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              Please provide a reason for rejecting this call request:
            </Typography>
            <textarea
              value={rejectReason}
              onChange={(e) => setRejectReason(e.target.value)}
              rows="4"
              className={classes.textarea}
              placeholder="Enter reason here..."
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeRejectDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={submitReject} color="secondary" variant="contained">
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        {/* Approval Dialog */}
        <Dialog
          open={approveDialogOpen}
          onClose={() => setApproveDialogOpen(false)}
          aria-labelledby="approve-dialog-title"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="approve-dialog-title">Approve Call Request</DialogTitle>
          <DialogContent>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="Select Date"
                format="MM/dd/yyyy"
                value={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                fullWidth
              />
            </MuiPickersUtilsProvider>
            <FormControl fullWidth margin="normal">
              <InputLabel id="time-slot-label">Select Time Slot</InputLabel>
              <Select
                labelId="time-slot-label"
                id="time-slot-select"
                value={selectedTimeSlot}
                onChange={(e) => setSelectedTimeSlot(e.target.value)}
              >
                {generateTimeSlots().map((slot, index) => (
                  <MenuItem key={index} value={slot}>
                    {slot}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setApproveDialogOpen(false)} color="primary">
              Cancel
            </Button>
            <Button
              onClick={submitApprove}
              color="primary"
              variant="contained"
              disabled={!selectedDate || !selectedTimeSlot}
            >
              Approve
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </ThemeProvider>
  );
};

export default AdminDashboard;

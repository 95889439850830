export const blogData = [
    {
        image: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/article/2024/blog-1730120763.png",
        title: "Artificial Intelligence and Academic Integrity: Striking A Balance",
        author: "OpenGrowth Content Team",
        authorImage: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/users/small/1721211340-opengrowth-logo-png.png",
        category: "Startup Basics",
        link: "https://www.blogs.opengrowth.com/artificial-intelligence-and-academic-integrity-striking-a-balance"
    },
    {
        image: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/article/2024/blog-1731079565.png",
        title: "What is the Game Playing Algorithm In AI?",
        author: "OpenGrowth Content Team",
        authorImage: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/users/small/1721211340-opengrowth-logo-png.png",
        category: "Industry Insights with Experts",
        link: "https://www.blogs.opengrowth.com/what-is-the-game-playing-algorithm-in-ai"
    },
    {
        image: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/article/2024/blog-1730914470.png",
        title: "Tools and Middleware Program for PlayStation®5 and PlayStation®4 Systems",
        author: "OpenGrowth Content Team",
        authorImage: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/users/small/1721211340-opengrowth-logo-png.png",
        category: "Remote Work",
        link: "https://www.blogs.opengrowth.com/tools-and-middleware-program-for-playstation-systems"
    },
    {
        image: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/article/2024/blog-1730914165.png",
        title: "Integrating AI Bots in QA: A Turning Point in Video Game Development",
        author: "OpenGrowth Content Team",
        authorImage: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/users/small/1721211340-opengrowth-logo-png.png",
        category: "Future Tech",
        link: "https://www.blogs.opengrowth.com/integrating-ai-bots-in-qa-a-turning-point-in-video-game-development"
    },
    {
        image: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/article/2024/business-tech-1730890166.png",
        title: "Understanding How to Utilize Technology Within Your Business",
        author: "OpenGrowth Content Team",
        authorImage: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/users/small/1721211340-opengrowth-logo-png.png",
        category: "Artificial Intelligence",
        link: "https://www.blogs.opengrowth.com/understanding-how-to-utilize-technology-within-your-business"
    },
    {
        image: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/article/2024/em-1730289670.png",
        title: "How to Boost Ecommerce Sales with Expert Email Marketing Strategies",
        author: "Mallika Khandelwal",
        authorImage: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/users/small/1709736699-img-20240226-194733701-1-jpg.jpg",
        category: "Sustainability",
        link: "https://www.blogs.opengrowth.com/how-to-boost-ecommerce-sales-with-expert-email-marketing-strategies"
    },
    {
        image: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/article/2024/blog-1730120172.png",
        title: "Adopting Enterprise in the AI Era",
        author: "OpenGrowth Content Team",
        authorImage: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/users/small/1721211340-opengrowth-logo-png.png",
        category: "Digital Marketing",
        link: "https://www.blogs.opengrowth.com/adopting-enterprise-in-the-ai-era"
    },
    {
        image: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/article/2024/blog-image-5-1730119595.png",
        title: "AI in Gaming: How AI Creates Intelligent Game Characters and Opponents",
        author: "Nishi Chawla",
        authorImage: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/users/small/1722947421-profile-photo-jpeg.jpeg",
        category: "Blockchain",
        link: "https://www.blogs.opengrowth.com/ai-in-gaming-how-ai-creates-intelligent-game-characters-and-opponents"
    },
    {
        image: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/article/2024/blog-1730119552.png",
        title: "How to Find and Address the Grey Areas of AI Ethics",
        author: "Pricing Strategist",
        authorImage: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/users/small/1721211340-opengrowth-logo-png.png",
        category: "Business Strategy",
        link: "https://www.blogs.opengrowth.com/how-to-find-and-address-the-grey-areas-of-ai-ethics"
    },
    {
        image: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/article/2024/cross-selling-customer-1729857279.png",
        title: "Seamless Cross-Selling: Driving Revenue Through Member-Centric Offers",
        author: "Mallika Khandelwal",
        authorImage: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/users/small/1709736699-img-20240226-194733701-1-jpg.jpg",
        category: "Team Management",
        link: "https://www.blogs.opengrowth.com/seamless-cross-selling-driving-revenue-through-member-centric-offers"
    },
];
// manualExperts.js

export const manualExperts = [
    {
        name: "Vineet Singh",
        category: "AI driven marketing expert",
        industry: "AI expert",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/vineet-singh.jpeg",
        profile_url: "vineet-singh",
        email: "vineet@example.com",
        about: "A results-driven Chief Digital Officer with 18+ years of experience in leading digital transformation, marketing, and product engineering to drive innovation and profitability.",
        yoe: '18+ years of experience',
        location: 'Mysore, Karnataka, India',
        long_about: [
            "I am a hands-on Chief Digital Officer with a proven track record in the marketing and advertising industry. I specialize in IT, digital transformation, omnichannel e-commerce, and integrated marketing strategies. ",
            "I have led over 200 high-ROI projects, generating more than $350 million in revenue for clients and partners. I’ve driven product engineering for six key products in the digital media ecosystem, leveraging AI, ML, and automation to deliver value and innovation. ",
            "With 18+ years of experience, I consistently deliver results in digital strategy, web analytics, global program management, and client engagement, while effectively managing teams across geographies."
        ],
        skills: ['Chief Marketing Officer', 'Growth Strategy Expert'],
        offers: [
            "Digital Marketing Strategy",
            " B2B / Enterprise SAAS Growth Strategy",
            "B2B content marketing strategy",
        ],
        rating: 4.2,
        price: "$175/Hour"
    },
    {
        name: "Ashwini Kumar",
        category: "Next-Generation AI solutions expert",
        industry: "AI expert",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/ashwini-kumar.png",
        profile_url: "ashwani-kumar",
        email: "ashwani@example.com",
        about: "Specializing in leading the development of AI/ML products at scale for startups, including next-gen AI solutions, and deploying NLP and deep learning models to solve complex business challenges.",
        long_about: "With over 12 years of hands-on experience, I specialize in leading the development of AI/ML products at scale for startups, including next-gen AI solutions. My expertise lies in leading data science teams and deploying NLP and deep learning models to solve complex business challenges. If you are looking for a seasoned professional with a proven track record in scaling AI/ML products for startups and large organizations, let's connect and discuss how I can bring value to your project.",
        yoe: '12+ years of experience',
        location: 'Bengaluru, Karnataka, India',
        skills: ['Next-Generation AI solutions Expert'],
        offers: [
            "Creating AI/ML (including Gen AI) Products at scale",
            "Data Strategy",
            "building data science teams from scratch",
            "Developing &amp; Deploying ML Models at scale",
            "Buy vs Build for existing products",
        ],
        rating: 4.2,
        price: "$80/Hour"
    },
    {
        name: "Deepthi Samineni",
        category: "Fractional CMO",
        industry: "Fractional CMO",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/deepthi.jpg",
        profile_url: "deepthi-samineni",
        email: "deepthi@example.com",
        about: "With over 15 years of experience in B2B SaaS marketing across HR Tech, IT Service Management, Insurance Telematics, AI Voice Platforms, and more, Deepthi brings the strategic insight needed to accelerate your SaaS growth without the full-time executive cost.",
        yoe: '15+ years of experience',
        location: 'Hyderabad, Telangana, India',
        long_about: [
            "50M+ Revenue Influence — Over my career, I've influenced revenue exceeding $50 million, demonstrating my ability to drive significant business impact in the SaaS sector.",
            "14.47% CAGR Growth - At INRY, I helped drive revenue growth to 14.47 CAGR in 4 years, through strategic GTM initiatives between 2019 and 2023.",
            "1000+ Active Customers in 3 Months for D2C Brand - Launched BlocQ in 2024, a new mosquito repellent D2C e-commerce brand in India, achieving product-market fit within three months by acquiring over 1000 active customers.",
            "AI Startup Growth from 0 to 1 - Currently overseeing the MVP launch of ContactSwing, an AI-based SaaS startup serving the US market's real estate, insurance, and hospitality sectors, driving initial traction and user acquisition.",
            "Whether you're a SaaS company, a D2C brand, or an AI startup, let's discuss how I can help you optimize your marketing strategy, increase user acquisition, and drive sustainable growth for your business.",
            "By choosing me as your Fractional CMO, you're not just hiring a consultant – you're partnering with a seasoned marketing leader committed to driving your business growth through strategic, innovative, and results-driven marketing initiatives."
        ],
        skills: ['Fractional CMO', 'SaaS marketing expert'],
        offers: [
            "Marketing Strategy Development",
            "Digital Marketing Optimization",
            "Marketing Team Leadership",
            "Marketing Technology Stack Optimization",
            "Budget Management and ROI Optimization",
            "Growth Marketing Initiatives",
            "AI-Driven Marketing Strategies"
        ],
        rating: 4.2,
    },
    {
        name: "Tushar Kansal",
        category: "Fractional CFO",
        industry: "Fractional CFO",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/tushar-kansal.png",
        profile_url: "tushar-kansal",
        email: "tushar@example.com",
        about: "Empowering startups and growth-stage companies globally through strategic investments and expert guidance.",
        yoe: '35+ years of experience',
        location: 'Delhi, India',
        long_about: [
            "I am the Founder and CEO of Kansaltancy Ventures. Currently serving as a Venture Advisor with Loyal VC, I bring my expertise to over 300 portfolio investments spanning 15 sectors in 60 countries. Additionally, I mentor and speak at esteemed institutions like IITs and IIMs, aiming to empower future entrepreneurs. With over 80 recommendations on my LinkedIn profile, I've been recognized for my contributions, receiving awards such as Best Startup Supporter at the IndiaFirst Tech Startup Conclave and Business Leader of the Year in Investment Management.",
            "My insights and opinions are sought after by leading business news channels and publications, including CNN-News18, VCTV, Business World, and Digital Market Asia. ",
            "With experience spanning multiple sectors, from venture capital to consulting and corporate finance, I have executed numerous successful deals, including raising USD 2.5 billion for MTS India. My academic background includes executive education from Harvard Business School, an MBA in Finance from the University of Delhi, and a B.Tech from The Technological Institute of Textile & Sciences, affiliated with the Textile Institute Manchester, UK, and part of the esteemed Birla Group.",
            "I aim to support change-makers by providing venture capital and strategic services, leveraging my extensive network of 450+ investors and VC funds."
        ],
        skills: ['Financial', 'Venture Advisory Expert'],
        offers: [
            "Venture Capital",
            "Private Equity ",
            "Financial Advisory ",
            "Management Consulting",
        ],
        rating: 4.2,
        price: "$50/Hour"
    },
    {
        name: "Dibyendu Ganguly",
        category: "Supply chain management and E-commerce expert",
        industry: "Growth expert",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/dibyendu-ganguly.png",
        profile_url: "dibyendu-ganguly",
        email: "dibyendu@example.com",
        about: "Passionate for solving global trade challenges and fostering innovation. Recognized for contributions to cross-border commerce and startup mentorship.",
        yoe: '17+ years of experience',
        location: 'Hyderabad, Telangana, India',
        long_about: [
            "I have over 17+ years of experience in e-commerce, online retail, and cross-border trade, focusing on creating seamless global supply chains and facilitating international brand expansion. As the founder of Niryati Global SCM, I lead a 4PL Global Supply Chain company providing comprehensive solutions for e-distribution, warehousing, freight management, customs clearance, and last-mile delivery.",
            "With an Advanced Program in Supply Chain Management (APSCM) from IIM Calcutta and a PGDM in Marketing from IMT Ghaziabad, I am deeply passionate about solving global trade challenges, delivering value to clients, and equipping my team with top-notch tools and practices. I prioritize building long-term partnerships, driving innovation, and promoting diversity and inclusion in our work culture. ",
            "Previously, I served as Vice President of Cross-Border Commerce at Prozo, overseeing integrated services for fast fulfillment across B2B, B2C, and D2C channels. I also founded EximScouts, a cross-border trade platform recognized for its excellence in the India 500 Startup Awards 2020 and shortlisted for the Top 50 Tech Companies Award.",
            "With expertise in business development, customer relationship management, and online marketing, I've collaborated with leading e-commerce platforms like Shopify, Shiprocket, Shypmax, and Primaseller. As a visiting faculty member at the Federation of Indian Export Organisations (FIEO) since 2019, I share insights on international trade with aspiring exporters."
        ],
        skills: ['Supply Chain Management', 'E-commerce'],
        offers: [
            "Global supply chain (International Warehousing & Last Mile Delivery Solutions - eCommerce or D2C Brands point of view)",
            "International Market Access (USA, UAE & Singapore) Through Multi-Channel e-commerce Marketplace in those countries. ",
            "Cross Border eCommerce, Multi-Channel eCommerce ",
            "Startup Mentorship (Business Development and Revenue Generation during the 0-1 phase of the startup)",
            "Planning to Execution Mentorship (Idea Stage Startup)",
        ],
        rating: 4.2,
    },
    {
        name: "Dheeraj Prasad",
        category: "Growth selling and sales strategy expert",
        industry: "Growth expert",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/dheeraj-prasad.jpg",
        profile_url: "dheeraj-prasad",
        email: "dheeraj@example.com",
        about: "Over 20 years of experience in global customer success operations, leading revenue growth strategies, and fostering social impact ecosystems as an Executive Vice President at OpenGrowth",
        yoe: '20+ years of experience',
        location: 'San Francisco Bay Area',
        long_about: [
            "As an Executive Vice President at OpenGrowth, a platform that connects entrepreneurs, investors, and mentors, I drive revenue growth strategies, creating a customer-focused organizational culture, and leveraging innovative technologies that enable growth. I am passionate about building ecosystems that foster social impact and education. ",
            "In my previous role, I led the India Operations for the largest GRC software factory in the world, steering it through a high growth stage and consolidation. My efforts in aligning culture and leadership with business objectives, and improving productivity, transformed the India Operations into a strategic center for MetricStream. ",
            "Currently, I serve on the Advisory Board of Fuzia, a girl empowerment-focused global social platform for creative expression, and on the Board of Mantra4Change, a non-profit organization that improves public education outcomes in India. Additionally, I am a Thinkfluencer on TigerHall, a global mentoring platform, and a member of the Forbes Business Council. I hold a Master of Business Administration from Birla Institute of Technology and am currently pursuing a Doctorate of Business Administration from SP Jain School of Global Management. I have published in the Journal for Services Management and received multiple awards, including the Microsoft MVP Award for Management Excellence in 2009."
        ],
        skills: ['Growth selling', 'Sales Strategy'],
        offers: [
            "Growth Strategies",
            "Customer-Focused Organizational Culture",
            "Leadership",
        ],
        rating: 4.2,
    },
    {
        name: "Ajeesh Venugopalan",
        category: "Fractional CMO",
        industry: "Fractional CMO",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/ajeesh.png",
        profile_url: "ajeesh-venugopalan",
        email: "ajeesh@example.com",
        about: "Senior Marketer and CEO of AJ&VG Media, specializing in creative and data-driven strategies to grow brands and revenue.",
        yoe: "20+ years of experience",
        long_about: [
            "With over 20 years of experience in marketing, I am a creative and data-driven leader who can help you grow your brand and revenue. I am the Founder, CEO, and Fractional CMO of AJ&VG Media, a strategic marketing partner that offers end-to-end solutions for B2B and B2C segments.",
            "As a Kotler Award winner and a certified graphic designer, web developer, and filmmaker, I have a blend of the right and the left brain, and a passion for creating engaging and impactful content and campaigns. I have hands-on experience in marketing automation, SEO, social media, event management, analyst relations, and account-based marketing. I have also authored a poetry book and delivered keynote speeches on various topics. Whether you need a CMO to get you started in the right direction or a partner who can support your existing marketing efforts, I am ready to help you achieve your goals."
        ],
        skills: [
            "Fractional CMO",
            "Marketing Automation Expert"
        ],
        offers: [
            "Developing and executing effective marketing strategies for B2B and B2C segments",
            "Creating engaging and impactful content and campaigns",
            "Navigating competitive landscapes and designing product vision and global market strategies"
        ]
    },
    {
        name: "Ram Ramalingum",
        category: "Fractional CMO",
        industry: "AI expert",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/ram.jpg",
        profile_url: "ram-ramalingum",
        email: "ram@example.com",
        about: "An award-winning CMO, a B2B & SaaS growth hacker, an engaging brand storyteller, a hands-on transformational leader, a predictive problem solver, an AI/ML & technology aficionado, and an expert global advisor.",
        yoe: '18+ years of experience',
        location: 'Gurugram, Haryana, India',
        long_about: [
            "An AI/ML & tech aficionado, my career spans over 18 years in the tech industry. I possess distinct tactical skills in business innovation & leadership, that enable me to envision, develop, strategize, mobilize, and manage a highly agile & productive global marketing engine, by deploying some highly customized new-age marketing techniques. ",
            "Tactical Skills: SEM/SEO – Market Research/Insights – Marketing Strategy & Transformation – Content Strategy – Sales Enablement – Marketing Process & Structure Design – Analyst Relations – Branding & Comms – Marketing Planning & Investments – Lead Scoring & Buyer Propensity Modeling – Account-Based Marketing – Pipeline Enrichment – Visualize & Design Assets – Account Insights & Strategies – Campaign Strategy & Insights – Events & Webinars – Key Partnerships – Inside Sales Strategy & Training – Creating Value with Marketing ",
            "I love a good story and every business has its unique one. I’ll help you tell it better, and if there’s a problem or two along the way that needs solving, I’ll own it/them for you and we’ll fix them, together."
        ],
        skills: ['Fractional CMO', 'B2B SaaS Growth Expert'],
        offers: [
            "GTM & business strategy",
            "Team structure & synergy",
            "Design & UI/UX, customer retention & loyalty programs",
            "Investor pitch decks, marketing & sales best practices",
        ],
        rating: 4.2,
        price: "$400/Hour"
    },
    {
        name: "Brice Vincocour",
        category: "Fractional CMO",
        industry: "Marketing",
        img: "https://academy.opengrowth.com/assets/images/users/user_968_professor_Brice.png",
        profile_url: "brice",
        email: "brice@example.com",
        about: "With 14 years of experience, Brice excels in leading teams to drive measurable impact for diverse companies, from startups to tech giants like Meta.",
        yoe: "14+ years of experience",
        location: "Paris, France",
        long_about: [
            "I’m a Marketing & Communication professional with 14 years of experience in leading teams to build brands and create strategies that drive measurable impact. Throughout my career, I've worked with a wide range of companies, from scale-ups and agencies to big tech firms (e.g., Meta) and established businesses, to develop and implement effective marketing strategies to grow their brands and businesses. From these experiences, I have developed strong leadership skills and marketing expertise, crafted practical frameworks, and built a network of talented marketers who can take your marketing strategies to the next level.",
        ],
        skills: [
            "Fractional CMO",
            "B2B Marketing Strategy Expert"
        ],
        offers: [
            "Brand building & rebranding",
            "Marketing planning & go-to-market frameworks",
            "Marketing operations & team organization"
        ],
        price: "$250/Hour"
    },

    {
        name: "Rachna Prasad",
        category: "Fractional CMO",
        industry: "Marketing",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/rachna-prasad.png",
        profile_url: "rachna-prasad",
        email: "rachna@example.com",
        about: "A seasoned marketing expert with over 25 years of diverse experience, dedicated to fostering growth and innovation for startups through strategic marketing initiatives.",
        yoe: "25+ years of experience",
        location: "Gurugram, Haryana, India",
        long_about: [
            "I bring over 25 years of rich experience in the dynamic field of marketing, successfully navigating both B2B and B2C landscapes. My journey spans Traditional Marketing and the Digital frontier, demonstrating a comprehensive understanding of the industry's evolution. I have diverse sectoral experience, having worked with MNCs and startups including BennettColemann, Ogilvy, American Express, MetLife, Oxigen, and Vinculum.",
            "I can help you craft and execute Go-to-Market (GTM) strategies, master media buying, orchestrate impactful product launches, secure influential celebrity endorsements, curate memorable events, and steer effective Awards Marketing and PR campaigns. Currently, I serve as a fractional Chief Marketing Officer for three startups in the B2B sector, meticulously building and executing their marketing blueprints for growth.",
            "In addition to my core responsibilities, I contribute as a mentor at IvyCap Ventures, sharing insights and guidance to nurture the next generation of business leaders. My multifaceted experience and commitment to innovation make me a valuable asset in steering brands towards unparalleled success."
        ],
        skills: [
            "Fractional CMO",
            "Marketing Strategy Expert"
        ],
        offers: [
            "Demand Generation",
            "Brand Building",
            "Go-to-market Framework"
        ],
        price: "$150/Hour"
    },
    {
        name: "Anant Sharma",
        category: "Fractional Legal Expert",
        industry: "Legal",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/anant-sharma.png", // Ensure this image is placed in src/assets/experts/
        profile_url: "anant-sharma",
        email: "anant@example.com",
        about: "Seasoned Corporate Lawyer with a stellar track record in legal advisory and consultation, specializing in corporate laws, cyber laws, technology laws, and FDI projects.",
        yoe: "12+ years of experience",
        location: "New Delhi, Delhi, India",
        long_about: [
            "As a Corporate Lawyer in India with over 12 years of experience, I've provided legal advisory and consultation services to clients worldwide. I've successfully executed multiple Multi-Million Dollar FDI Projects in India and advised over 300 startups across the country. With a track record of over 95% success rate in court litigation, drafting and vetting thousands of agreements and contracts, and serving as an arbitrator for major corporate houses, I offer comprehensive legal solutions tailored to meet various needs, including setting up businesses in India, contesting insolvency and bankruptcy lawsuits, and safeguarding investments.",
            "My expertise spans corporate laws, cyber laws, technology laws, IT laws, foreign direct investments, and Indian business entry strategies."
        ],
        skills: [
            "Fractional Legal Expert",
            "Corporate Law Expert"
        ],
        offers: [
            "Corporate Laws",
            "Cyber Laws",
            "Technology Laws",
            "IT Laws",
            "Foreign Direct Investments or India Business Entry"
        ],
        price: "$80/Hour"
    },
    {
        name: "Sailesh Sridhar",
        category: "Growth strategy expert",
        industry: "Growth expert",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/sailesh-sridhar.png", // Ensure this image is placed in src/assets/experts/
        profile_url: "sailesh-sridhar",
        email: "sailesh@example.com",
        about: "Experienced investment banking professional specializing in investor readiness, growth strategy, and strategic partnerships for startups and SMEs.",
        yoe: "13+ years of experience",
        location: "Mumbai, Maharashtra, India",
        long_about: [
            "With over 13 years of experience in investment banking, M&A, valuation, and fundraising, I've worked with global advisory firms such as Transworld M&A, GHA, and EY. Currently, as the Founder of Valuecraftz Advisors, I specialize in providing premium knowledge support to investment banks, M&A firms, financial sponsors, investment management firms, and high-growth companies. Having assisted over 300 startups and businesses in achieving investor readiness, I've been involved in transactions worth over $2 billion globally. Before establishing Valuecraftz, I handled cross-border M&A transactions at Transworld M&A and worked with EY's Transaction Advisory Services team on significant valuation engagements. ",
            "I have a B.Com (Hons) and an MBA (Finance) degree from Sri Sathya Sai Institute of Higher Learning and I’m also a CFA Charter holder."
        ],
        skills: [
            "Investment Banking",
            "Growth Strategy"
        ],
        offers: [
            "Start-up /SME Investor readiness ",
            "Growth strategy",
            "Strategic /Inorganic growth strategy",
            "Investor network",
        ],
        price: "$150/Hour"
    },
    {
        name: "Onkar Prasad",
        category: "Fundraising expert",
        industry: "Growth expert",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/onkar-prasad.png", // Ensure this image is placed in src/assets/experts/
        profile_url: "onkar-prasad",
        email: "onkar@example.com",
        about: "Founder and CEO of Promorph Capital and EmpowerU with 11 years of interdisciplinary experience, spanning various disciplines such as Startup Fundraising Advisory, Product Development, Data Analytics, Financial Risk, and Manufacturing.",
        yoe: "11+ years of experience",
        location: "Bengaluru, Karnataka, India",
        long_about: [
            "I am the Founder and CEO of Promorph Capital and flagship brand EmpowerU. With 11 years of experience spanning various disciplines such as Startup Fundraising Advisory, Product Development, Data Analytics, Financial Risk, and Manufacturing, I've successfully led high-performing teams, executed large-scale projects, and nurtured the growth of my company.  ",
            "Key accomplishments include raising over $30 million globally in 2022 for startups through Promorph Capital, securing Equity & Grant funding of INR 16.5 million for EmpowerU, and developing the Safe Learning Platform 'EmpowerU Kids,' with content garnering over 20 million views. I've been featured in Forbes for Social Innovation, recognized as one of the top 4 Social Innovators by CNBC Awaaz Entrepreneur, and appreciated by various State Governments for successful project outcomes. Additionally, I received an award from the MHRD for Innovative Idea for the use of Technology and Innovation in Promoting Good Governance in December 2014. ",
            "Through implementing EmpowerU in over 7000 public schools across three states, I've made a significant social impact, improving the quality of education for over half a million students and 50,000 teachers through technology-driven systemic improvements, and have published papers in research journals. ",
        ],
        skills: [
            "Startup Mentor",
            "Fundraising Expert"
        ],
        offers: [
            "Startup strategy ",
            "Investor pitch decks",
            "Fundraising strategies",
        ],
        price: "$60/Hour"
    },
    {
        name: "Venkat Ramanan",
        category: "Pitch deck expert",
        industry: "Growth expert",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/venkat-ramanan.png", // Ensure this image is placed in src/assets/experts/
        profile_url: "venkat-ramanan",
        email: "venkat@example.com",
        about: "Specializes in assisting founders with crafting compelling narratives for their investor decks, leveraging extensive experience and expertise in pitch deck development and business model consulting.",
        yoe: "10+ years of experience",
        location: "Bengaluru, Karnataka, India",
        long_about: [
            "I specialize in guiding founders in effectively pitching their companies to investors. As the founder of Pitchy Deck, I focus on crafting compelling narratives and optimizing investor pitch decks to secure funding. With over 900 pitch decks completed and experience working with founders from over 50 countries, I bring expertise in shaping narratives, presenting slides, and ensuring alignment with the company's vision and goals.  ",
        ],
        skills: [
            "Business Strategy",
            "Pitch Deck"
        ],
        offers: [
            "Investor Pitch Deck Development - Guiding founders in crafting and optimizing their investor pitch decks to convey the right message. ",
            "Business Model Consulting - Providing insights and second opinions on business models, ideal customer profiles, and company direction.",
            "Narrative Consultation - Assisting in shaping the narrative to customers, partners, and investors, ensuring alignment with company vision and goals.",
        ],
        price: "$70/Hour"
    },
    // {
    //     name: "Udayan Dravid",
    //     category: "Growth management expert",
    //     industry: "Growth expert",
    //     img: "udayan_dravid.jpg", // Ensure this image is placed in src/assets/experts/
    //     profile_url: "udayan-dravid",
    //     email: "udayan@example.com",
    //     about: "A seasoned industry expert with over three decades of experience, specializing in turnaround strategies, entrepreneurship, and rapid growth management.",
    // },
    {
        name: "Mansi Dhupar",
        category: "Fractional legal expert",
        industry: "Fractional legal expert",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/mansi.jpg", // Ensure this image is placed in src/assets/experts/
        profile_url: "mansi-dhupar",
        email: "mansi@example.com",
        about: "Highly experienced business and contract lawyer, guiding clients through legal complexities, and a passionate entrepreneur championing clean beauty through her brand, Oily Poily.",
        yoe: "13+ years of experience",
        location: "New Delhi, Delhi, India",
        long_about: [
            "I have over 13 years of expertise in company law, investments, contract management, and negotiation. I earned my bachelor's degree in law in 2010 and specialized in Corporate and Governance Laws, including International Business Laws, through an LL.M program at NALSAR University of Law, Hyderabad, India.",
            "As the founder of MD Legal, a leading contract management agency, I have successfully guided numerous clients through complex legal matters, ensuring their interests are protected and their contracts are optimized for their business success.",
            "Throughout my career, I have: ",
            "- Accumulated over 13 years of experience in corporate, commercial law, and regulatory compliance.",
            "- Delivered over 500 bespoke legal contracts for businesses, government organizations, and NGOs.",
            "- Completed over 200 investment transactions for companies in the US, UK, and India.",
            "- Led legal audits and contractual negotiations for blue-chip/multinational companies.",
            "In addition to my legal career, I am a passionate entrepreneur and the founder of Oily Poily, a clean beauty brand that promotes natural beauty, self-care, and overall well-being."
        ],
        skills: [
            "Business and Contract Law Expert"
        ],
        offers: [
            "Legal Advisory, Contracts ",
            "Company Incorporation & Business Legal",
            "Contracts",
            "Regulatory Compliance",
        ]
    },
    {
        name: "Ajinkya Chikte",
        category: "Pitch deck expert",
        industry: "AI expert",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/ajinkya-chikte.png", // Ensure this image is placed in src/assets/experts/
        profile_url: "ajinkya-chikte",
        email: "ajinkya@example.com",
        about: "A diverse background spanning graphic design, an MBA in marketing, and entrepreneurial ventures, and over 15 years of marketing expertise to craft creative and strategic solutions.",
        yoe: "15+ years of experience",
        location: "Goa, India",
        long_about: [
            "With over 15 years of experience in marketing across media, tech, and social impact, I specialize in creating comprehensive marketing roadmaps for small businesses and early-stage startups. From planning budgets and yearly campaign calendars to executing cross-funnel campaigns and organizing marketing team structures, hiring, and training, I excel in solving marketing problems and navigating fundraising roadblocks. My expertise is backed by a decade of crafting digital content for brand solutions and marketing, monetizing content and campaigns for diverse clients, and contributing to the development of digital teams at global media houses.  ",
            "With niche skills in research, writing, and design, I simplify complex concepts and have helped clients secure $150 million in funding and revenue through my decks. With a background in graphic design, an MBA in marketing, and entrepreneurial experience, I now consult with organizations to deliver creative and strategic marketing solutions. Some of the brands I've worked with include MTV, LinkedIn, Yamaha, Axe, Airtel, Microsoft, Tata, Durex, Vodafone, Comedy Central, Nestle, Mercedes, Lakme, and many more.",
            "I can help you make decks for: ",
            "- Startups pitching to investors",
            "- SaaS companies pitching bespoke solutions to customers",
            "- Digital agencies pitching campaigns/solutions to customers",
            "- CEOs pitching strategy to boards",
            "- Leaders speaking at conferences."
        ],
        skills: [
            "Marketing Strategy",
            "Pitch Deck Expert"
        ],
        offers: [
            "Making yearly campaign calendars  ",
            "Executing regular cross-funnel campaigns",
            "Plan marketing team org structure, hiring, & training",

        ],
        price: "$100/Hour"
    },

    {
        name: "Aniisu .K. Verghese",
        category: "Personal and employer branding expert",
        industry: "Growth expert",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/aniisu.png",
        profile_url: "aniisu-k-verghese",
        email: "aniisu@example.com",
        about: "A Distinguished internal communications and change Leader, Author, Speaker, and LinkedIn Top Voice for Internal Communications and Personal Branding",
        yoe: "25+ years of experience",
        location: "Sydney, New South Wales, Australia",
        long_about: [
            "With over 20 years of expertise in personal and employer branding and leadership communications, I help startups build compelling brand narratives and foster effective internal communications. ",
            "As an author, speaker, and trainer, I engage with communicators, academia, and students worldwide. In 2006, I launched India’s first internal communications blog (Intraskope) to share insights with practitioners.",
            "My journey began in brand management with Leo Burnett and Saatchi & Saatchi, working on accounts like Nestle and Tata Tea. I later specialized in employee communications at Accenture, Fidelity, Sapient, and Tesco.",
            "Recognized with awards like IABC APAC Communicator of the Year (2022) and PRCI Hall of Fame (2015), I've managed cross-functional teams and taught corporate communications at institutions like ISM University and IIM."
        ],
        skills: [
            "Personal and Employer Branding Expert"
        ],
        offers: [
            "Employer branding ",
            "Employee communications",
            "Personal branding",
            "Leadership communications"
        ]
    },
    {
        name: "Vinod Harith",
        category: "Fractional CMO",
        industry: "Fractional CMO",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/vinod-harith.png",
        profile_url: "vinod-harith",
        email: "vinod@example.com",
        about: "A fractional CMO, mentor, and independent marketing advisor with 30 years of experience in establishing successful marketing operations and mentoring startups.",
        yoe: "35+ years of experience",
        location: "Bengaluru, Karnataka, India",
        long_about: [
            "With over three decades of experience in marketing leadership roles across global organizations, I bring a wealth of marketing and business acumen to the table, spanning IT, Automotive, FMCG, and Education industries. ",
            "As a fractional CMO, Mentor, and Independent Marketing Advisor, I have established successful marketing back offices, advised NGOs on digital strategies, and mentored startups incubated by esteemed institutions like Stanford Seed. Additionally,",
            "I founded and scaled CMO Axis, a global pioneer in Marketing KPO, and currently serve as a board advisor to my previous companies.",
            "I am willing to offer consultation and mentoring, specializing in direction setting, team building, and execution excellence in marketing."
        ],
        skills: [
            "Fractional CMO",
            "Marketing Transformation Expert"
        ],
        offers: [
            "Business strategy and Go to market ",
            "Marketing strategy, branding, communications, and Process",
            "Governance and operations",
        ],
        price: "$118/Hour"
    },
    {
        name: "Shoan Shinde",
        category: "Fractional CMO",
        industry: "Fractional CMO",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/shoan-shinde.png",
        profile_url: "shoan-shinde",
        email: "shoan@example.com",
        about: "Specializing in propelling B2B startups through transformative phases with growth marketing expertise and a focus on building robust marketing systems.",
        yoe: "29+ years of experience",
        location: "Bengaluru, Karnataka, India",
        long_about: [
            "As an ISB alumnus, I specialize in propelling B2B startups through their transformative 0-to-1 phase, utilizing growth marketing expertise to architect demand generation strategies that fuel market entry and expansion. With a keen focus on building robust marketing teams, systems, and processes, I thrive in sculpting scalable frameworks that ensure sustained success.",
            "With nearly two decades of proven track record in setting up and managing businesses, coupled with a flair for design thinking and problem-solving, I can empower your emerging startup to achieve rapid, sustainable growth by strategically blending vision with execution."
        ],
        skills: [
            "Fractional CMO",
            "Growth Marketing Expert"
        ],
        offers: [
            "Marketing ",
            "Product-led Growth",
            "Demand Generation",
        ],
        price: "$100/Hour"
    },
    {
        name: "Prashant Vishwa",
        category: "Fractional CHRO",
        industry: "Fractional CHRO",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/prashant-vishwa.png",
        profile_url: "prashant-vishwa",
        email: "prashant@example.com",
        about: "Over 16 years of experience in HR Business Partnering and Talent Acquisition across diverse industries, driving performance culture and ensuring compliance to enhance organizational effectiveness.",
        yoe: "18+ years of experience",
        location: "Noida, Uttar Pradesh, India",
        long_about: [
            "With over 16 years of experience in HR Business Partnering and Talent Acquisition across various industries such as Retail, Food/Tech, Edu/Tech, and Product companies, I have been deeply involved in addressing the crucial 'Talent Build v/s Buy' decision. Throughout my career, I've spearheaded mind shift changes by creating multiple points of talent sourcing, including Internal Job Postings (IJPs) and identifying transferable skills both internally and externally. Moreover, I've been instrumental in conveying the Employee Value Proposition across various channels such as career sites, social media platforms, and one-to-many meetings, ensuring its resonance internally and externally, including stores.",
            "I have focused on fostering a culture of sharing within organizations, particularly in bridging skill gaps through peer-to-peer learning, best practice sharing, and On-the-Job Training (OJTs). Leveraging HR tools like Applicant Tracking Systems (ATS) and Human Resource Management Systems (HRMS), I have facilitated efficient tracking, reporting, and data-driven decision-making processes.",
            "Additionally, I have played a pivotal role in cultivating a high-performance culture by implementing group and global performance standards, Key Performance Indicators (KPIs), and targets to enhance business performance. Recognizing and rewarding high performance in individuals and teams has been a key focus area.",
            "Moreover, I have been deeply involved in Capability and Workforce Planning, ensuring that organizational capabilities required in the mid to long-term are identified and actioned upon. This includes ensuring that short-term business positions are adequately staffed to meet or exceed annual business targets."
        ],
        skills: [
            "HR Business Partnering",
            "Talent Acquisition"
        ],
        offers: [
            "Talent Acquisition Strategies ",
            "HR Business Partnering",
            "Talent Build vs. Buy Decisions",
            "Governance and Compliance in HR Practices"
        ],
        price: "$116/Hour"
    },
    {
        name: "Manu Parpia",
        category: "Business strategy expert",
        industry: "Growth expert",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/manu-parpia.jpg",
        profile_url: "manu-parpia",
        email: "manu@example.com",
        about: "A seasoned professional with extensive experience in business strategy, technology commercialization, and PLM Engineering Services.",
        yoe: "40+ years of experience",
        location: "Mumbai, Maharashtra, India",
        long_about: [
            "With over 35 years of experience, I've carved a path as a pioneer in business strategy, technology commercialization, and the PLM Engineering Services industry. I hold a bachelor's degree from McGill University, an MBA from Harvard Graduate School of Business Administration, and a Diploma in Finance Accounting from England.",
            "My journey began with a fervor for business development, starting as an engineer at Humphreys Glasgow Consultants Pvt. Ltd. and later founding K. Wadia Exports Pvt. Ltd. I then ascended to the role of Chief Executive of the Electronic Business Equipment Division at Godrej Boyce, overseeing numerous successful ventures.",
            "Additionally, I lent my expertise as a senior advisor to McKinsey on various projects. Notably, I founded Geometric Ltd., steering it into a $200 million enterprise, later acquired by HCL Technologies. Driven by a thirst for growth, I continuously seek new experiences, currently serving as a consultant for multiple companies."
        ],
        skills: [
            "Business Strategy Expert",
            "Management Consulting"
        ],
        offers: [
            "Business Strategy",
            "Leadership Development",
            "Management Consulting",
        ]
    },
    // {
    //     name: "Nikhil Kumbhar",
    //     category: "Fractional CXO",
    //     industry: "Fractional CXO",
    //     img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/nikhil-kumbhar.jpg",
    //     profile_url: "nikhil-kumbhar",
    //     email: "nikhil@example.com",
    //     about: "A seasoned sales leader and mentor with over 14 years of experience, excelling in strategy, customer management, and digital marketing expertise.",
    //     yoe: "14+ years of experience",
    //     long_about: [
    //         "With a rich background spanning diverse customer sizes, from streamlined business sales to global account management, I bring a wealth of knowledge to the table. As a coach and cross-functional leader, I've adeptly managed teams, operations, training, and coaching to propel business growth for both my team and customers.",
    //         "Beyond sales prowess, I excel in partnerships and managing CxO relationships, offering strategic guidance on GoToMarket strategies for launching brand-new products through digital marketing. With over 11 years at Google, starting as a sales manager and advancing to a programmatic media lead, I've honed my mentoring skills. My commitment to knowledge-sharing and helping others thrive underscores my dedication as a remarkable mentor in my field."
    //     ],
    //     skills: [
    //         "Sales Strategy",
    //         "Marketing Expert"
    //     ],
    //     offers: [
    //         "Sales",
    //         "Marketing",
    //         "Digital Strategies",
    //         "Customer Management"
    //     ]
    // },
    {
        name: "Sameer Karulkar",
        category: "Investment strategy and portfolio management expert",
        industry: "Growth expert",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/sameer-karulkar.png",
        profile_url: "sameer-kulhar",
        email: "sameer@example.com",
        about: "A multifaceted leader with extensive expertise in corporate development, investments, and strategic advisory services.",
        yoe: "25+ years of experience",
        location: "Mumbai, Maharashtra, India",
        long_about: [
            "I have over 25 years of leadership experience across a spectrum of disciplines including Corporate Development, Mergers and Acquisitions, Private Equity, Venture Capital, and Investment Banking. Currently, I serve as the Founder and Partner at Coverpage Ventures, specializing in Strategic Advisory Services, Co-Investments, and Executive Interim Management. My passion lies in assisting companies to achieve growth and monetization, aiding investors in portfolio management or exits, and facilitating international companies entering the Indian market. As a Venture Builder, I actively support founders in building profitable businesses, offering guidance in areas such as customer acquisition, distribution, funding, and team building. Additionally, I serve as a Mentor at various prestigious institutions. My extensive background includes roles at Times of India Group and SBI Capital Markets/Oaklins, where I led diverse teams and spearheaded significant equity investments and exits."
        ],
        skills: [
            "Investment strategy",
            "Portfolio management"
        ],
        offers: [
            "Corporate development and strategic growth",
            "Investment strategy and portfolio management",
            "Venture building and entrepreneurial guidance",
        ],
        price: "$500"
    },
    {
        name: "Shubhamay Chatterjee",
        category: "Fractional CHRO",
        industry: "Fractional CHRO",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/shubhamay-chatterjee.jpg",
        profile_url: "shubhamay-chatterjee",
        email: "shubhamay@example.com",
        about: "Experienced CHRO with a track record of implementing successful HR strategies across diverse industries and environments.",
        yoe: "27+ years of experience",
        location: "Ghaziabad, Uttar Pradesh, India",
        long_about: [
            "I started my career in plant HR and advanced to the role of CHRO driven by ambition. Completed a Fellow Programme while serving as CHRO. I've pursued coaching certifications, psychometric profiling, and HR analytics, and am currently pursuing an Independent Director level course to stay updated.",
            "My experience spans Manufacturing HR, R&D HR, Sales HR, and Corporate HR across various industries like FMCG, Pharma, Food and Beverages, etc., providing a deep understanding of HR practices and human behavior.",
            "I've successfully implemented organizational transformations, managed complex IR environments, handled mergers and demergers, and executed learning interventions, coaching, and mentoring across hierarchies.",
            "My track record includes enhancing productivity, improving retention, implementing succession planning, establishing HR frameworks, and overcoming challenges with experience-backed judgment, work ethic, and integrity. I've proven myself as a change agent, aiding organizations in reaching milestones."
        ],
        skills: [
            "CHRO",
            "Talent Development Expert"
        ],
        offers: [
            "Implementation/Revamping of HR Processes",
            "Talent Development",
            "Enhance Productivity and Employee Retention",
        ]
    },
    {
        name: "Savitha Gaekwad",
        category: "Fractional CHRO",
        industry: "Fractional CHRO",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/savitha-gaekwad.png",
        profile_url: "savitha-gaekwad",
        email: "savitha@example.com",
        about: "A highly experienced Talent Advisor and Executive Coach with expertise in leadership development, culture enhancement, and talent management across diverse industries.",
        yoe: "18+ years of experience",
        location: "Vadodara, Gujarat, India",
        long_about: [
            "I'm Savitha Gaekwad, a seasoned Talent Advisor and Executive Coach with over 21 years of experience. I've held significant roles such as Senior GM & Corporate L&OD Lead at Sun Pharmaceuticals and Talent Delivery Leader at Deloitte Consulting India. As the founder of Aquila Consulting, I provide impactful consulting and advisory services nationwide, collaborating with firms like Aon (Now Kincentric) and Great Place to Work. My coaching expertise spans leadership development, conflict resolution, communication enhancement, and fostering innovation.",
            "I've worked on various projects, including end-to-end competency framework development, performance management system revamps, and strategy design for corporate learning and development universities. Additionally, I've facilitated transition training programs and conducted assessment center evaluations for clients globally.",
            "Some of my recent advisory work includes: ",
            "- End-to-end competency framework development and talent management program for a large global NBFC",
            "- Revamp the entire Performance Management system and Sales Incentive program of an Agribusiness Firm",
            "- Strategy design for the Corporate L&D University of a large Pharma Company",
            "- Working directly with the promoter and CEO of a Start-up in the Solar Energy space– defined the roles and helped them hire their CHRO and CFO incumbents.",
            "- Design of programs around Goal Setting, Crucial conversations, and Performance management case studies for the Management Training Team of a large conglomerate with a diversified portfolio of businesses",
            "- A Campus 30-60-90 days transition training program for a Chemicals business for their campus hires",
            "- Multiple online and offline Assessment Center evaluations for Indian and global clients.",
        ],
        skills: [
            "Fractional CHRO",
            "Leadership Expert"
        ],
        offers: [
            "Leadership Development",
            "Company Culture Enhancement",
            "Talent Development",
        ]
    },
    {
        name: "Amit Avasthi",
        category: "Fractional CHRO",
        industry: "Fractional CHRO",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/amit-avasthi.png",
        profile_url: "amit-avasthi",
        email: "amit@example.com",
        about: "A seasoned HR executive and ICF Coach specializing in empowering organizations through culture building and employee engagement.",
        yoe: "22+ years of experience",
        location: "Greater Delhi Area",
        long_about: [
            "With over 22 years of experience in leading organizations through complex transitions, I am a seasoned HR executive and an ICF Coach who is passionate about empowering organizations through culture building and employee engagement. As the Vice President of Talent Transformation at Entomo, I partner with clients to deliver high-touch people experience solutions in the areas of talent, skills, performance, and analytics.",
            "My expertise lies in designing and executing global people strategy, driving process, and cultural transformations, delivering people services, and handling global employee relations. I have successfully led HR initiatives across various geographies, industries, and business models, including fintech, digital solutions, and emerging markets. ",
            "Some of my key achievements include driving HCL Technology's culture and process transformation across 80,000 employees and 34 countries, overseeing global HR strategy and services as the CHRO of two startups/scale-ups - a fintech company and a digital solutions provider, and aligning HR strategy with a digital roadmap and enabling 20% primary growth during lockdown for a Pharma MNC. I am a trusted advisor to business leaders and a visionary leader who challenges conventional wisdom and reduces biases, building inclusive environments that help talent leverage their true potential."
        ],
        skills: [
            "CHRO",
            "HR Strategy Expert"
        ],
        offers: [
            "Global people strategy",
            "Process and cultural transformations",
            "Global employee relations",
        ]
    },
    {
        name: "Anmol Jamwal",
        category: "Fractional CMO",
        industry: "Fractional CMO",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/anmol-jamwal.png",
        profile_url: "anmol-jamwal",
        email: "anmol@example.com",
        about: "A dynamic entrepreneur and tech innovator, offering expertise in startup growth strategies and fostering innovation culture among young Indians.",
        yoe: "25+ years of experience",
        location: "Chandigarh, India",
        long_about: [
            "I am the Co-founder, and I handle the President Operations for Feoster & Go Driver, an online Tech Platform that makes luxury services accessible to everyone. As an Angel Investor, social media influencer, YouTuber, and young startup founder, I am the driving force behind one of the fastest-growing startups in Tricity.",
            "With Feoster, I am revolutionizing how people experience luxury, making services like chauffeurs, nannies, and shareable seats in private jets available to people of all income levels.",
            "I am a great believer in the power of technology and believe that with the right technology, anything is possible. I am passionate about promoting the culture of innovation among young Indians and love to mentor and guide startups. I have been featured in many publications such as Entrepreneur India, The Economic Times, YourStory, and more. I have been interviewed by leading publications, television channels, and radio channels across India to share my experiences and insights."
        ],
        skills: [
            "Leadership",
            "Personal Branding Expert"
        ],
        offers: [
            "Startup Growth Strategies",
            "Entrepreneurship Mentoring",
            "Social Media and Influencer Marketing",
        ]
    },
    // {
    //     name: "Atul Tiwary",
    //     category: "Organizational development  expert",
    //     industry: "Growth expert",
    //     img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/atul-tiwary.png",
    //     profile_url: "atul-tiwary",
    //     email: "atul@example.com",
    //     about: "Over 16 years of experience in HR consulting, tech rollouts, stakeholder management, and leadership development through strategic HR initiatives and talent optimization.",
    //     yoe: "16+ years of experience",
    //     long_about: [
    //         "As an HR and Consulting Professional, I bring over 16 years of qualitative experience in various facets of HR, including HR Consulting, Global HR Tech Rollouts, HR Product Design, Stakeholder Management, Management Consulting, leadership development, Strategy Planning, Shared Services, C&B, and OD Interventions across diverse industry verticals globally.",
    //         "My expertise lies in analyzing key business challenges and evolving and aligning HR strategy and initiatives to meet the short-term and long-term goals of the organization.",
    //         "I have been instrumental in developing HR strategies and leading change initiatives to institutionalize processes and facilitate rapid scale-up through talent acquisition and establishing an Employee Value Proposition. Additionally, my experience encompasses insight into Change Management and Culture Building through various organizational initiatives.",
    //         "I am deeply passionate about Simplifying work life through HR Technology, Change Management, Organizational Development, Talent Management, HR Transformations, and Leadership Development. Over the years, I have conducted multiple people interventions aimed at helping organizations and their leaders realize their potential."
    //     ],
    //     skills: [
    //         "People Development",
    //         "Growth Expert"
    //     ],
    //     offers: [
    //         "Organizational Development ",
    //         "Talent Management",
    //         "HR Transformations",
    //         "Employee Engagement"
    //     ]
    // },
    // {
    //     name: "Gayatri Sharma",
    //     category: "Fractional CHRO",
    //     industry: "Fractional CHRO",
    //     img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/gayatri-sharma.jpg",
    //     profile_url: "gayatri-sharma",
    //     email: "gayatri@example.com",
    //     about: "Extensive experience spanning Telecom, ITES, and BPO sectors. Served as an Associate Director of HR - Learning Development, leading operations for organizations with over 2000 employees",
    //     long_about: [
    //         "As an HR & Administration Professional, I bring several years of diverse industry experience in sectors like Telecom, ITES, and BPOs. I've served as an Associate Director of Human Resources - Learning Development, leading HR operations for an organization with over 2000 employees across multiple locations. My responsibilities include managing various aspects of learning and development, such as manpower planning, talent acquisition, and recruiting systems. I aim to contribute value to organizations by sharing expertise on various concepts."
    //     ],
    //     skills: [
    //         "Human Resources Expert"
    //     ],
    //     offers: [
    //         "Performance management ",
    //         "HR Policies",
    //         "Employee engagement",
    //     ]
    // },
    {
        name: "Simha Chakravarthy",
        category: "Fractional sales expert",
        industry: "Fractional sales expert",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/simha-chakravarthy.jpg",
        profile_url: "simha-chakravarthy",
        email: "simha@example.com",
        about: "A highly accomplished sales and revenue growth leader with over two decades of experience, adept at building and scaling B2B sales teams globally",
        yoe: "26+ years of experience",
        location: "United States",
        long_about: [
            "I am a seasoned sales and revenue growth leader with over two decades of experience in business development within the B2B environment. My extensive experience includes working with technology giants such as Microsoft, Oracle, Lenovo, and Akamai.",
            "Over the last decade, I have achieved an outstanding average year-on-year growth rate of 1.5x for the teams under my leadership. Notably, I have played a pivotal role in enhancing channel enablement and channel growth, achieving a remarkable 3x increase for a major technology company. My strategic leadership has also led to the successful establishment and scaling of a new line of business for sales development. In addition to my corporate roles, I contribute to the growth of the technology ecosystem by serving as a strategic advisor for startups in both the United States and India. My expertise and insights are sought after, making me a valuable resource for emerging companies in the tech space. My commitment to the field is further evident in my role as a sales consultant and active membership in the Sales Enablement Society."
        ],
        skills: [
            "Sales",
            "Revenue Growth"
        ],
        offers: [
            "Building and scaling B2B sales teams",
            "Channel enablement strategies",
            "New business development",
            "Sales practices optimization",
            "Sales consulting",
            "Sales leadership development"
        ]
    },
    {
        name: "Niraj Sharan",
        category: "Global consultant and industrial innovation expert",
        industry: "Growth expert",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/niraj-sharan.jpg",
        profile_url: "niraj-sharan",
        email: "niraj@example.com",
        about: "30 years of experience dedicated to renewable energy, healthcare, and Agrotech industries, focused on upgrading systems through technology and collaboration for a sustainable future.",
        yoe: "30+ years of experience",
        long_about: [
            "I am Niraj Sharan, the founder of Aura Inc., a multi-disciplinary event agency. Throughout my 30-year professional career, I have focused solely on the renewable energy, healthcare, and Agrotech industries. In addition to demonstrating leadership and strategic planning across various businesses, I am deeply concerned about improving the quality of healthcare and preserving the environment.",
            "For the past four years, I have dedicated myself to upgrading farming and healthcare systems through cloud and agro technologies. I have collaborated with research organizations from Japan and other countries, serving as a knowledge and technology partner. Additionally, I work as a strategic technology partner for a research organization developing the High Flow Nasal Cannula system for treating patients with respiratory failures.",
            "My writings have been published in leading publications, and I am honored to have received the Rajiv Gandhi Shiromani Award. I believe that collaboration is crucial for enhancing our healthcare system, especially in the face of challenges like COVID-19.",
            "Through extensive research and strategic planning, I have made a significant impact on these industries. I remain committed to working with individuals and organizations dedicated to building a sustainable future."
        ],
        skills: [
            "Global Consultant",
            "Industrial Innovation Expert"
        ],
        offers: [
            "Next generation manufacturing ",
            "Strategic global partnership",
            "Technological upgrading",
        ]
    },
    {
        name: "Subash Franklin",
        category: "Fractional CMO",
        industry: "Fractional CMO",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/subash-franklin.png",
        profile_url: "subash-franklin",
        email: "subash@example.com",
        about: "Sales & Profit oriented Fractional CMO specializing in enhancing marketing, sales, and profitability for businesses.",
        yoe: "19+ years of experience",
        location: "Bengaluru, Karnataka, India",
        long_about: [
            "I am a Sales & Profit oriented Fractional CMO, having worked with prominent businesses like Ford, Lenovo, HealthKart, Apollo Hospitals, and Eastern Foods, focusing on marketing, sales, and profitability enhancement functions.",
            "My expertise lies in product and service design, lead generation, advertising & marketing, no-cost sales and profit enhancement, partnerships, and business scaling. I have also written articles and case studies on driving sales, increasing profit, and cutting costs strategically. ",
            "I offer four flagship services : ",
            "- Learn how to ‘Double your Business, Profit & Sales’ through automated selling.",
            "- Discover how to attract customers who are ready to buy on a tiny budget, in autopilot mode.",
            "- Get insights on how to Get Profit In Advance. ",
            "- Uncover the hidden profit and sales already in your business, which are being underutilized.",
        ],
        skills: [
            "Fractional CMO",
            "Sales Expert"
        ],
        offers: [
            "Optimizing product/service design to maximize sales potential. ",
            "Implementing cost-effective lead generation strategies to attract qualified customers.",
            "Developing innovative advertising and marketing campaigns to drive revenue growth."
        ]
    },
    {
        name: "Alok Nigam",
        category: "Fractional CHRO",
        industry: "Fractional CHRO",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/alok-nigam.png",
        profile_url: "alok-nigam",
        email: "alok@example.com",
        about: "A versatile and experienced leader adept at driving organizational transformation and talent development across diverse industries and global landscapes",
        yoe: "33+ years of experience",
        long_about: [
            "I am a dynamic, agile, and adaptable leader with extensive experience across various industries such as FMCG, FMCD, Telecom, Real Estate, and start-up ecosystems. Throughout my career, I have led organizations through transitions by restructuring business operations, driving cultural transformations, and managing change effectively. I specialize in designing and implementing talent management processes, identifying and nurturing top talent, and creating robust career development pathways.",
            "One of my key achievements has been leading the deployment of talent management processes that enabled 70% of top talent to move into critical roles within the organization. I am passionate about revamping people strategies to align with the needs of diverse generations, including Gen X and millennials. Additionally, I have expertise in leadership acquisition, transition, and assimilation across different businesses and geographical locations.",
            "As a strong people leader, I have formed guiding coalitions involving employees and have a deep commitment to mentoring leaders and grooming talent. I am certified as an SHL facilitator, STAR team facilitator, and Gallup facilitator, reflecting my dedication to excellence in talent development and organizational effectiveness.",
            "Having operated in Asia and Europe, I am globally mobile and possess a unique ability to adapt seamlessly to diverse sectors, roles, and business situations. I am resilient, possess excellent communication skills, and am driven by strong values, making me a trusted leader capable of simplifying complex challenges and driving positive change."
        ],
        skills: [
            "HR Advisor",
            "People Development Expert"
        ],
        offers: [
            "Implementing effective organizational change management strategies.",
            "Developing and deploying robust talent management processes.",
            "Facilitating leadership acquisition, transition, and assimilation across different business sectors"
        ],
        price: "$60/Hour"
    },
    {
        name: "Mili Kataria",
        category: "Fractional CMO",
        industry: "Fractional CMO",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/mili-kataria.png",
        profile_url: "mili-kataria",
        email: "mili@example.com",
        about: "A seasoned professional with over 25 years of diverse experience in strategic branding and marketing.",
        yoe: "25+ years of experience",
        long_about: [
            "As a seasoned professional with over 25 years of diverse entrepreneurial and professional experience across industries such as education, FMCG, jewelry, event management, and banking, I specialize in building brands through strategic Branding, Digital Marketing, and Growth Hacking efforts. Alongside this, I am passionate about mentoring budding entrepreneurs to facilitate the growth and brand positioning of their startups. Since 2011, I have been running my own Firm – Pandora’s Box, providing marketing and branding consulting services.",
            "In my role as Head of Branding & Marketing at Hesa Enterprises Pvt. Ltd, I am responsible for leading branding and marketing initiatives to drive business growth. Additionally, I actively mentor at T-Hub, AIC-RNTU, AIC-NITF, Headstart Network Foundation, We Hub, 91Springboards, Google for Startups, Deshpande Startups, Wadhwani Foundation, and Stanford Seed, contributing to the entrepreneurial ecosystem.",
            "Outside of my professional endeavors, I volunteer at Headstart Network Foundation and am involved with SCSC Women's initiative."
        ],
        skills: [
            "Branding",
            "Marketing"
        ],
        offers: [
            "Business Consulting  ",
            "HBrand Marketing",
            "Business Development",
        ],
        price: "$60/Hour"
    },
    {
        name: "Monil Hathi",
        category: "Fractional CMO",
        industry: "Fractional CMO",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/monil-hathi.png",
        profile_url: "monil-hathi",
        email: "monil@example.com",
        about: "25+ Years of Marketing, Branding, and Strategy Experience with a proven track record in building high-performance teams and developing innovative marketing programs for global B2B and technology organizations.",
        yoe: "25+ years of experience",
        long_about: [
            "As a Marketing & Strategy executive, I have a proven record of delivering results by building high-performance teams and developing innovative marketing programs globally for B2B and technology organizations. I am experienced in managing marketing P&L, developing and executing integrated (digital + traditional) marketing strategies, developing GTM plans, establishing new business verticals, and leveraging technology to improve business outcomes.",
            "Specialties: ",
            "- Marketing Strategy & Execution: Brand positioning & messaging, marketing communications, demand generation, lead generation, partner marketing, field marketing, and analyst/public relations.",
            "- Inbound Marketing: SEO, social media, Google Ads, content marketing, web development, marketing automation, conversion optimization, and data analytics.",
            "- Outbound/Traditional Marketing: Direct mail, print, online, events, conferences, PR, advertising, and inside sales.",
            "- Technical Skills: Proficient in Google Analytics, HubSpot, and Act-On.",
            "- Certifications: HubSpot Inbound Certified and Google Ads Certified.",
            "As the Founder of Virtual CMO Services, I work closely with C-Suite executives, marketing, and sales teams across various B2B firms. Together, we implement practical marketing strategies aimed at boosting business pipelines. Acting as a fractional CMO and marketing advisor, my goal is to drive substantial business growth.",
            "Do you aspire to transform your B2B business from being relatively unknown to becoming a recognizable brand? Let's connect!"
        ],
        skills: [
            "Fractional CMO",
            "Marketing Transformation Expert"
        ],
        offers: [
            "B2B and Technology Marketing strategy",
            "Brand Building in a Noisy Business World",
            "Demand Generation",
            "Marketing Transformation and Automation"
        ],
        price: "$250/Hour"
    },
    {
        name: "Shenaz Bapooji",
        category: "Fractional CMO",
        industry: "Fractional CMO",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/shenaz-bapooji.png",
        profile_url: "shenaz-bapooji",
        email: "shenaz@example.com",
        about: "A seasoned marketing and advertising expert, renowned for her ability to drive omnichannel strategies, build successful brands, and mentor startups to success.",
        yoe: "30+ years of experience",
        long_about: [
            "With over 30 years of experience in Marketing & Advertising, I currently lead a Marketing Advisory, where I focus on running accelerator programs for startups among other initiatives.",
            "During my tenure with the MatchMove Group, I spearheaded marketing strategies for MatchMove (Fintech) and Shopmatic (E-commerce).",
            "Here are some key highlights of my career: ",
            "- Spent over 7 and a half years with the digitally focused brand, Shopmatic Group, where I built Shopmatic from the ground up, witnessing its entire journey from inception to growth to acquisition.",
            "- Accumulated over 20 years of advertising expertise with Ogilvy, where I handled both B2C and B2B projects.",
            "- Established the B2B Practice Group for Ogilvy India and also led Non-Advertising disciplines for Ogilvy Bangalore.",
            "- Maintained an excellent track record in securing new business and nurturing strong client and partner relationships.",
            "- Contributed significantly to building numerous brands across various sectors such as Tech, Personal Care, FMCG, Fashion, Education, F&B, and e-commerce, among others.",
            "- Acted as visiting faculty in prestigious Management schools and participated in panel discussions and award shows as a jury member.",
            "- My strengths lie in crafting omnichannel strategies that drive business growth, build successful brands, and exceed business goals while ensuring profitability. I possess expertise in both digital and offline marketing, leveraging my deep understanding of marketing and communication channels effectively.",
            "If you're seeking guidance on digitizing your brand or developing effective strategies for your startup, I'm here to help."
        ],
        skills: [
            "Fractional CMO",
            "Marketing Strategy Expert"
        ],
        offers: [
            "How to Position Your Brand ",
            "Devising the Best Go-to-Market Plan",
            "Brand Identity",
            "Social Media Marketing"
        ]
    },
    {
        name: "Diptarup Chakraborti",
        category: "Fractional CMO",
        industry: "Fractional CMO",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/diptarup.png",
        profile_url: "diptarup-chakraborti",
        email: "diptarup@example.com",
        about: "A SaaS and Tech Marketing expert with 25 years of experience. Passionate about helping mid and early-stage companies accelerate their growth journeys.",
        yoe: "25+ years of experience",
        long_about: [
            "I'm Diptarup Chakraborti, throughout my career, I've had the privilege of partnering with four SaaS organizations, supporting them during their critical phases of scaling from $1 million to $10 million and then from $10 million to $100 million in revenue.",
            "Beyond that, I actively advise early-stage companies on crafting winning marketing and growth strategies. I bring extensive cross-functional expertise to the table, specializing in driving growth marketing, product marketing, inside sales, field marketing, and sales enablement initiatives for mid-sized and early-stage startups. ",
            "I have a proven track record of integrating cutting-edge marketing technologies to enhance efficiency and drive marketing success.",
            "My experience extends beyond traditional marketing realms; I'm also a skilled analyst relations professional. Having worked both as an analyst with the world's largest analyst firm and in running the AR function for organizations I've been a part of, I understand the intricacies of cultivating and managing analyst relationships to bolster brand reputation and market positioning."
        ],
        skills: [
            "Fractional CMO",
            "Marketing Strategy Expert"
        ],
        offers: [
            "Growth marketing strategies ",
            "Product positioning and messaging",
            "Go-To-Market Strategy for Early-Stage Companies",
        ],
        price: "$118/Hour"
    },
    {
        name: "Prakher Srivastava",
        category: "Team leadership and technology solutions expert",
        industry: "Growth expert",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/prakher.png",
        profile_url: "prakher-srivastava",
        email: "prakher@example.com",
        about: "An accomplished leader with a proven track record of directing diverse technology teams across India, excelling in end-to-end business deliveries and resource management.",

        long_about: [
            "I'm a seasoned technology leader with extensive experience at Barclays Investment Bank, managing cross-functional teams and delivering innovative solutions. My passion lies in problem-solving and supporting teams with technical challenges.",
            "Whether you need expertise in application and database design, implementing DevOps practices, or building high-performing tech teams, I can provide guidance and support to take your organization to the next level."
        ],
        skills: [
            "Team Leadership",
            "Technology Solutions Expert"
        ],
        offers: [
            "Technology Leadership and Team Management",
            "Technology Strategy Alignment with Business Objectives",
            "DevOps and Engineering Transformation",
        ],
        price: "$250/Hour"
    },
    {
        name: "Saurabh Srivastava",
        category: "Product market fit and marketing strategy expert",
        industry: "Growth expert",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/saurabh.png",
        profile_url: "saurabh-srivastava",
        email: "saurabh@example.com",
        about: "A tech architect with a proven track record of building high-performance teams and delivering game-changing solutions.",
        yoe: "20+ years of experience",
        long_about: [
            "As an industry veteran with over 20 years of experience, I've held leadership roles at some of India's most notable startups, including Ixigo, MobiKwik, Jabong, and Housr, contributing significantly to their growth and success. Beginning my career in traditional marketing with esteemed organizations like Maruti Suzuki, Goodyear, and WPP, I've honed strong capabilities in Portfolio & Brand Management, Entrepreneurship, and Innovation.",
            "I thrive on constant innovation and growth, with a passion for building businesses and brands from the ground up. Joining Monster.com as the Chief Marketing Officer in 2022, I spearhead business strategy and brand management for the company.",
            "With degrees from prestigious institutions such as the Indian School of Business, Hyderabad, and Mudra Institute of Communications, Ahmedabad, I enjoy engaging in conversations that intersect the realms of Tech, Marketing, and Product."
        ],
        skills: [
            "Product Market fit",
            "Marketing Strategy Expert"
        ],
        offers: [
            "Brand Building and Business Scaling ",
            "Portfolio and Brand Management",
            "Business Strategy and Innovation"
        ],
        price: "$200/Hour"
    },
    {
        name: "Ajitabh Sarin",
        category: "Fractional Sales expert",
        industry: "Fractional Sales expert",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/ajitabh.png",
        profile_url: "ajitabh-sarin",
        email: "ajitabh@example.com",
        about: "An independent consultant with over 21 years of experience in technology and consulting sales. Passionate about scaling up a new business  and executing a turn- around strategy to focus on high revenue growth.",
        yoe: "21+ years of experience",
        long_about: [
            "I have experience in Sales, Business Development, and large account management, focused on driving revenue growth in technology and consulting firms. I've had the opportunity to work with leading organizations like Siemens Industry Software, EY, Dassault Systèmes, and Rockwell Automation.",
            "For more than 12 years, I’ve been deeply involved with the Indian automotive sector, spearheading digital transformation initiatives across product development, manufacturing, and supply chain. My efforts have driven a 3x revenue growth in software license sales, managing a portfolio worth $5 million.",
            "I’ve also successfully scaled a services business line, resulting in a 10x revenue increase, and created a new market for consulting services that contributed an additional $3 million to overall revenue."
        ],
        skills: [
            "Business Consultant",
            "sales expert"
        ],
        offers: [
            "Strategy and Client Acquisition ",
            "Revenue Growth",
            "Sales Strategy and Planning",
            "Key Account Management ",
            "Channel Management ",
            "Negotiation Strategy",
            "Training and Tools",

        ]
    },
    {
        name: "H V Narasinga Rao",
        category: "Fractional CFO",
        industry: "Fractional CFO",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/narsinga-rao.jpg",
        profile_url: "hv-narasinga-rao",
        email: "hv@example.com",
        about: "Senior Finance professional with extensive experience driving business growth and profitability for leading Pharma and Healthcare companies through strategic financial management, M&A, and operational transformation.",

        long_about: [
            "Senior Finance professional with formidable experience steering organic/inorganic business growth strategies for leading Pharma and Healthcare corporations. Expert in cost/market-driver analysis, due diligence, corporate valuation, win-win deal negotiation, and post- M&A organizational integration",
            "- Repeatedly called on to take the reins in crises to quickly distill complex data into business insights and action that transform, turn around, and establish means to long-term profitability. ",
            "- A transformational leader who reinvigorates companies by restructuring, streamlining, and strengthening financial operations to maximize performance and profitability.",
            "- Operations trouble-shooter equipped to respond to financial challenges related to turnarounds, acquisitions, and mergers with confidence, determination, and focus in fast-paced environments",
            "Areas of experience : ",
            "- Finance & Accounting  leadership",
            "- Performance management",
            "- Profit improvement and cost efficiencies ",
            "- Finance transformation.",
            "- Business planning and strategy.",
            "- ERP implementations",
            "- Systems and controls.",

        ],
        skills: [
            "CFO",
            "Growth Strategy Expert"
        ],
        offers: [
            "Business partnering  and  performance management",
            "Value focus includes growth strategies.",
            "Financial planning & analysis",
            "Startups and MSME companies",
            "Operational efficiencies."
        ]
    },
    {
        name: "Srinath Mysore Nagendra",
        category: "Fractional CTO",
        industry: "Fractional CTO",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/srinath.png",
        profile_url: "srinath-mysore",
        email: "srinath@example.com",
        about: "Srinath is an engineer and corporate leader with extensive experience in global and Indian MNCs like Coca-Cola and PepsiCo and is passionate about enabling entrepreneurs to achieve sustainable and profitable growth.",
        long_about: [
            "I am an enthusiastic optimist who believes in the transformative power of entrepreneurship. With a rich career spanning global and Indian MNCs such as Coca-Cola, PepsiCo, Whirlpool, Voltas, and Frigoglass, I have honed my skills in sales, after-sales operations, innovation, supply chain, product development, and commercialization. As an Independent Director certified by the Indian Institute of Corporate Affairs and an advocate of ESG principles, my mission is to unleash the potential of entrepreneurs across various company sizes, from MNCs to startups, ensuring profitable and sustainable growth. I provide management consulting, technology consulting, and Interim CXO services, and actively mentor and invest in startups in the USA and India, striving to create meaningful solutions and wealth for all stakeholders."
        ],
        skills: [
            "Fractional Co-Founder",
            "Technology Expert"
        ],
        offers: [
            "Scaling Business Operations ",
            "Innovation and Product Development",
            "Sustainable and Profitable Growth",
        ],
        price: "$300/Hour"
    },
    {
        name: "Dr. Munish Kumar",
        category: "Strategic marketing,and communication expert",
        industry: "Growth expert",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/munish-kumar.png",
        profile_url: "munish-kumar",
        email: "munish@example.com",
        about: "A forward-thinking marketing strategist with a proven record of driving brand success through innovative campaigns, digital expertise, and data-driven insights.",
        long_about: [
            "As a marketing strategist with a proven track record of building successful brands and fostering growth through innovative marketing initiatives, I excel in combining strategic planning with cutting-edge digital expertise. I specialize in crafting and executing high-impact marketing campaigns across all channels.",
            "My strengths include: ",
            " Marketing Leadership & Management: I am a seasoned leader with exceptional team management and budgeting skills.",
            " Digital Marketing Mastery: I possess in-depth knowledge of SEO, SEM, content marketing, social media marketing, and email marketing, ensuring a truly omnichannel marketing approach.",
            " Brand Management Expertise: I have a deep understanding of brand identity and reputation management, adept at building and elevating brand awareness.",
            " Data & Analytics Powerhouse: I am skilled in analyzing marketing data, utilizing tools like marketing attribution and dashboards to measure ROI and fuel data-driven decision-making.",
            " AI-Powered Innovation: I embrace AI-powered marketing tools such as programmatic advertising, social listening platforms, and customer segmentation to personalize experiences and optimize campaigns.",
            " Communication & Collaboration Champion: I excel in fostering collaboration with internal and external stakeholders, managing projects, and ensuring smooth execution.",
            " Innovation & Experimentation: I thrive on exploring new marketing trends and A/B testing to continuously improve strategies and drive growth through data-focused experimentation.",
            "I am an internationally tested, certified Performance & Growth Marketer with global expertise spanning various domains, including 360 Marketing, Business Consulting, Integrated Marketing Communications, Developing Business (Sales Enablement, Lead and Demand Generation), and utilizing Data Science, Marketing Analytics, Business Analytics (BA), and Business Intelligence (BI) backed by AI and Machine Learning.",
            "Through it all, my passion for human psychology fuels my drive to innovate and explore new frontiers.",

        ],
        skills: [
            "Strategic Marketing",
            "Communication"
        ],
        offers: [
            "Marketing and Consulting",
            "Change Management ",
            "Corporate Psychological Counselling (One-to-One and/or Group)",
        ],
        price: "$150/Hour"
    },
    {
        name: "Prashant Kohli",
        category: "Brand development and marketing strategy expert",
        industry: "Growth expert",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/prashant-kohli.png",
        profile_url: "prashant-kohli",
        email: "prashant@example.com",
        about: "Unlocking brand-led growth for consumer companies & startups using content + tech + communities. Proficient in building systems & cultures for sustainable growth.",

        long_about: [
            "I am a Delhi College of Engineering alum & founding member of India's leading digital creative outfit, The Glitch (Acquired by WPP). Solved business challenges across categories including FMCG, Alco-Bev, Ed-Tech, Petcare, and Consumer Internet.",
            "A certified design thinker who keeps people at the core of every business opportunity, using first principles to sharply define problems & iterative design principles for solutions.",
            "I have enjoyed a wide diversity of roles in my career across Brand Strategy & Management, Go To Market Strategies, New Product Development, D2C & E-commerce, Digital & Social Strategy, and Customer Experience. My personal & professional journey has been marked with rich  milestones and learnings which have shaped what I do, and how I do it: ",
            "I can create & scale brands from scratch and accelerate the growth of established brands : ",
            " A strong customer insight & problem identification are ALWAYS the starting point",
            " A clear, distinct strategy based on organizational strengths & flawless execution is non-negotiable",
            " While creativity thrives on intuition, measuring outcomes & being data-oriented is the way to guide intuition",
            "I can build, improve & scale teams : ",
            " I shine when my team wins & the buck always stops with me",
            " I tell narratives & frame stories that motivate",
            " I am equally charged with interacting with junior execs and the C-suite",
            "New perspectives & people energize me & I'd be happy to have a conversation about marketing, design thinking, culture, startups, kids, or spirituality. I have a huge bias for action.",
        ],
        skills: [
            "Brand development",
            "Marketing Strategy Expert"
        ],
        offers: [
            "Brand-Led Growth ",
            "Go To Market Strategy ",
            "Customer Experience",
        ],
        price: "$200/Hour"
    },
    {
        name: "Sayantan Dasgupta",
        category: "Brand and product marketing expert",
        industry: "Growth expert",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/sayantan-dasgupta.png",
        profile_url: "sayantan-dasgupta",
        email: "sayantan@example.com",
        about: "A results-driven marketing leader with 13+ years of experience in diverse sectors, recognized by Forbes and the CMO Council for his impactful contributions and innovative strategies",
        yoe: "13+ years of experience",
        long_about: [
            "Seasoned marketing and demand generation leader with 13+ years of experience in B2B SaaS, Data Science & Analytics, High Ed, eCommerce, ESG, and Media industries. I've held several leadership roles, including 4x VP of Marketing, Sr. Director, and Partner at a US-based Private Equity fund. I have led integrated programs at growth to IPO-listed companies and witnessed the $1M to $100M ARR journey. Recognized by Forbes and the CMO Council for outstanding contributions in the marketing world, I love helping companies in the SaaS and technology space.",

        ],
        skills: [
            "Brand and Product Marketing Expert"
        ],
        offers: [
            "Go-to-market Strategy",
            "Product Marketing ",
            "Content Marketing",
            "Demand Generation",
            "Pricing Strategy"
        ],
        price: "$200/Hour"
    },
    {
        name: "Piyush Bhamre",
        category: "Fractional Sales expert",
        industry: "Fractional Sales expert",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/piyush.png",
        profile_url: "piyush-bhamre",
        email: "piyush@example.com",
        about: "A seasoned sales and business development strategist with extensive experience in scaling startups and driving revenue growth.",
        yoe: "16+ years of experience",
        long_about: [
            "With over 16 years of experience, I am an accomplished sales and business development strategist. I played a pivotal role in a $15 million Series A fundraising and a multi-million dollar acquisition. I have worked with over 50 startups, helping them define their go-to-market (GTM), sales, scaling, and hiring strategies.",
            "As a passionate LinkedIn contributor, I have been recognized as a LinkedIn Top Voice. I am also a proud member of the HubSpot Revenue Council, a prestigious community of Sales and RevOps leaders from around the world.",
            "Skillset: Sales and RevOps Advisory, Strategic Planning and Execution, Business Development, Sales Strategy, Leadership and Team Building, Startup Scaling, Partnership and Alliance Formation, Revenue Growth, Market Expansion, Client Relationship Management"
        ],
        skills: [
            "Go-To-Market Strategy",
            "Sales Expert"
        ],
        offers: [
            "Developing and Executing Sales Strategies",
            "Scaling Startups",
            "Building Strategic Partnerships",
        ],
        price: "$70/Hour"
    },
    {
        name: "Siddhartha Khaitan",
        category: "Fractional CFO",
        industry: "Fractional CFO",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/siddharth.png",
        profile_url: "siddhartha-khaitan",
        email: "siddhartha@example.com",
        about: "A seasoned finance professional with over 24 years of experience in strategy, procurement, and financial management, recognized for expertise in both domestic and international markets.",
        yoe: "24+ years of experience",
        long_about: [
            "I am a senior finance professional with over 24 years of experience in both India and the UK. I have hands-on expertise in various areas including strategy, procurement, supply chain, controllership, funding, SAP implementation, fiscal controls, and internal assurance.",
            "I hold the distinction of being a rank-holder Cost Accountant and Chartered Accountant since 1997 in India. I completed the MBA program (SMP) from IIM Kolkata in 2010 and am currently undergoing a one-year program on Private Equity and Venture Capital from ISB. In 2012, CFO India recognized me among the 100 Next CFOs.",
            "I am quality and process-focused, having trained as a Black Belt in Six Sigma from GE Corporation. Additionally, I have shared my expertise as a guest lecturer on finance and telecom at MDI Gurgaon, IMT Ghaziabad, and IMI New Delhi.",
            "Areas of expertise : Finance, Operations, Supply Chain, Start Ups, Funding, Strategy",
        ],
        skills: [
            "CFO",
            "Business Strategy Expert"
        ],
        offers: [
            "Financial Strategy and Planning",
            "Fundraising and Capital Investment",
            "Process Improvement and Cost Reduction",

        ],
        price: "$250/Hour"
    },
    {
        name: "Ramesh Naidu Pediredla",
        category: "Fractional CMO",
        industry: "Marketing",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/ramesh.png", // Ensure this image is placed in src/assets/experts/
        profile_url: "ramesh-naidu-pediredla",
        email: "ramesh@example.com",
        about: "With over 12+ years in online and offline marketing, Ramesh excels in Mobile App User Acquisition and managing diverse campaign channels.",
        yoe: "12+ years of experience",
        long_about: [
            "I am currently the Chief Marketing Officer at India's leading game developer and publisher, renowned for producing India's most played game with over 1.5 B+ Downloads.",
            "With over 12 years of experience in both online and offline marketing, I specialize in Mobile App User Acquisition, mastering campaign management across diverse channels including Self-Reporting Networks, Ad Networks, Affiliates, Programmatic, and TV Media Buys.",
            "Leadership Excellence:",
            "Managed and trained numerous digital marketing professionals, nurturing talent and leadership capabilities.",
            "Steered teams towards exceeding ambitious user acquisition and campaign management goals, fostering a culture of innovation, accountability, and continuous improvement.",
            "Strategic & Tactical Expertise:",
            "I develop innovative growth strategies and manage hands-on campaign execution, optimization, and analytics. My leadership has been crucial in scaling user acquisition from Soft Launch to Global Launch across multiple platforms like Web (APK), iOS App Store, Google Play Store, and Amazon Appstore.",
            "Key Achievements:",
            " Managed games with MAUs ranging from 1M to 200M and DAUs from 100K to 20M.",
            " Oversaw marketing budgets ranging from $100K to $1M per month, successfully acquiring 100K to 15M users monthly.",
            " Contributed to over 50+ Free-to-Play (F2P) games and 15+ Applications.",
            " Collaborated with 100+ mobile advertising networks & affiliates.",
        ],
        skills: [
            "Chief Marketing Officer",
            "digital marketing expert"
        ],
        offers: [
            "Mobile Gaming Industry",
            "Mobile User Acquisition & Growth",
            "Market Strategy for Game Launch"
        ]
    },
    {
        name: "Fareed Mannan",
        category: "Fractional sales expert",
        industry: "Fractional sales expert",
        img: "https://www.opengrowth.com/assets/public/opengrowth/images/experts-image/experts/fareed.jpg", // Ensure this image is placed in src/assets/experts/
        profile_url: "fareed-mannan",
        email: "fareed@example.com",
        about: "A seasoned executive with over 20 years of experience in SaaS, known for my success in scaling startups, driving growth, and leading high-performing sales teams.",
        yoe: "20+ years of experience",
        long_about: [
            "I am a seasoned executive with over 20 years of experience, including more than 12 years in SaaS, building large businesses from scratch. I have had notable success at LinkedIn, where I was a founding member of the India sales organization, expanding the client base and leading high-performing sales teams. My expertise lies in scaling startup companies, driving growth through multiple funding rounds, and managing teams across various functions.",
            "At Itilite, I played a pivotal role in taking the company from Series B to Series C funding, tripling signed business despite challenging market conditions. My leadership and strategic vision have consistently driven significant revenue growth and market expansion.",
            "Areas of Expertise:",
            "SaaS Selling",
            "Go-to-Market Strategy and Execution",
            "Sales Strategy and Growth",
            "Business Development and Expansion",
            "Sales Enablement and Coaching",
            "Identifying and Hiring the Right Sales Talent",
            "Team Building and Leadership",
            "Scaling Startups and Managing Growth",
            "Market Fit and Product Feedback",
            "Sales Compensation and Incentives",
        ],
        skills: [
            "Seasoned Sales Expert"
        ],
        offers: [
            "Saas selling to mid market and Enterprise",
            "Scaling business from $1 to $10M ARR and GTM",
            "Building sales teams in terms of structure, identifying the right talent, scaling and sales enablement"
        ]
    }
    ,
    // Removed duplicate entries for "Prashant Vishwa", "Monil Hathi", and "Shenaz Bapooji"
];

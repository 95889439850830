// src/components/AllExperts.jsx

import React, { useState, useMemo, useEffect, Suspense, lazy } from 'react';
import {
  Box,
  Typography,
  Card,
  Grid,
  Button,
  Chip,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
  Dialog,
  DialogTitle,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { PopupButton } from 'react-calendly';
import ExpertCard from './ExpertCard';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { manualExperts } from './ManualExperts';
import GrowthBenefitsCard from './GrowthBenefitsCard';
import useGlobalStyles from '../styles/CourseStyles';
import promoImageSrc from '../assets/expert1.webp'; // Ensure this path is correct
import Slider from 'react-slick'; // Import Slider from react-slick

// Import slick-carousel CSS
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Banner from './Banner';
import AlertBox from "../Courses/AlertBox"; // Ensure the correct import path
import { blogData } from '../Homepage/BlogData';
import LazyLoadComponent from '../Homepage/LazyLoadedComponent'; // Import the LazyLoadComponent

// Lazy loaded components using React.lazy
const TrendingBlogs = lazy(() => import('./TrendingBlogs'));
const FAQSection = lazy(() => import('./FaqSection'));
const ExpertConnectRegister = lazy(() => import('./ExpertConnectRegister'));

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  mainCard: {
    boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
    borderRadius: '8px',
    overflow: 'hidden',
    margin: 'auto',
    border: 'none',
    padding: theme.spacing(4),
    paddingTop: theme.spacing(8),
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: 8,
    },
  },
  featureCard: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    padding: theme.spacing(3),
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    borderRadius: '8px',
    backgroundColor: '#ffffff',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    width: '100%',
    '&:hover': {
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      transform: 'translateY(-2px)',
      backgroundColor: '#0000000a',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
  h6font: {
    color: '#25387c',
  },
  h5font: {
    marginTop: '40px',
  },
  h5: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '2.6rem',
    fontFamily: 'MyCustomFont, sans-serif',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8em',
    },
  },
  searchBar: {
    backgroundColor: '#fff',
    borderRadius: '50px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '400px',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '50px',
      height: '2.1em',
      padding: '0 14px',
    },
    '& .MuiInputBase-input': {
      padding: 0,
      height: '100%',
      fontSize: '0.9rem',
    },
  },
  searchBarBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '2.5rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '16px',
    },
  },
  chipsearchbox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingLeft: '4px',
      paddingRight: '0px',
    },
  },
  searchButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: '#f9bb02',
    color: '#000',
    height: '2.4em',
    fontWeight: 'bold',
    borderRadius: '50px',
    '&:hover': {
      backgroundColor: '#d6a302',
    },
    whiteSpace: 'nowrap',
  },
  newHeading: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  filterChips: {
    display: 'flex',          // Enable flex layout
    flexWrap: 'wrap',         // Allow chips to wrap on desktop
    gap: theme.spacing(1),    // Space between chips
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',       // Allow wrapping on small screens
      justifyContent: 'center', // Align chips to the center
    },
  },
  filterChip: {
    // Existing chip styles
    // Add responsive width for small screens
    [theme.breakpoints.down('sm')]: {
      flex: '0 0 40%',           // Each chip takes up 40% of the container's width
      boxSizing: 'border-box',   // Include padding and border in the width
      marginBottom: theme.spacing(1), // Space between rows
      paddingLeft: theme.spacing(0.4),
      paddingRight: theme.spacing(0.4),
      fontSize: '0.8rem',
    },
  },
  dialogTitle: {
    padding: '0px 0px !important',
  },
  // Styles for Load More button and loading indicator
  loadMoreButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  loadMoreButton: {
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    borderRadius: '50px',
    padding: '8px 24px',
    minWidth: '150px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
  loadingIndicator: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
  // Styles for the promotional card
  promoCardContainer: {
    display: 'flex',
    backgroundColor: '#25387c',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderRadius: '4px',
    border: 'none',
    marginTop: theme.spacing(6),
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  promoCard: {
    display: 'flex',
    border: 'none',
    flexDirection: 'column',
    backgroundColor: '#25387c',
    color: '#fff',
    padding: theme.spacing(4),
    borderRadius: '12px',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: theme.spacing(1.5),
      textAlign: 'center',
    },
  },
  promoImage: {
    width: '400px',
    height: '100%',
    marginLeft: theme.spacing(4),
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      width: '200px',
      marginLeft: 0,
      marginTop: theme.spacing(3),
    },
    objectFit: 'cover',
    loading: 'lazy', // Lazy load the image
  },
  promoTitle: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem',
    },
  },
  promoSubtitle: {
    fontSize: '1rem',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
  },
  promoButton: {
    marginTop: '16px',
    alignSelf: 'flex-start',
    cursor: "pointer",
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'center',
      fontSize: '0.9rem'
    },
  },
  dialogContent: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
    // Prevent horizontal overflow
    overflowX: 'hidden',
  },
}));

// AllExperts Component
const AllExperts = () => {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();
  const theme = useTheme();
  const history = useHistory();
  const [calendlyUrl, setCalendluUrl] = useState('https://calendly.com/vedika-5m0y/30min?month=2024-10')

  // Initialize experts with manual experts data
  const [experts, setExperts] = useState(manualExperts);

  const [selectedExpert, setSelectedExpert] = useState(null);

  // Manage selected category state
  const [selectedCategory, setSelectedCategory] = useState('All');

  // Manage search query state
  const [searchQuery, setSearchQuery] = useState('');

  // Manage visible count for "Load More" functionality
  const [visibleCount, setVisibleCount] = useState(8);

  // Manage loading state when loading more experts
  const [loadingMore, setLoadingMore] = useState(false);

  // Define chip labels with corresponding filter criteria
  const chipLabels = [
    { label: 'All', filter: 'All' },
    { label: 'Growth experts', filter: 'Growth' },
    { label: 'AI experts', filter: 'AI' },
    { label: 'Fractional experts', filter: 'Fractional' },
  ];

  const [showPopup, setShowPopup] = useState(false);
  const [popupClosed, setPopupClosed] = useState(false);

  // AlertBox state
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('success');

  useEffect(() => {
    // Check if the form has been submitted before by checking localStorage
    const formSubmitted = localStorage.getItem('expertConnectSubmitted');
    if (!formSubmitted && !popupClosed) {
      const timer = setTimeout(() => {
        setShowPopup(true);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [popupClosed]);

  // Function to handle popup closure
  const handleClosePopup = () => {
    setShowPopup(false);
    setPopupClosed(true);
  };

  // Callback functions to handle success and error alerts
  const handleSubmitSuccess = (message, type) => {
    setAlertMessage(message);
    setAlertType(type);
    setAlertOpen(true);

    // Set the localStorage flag to prevent popup on reload
    try {
      localStorage.setItem('expertConnectSubmitted', 'true');
    } catch (e) {
      console.error('Could not set localStorage flag:', e);
    }
  };

  const handleSubmitError = (message, type) => {
    setAlertMessage(message);
    setAlertType(type);
    setAlertOpen(true);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  // Handle expert click
  const handleExpertClick = (expert) => {
    setSelectedExpert(expert);
    // Optionally, navigate to expert's detail page
    // history.push(`/expert/${expert.id}`, { expert });
  };

  // Handle chip click
  const handleChipClick = (label) => {
    setSelectedCategory(label);
    setVisibleCount(8); // Reset visibleCount when changing filters
  };

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setVisibleCount(8); // Reset visibleCount when changing search query
  };

  // Handle clearing search
  const handleClearSearch = () => {
    setSearchQuery('');
    setVisibleCount(8); // Reset visibleCount when clearing search
  };

  // Filter experts based on selected category and search query
  const filteredExperts = useMemo(() => {
    let filtered = [];

    if (selectedCategory === 'All') {
      filtered = experts;
    } else if (selectedCategory === 'Growth experts') {
      filtered = experts.filter((expert) =>
        expert.industry.toLowerCase().includes('growth')
      );
    } else if (selectedCategory === 'AI experts') {
      filtered = experts.filter((expert) =>
        expert.industry.toLowerCase().includes('ai')
      );
    } else if (selectedCategory === 'Fractional experts') {
      filtered = experts.filter((expert) =>
        expert.category.toLowerCase().startsWith('fractional')
      );
    }

    // Apply search filter
    if (searchQuery.trim() !== '') {
      const query = searchQuery.toLowerCase();
      filtered = filtered.filter(
        (expert) =>
          expert.name.toLowerCase().includes(query) ||
          expert.about.toLowerCase().includes(query) ||
          expert.industry.toLowerCase().includes(query) ||
          expert.category.toLowerCase().includes(query)
      );
    }

    return filtered;
  }, [experts, selectedCategory, searchQuery]);

  // Determine if "Load More" button should be shown
  const canLoadMore = visibleCount < filteredExperts.length;

  // Handle "Load More" button click
  const handleLoadMore = () => {
    setLoadingMore(true);
    // Simulate API call delay; replace with actual data fetching if necessary
    setTimeout(() => {
      setVisibleCount((prev) => prev + 8);
      setLoadingMore(false);
    }, 1000);
  };

  return (
    <Box style={{ padding: '8px', paddingTop: 0 }}>
      {/* Header Component (if any) */}
      {/* <Header /> */}

      {/* Banner Carousel */}
      <Banner />

      {/* Main Content Card */}
      <Card className={classes.mainCard}>
        {/* New Headings */}
        <Typography variant="h4" className={styleClasses.h4font}>
          Level up the passion in your team and boost your metrics with an experienced Growth Expert
        </Typography>
        <Typography variant="h6" className={`${styleClasses.description} ${classes.h6font}`}>
          Connect with our growth experts to receive personalized advice and accelerate your business growth.
        </Typography>
        {/* End of New Headings */}

        <Typography variant="h5" className={`${styleClasses.h5heading} ${classes.h5font}`}>
          Our Growth Experts
        </Typography>

        {/* Chips and Search Bar */}
        <Box className={classes.chipsearchbox}>
          {/* Chips */}
          <Box className={`${styleClasses.filterChipsContainer} ${classes.filterChips}`}>
            {chipLabels.map((chip, index) => (
              <Chip
                key={index}
                label={chip.label}
                variant={selectedCategory === chip.label ? 'default' : 'outlined'}
                color={selectedCategory === chip.label ? 'primary' : 'default'}
                onClick={() => handleChipClick(chip.label)}
                className={`${styleClasses.filterChip} ${classes.filterChip}`} // Merge global and local chip classes
              />
            ))}
          </Box>

          {/* Search Bar */}
          <Box className={classes.searchBarBox}>
            <TextField
              variant="outlined"
              placeholder="Find an expert in a specific domain"
              value={searchQuery}
              onChange={handleSearchChange}
              className={classes.searchBar}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                ),
                endAdornment:
                  searchQuery && (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="clear search"
                        onClick={handleClearSearch}
                        edge="end"
                        size="small"
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
              }}
            />
          </Box>
        </Box>

        {/* Displaying Experts inside the same card */}
        {filteredExperts.length > 0 ? (
          <>
            <Grid
              container
              spacing={2}
              style={{
                justifyContent: 'center',
                padding: '8px 8px',
              }}
            >
              {filteredExperts.slice(0, visibleCount).map((expert, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  key={expert.id || index}
                  style={{ padding: '8px' }}
                >
                  <ExpertCard
                    expert={expert}
                    handleExpertClick={handleExpertClick}
                    context="allExperts"
                    loading={false}
                    role={null} // Adjust as necessary
                  />
                </Grid>
              ))}
            </Grid>

            {/* Load More Button */}
            {canLoadMore && (
              <Box className={classes.loadMoreButtonContainer}>
                <Button
                  variant="outlined"
                  onClick={handleLoadMore}
                  className={classes.loadMoreButton}
                  disabled={loadingMore}
                >
                  {loadingMore ? <CircularProgress size={24} color="primary" /> : 'Load More'}
                </Button>
              </Box>
            )}
          </>
        ) : (
          <Typography
            variant="h6"
            color="textSecondary"
            style={{ textAlign: 'center', padding: '32px 0' }}
          >
            No experts found matching your criteria.
          </Typography>
        )}
      </Card>

      {/* Promotional Card */}
      <Box className={classes.promoCardContainer}>
        {/* Promotional Text Content */}
        <Card className={classes.promoCard}>
          <Box>
            <Typography
              variant="h4"
              className={`${styleClasses.h4font}`}
              style={{ color: 'white' }}
            >
              Looking for a Growth Associate?
            </Typography>
            <Typography variant="h6" className={`${styleClasses.description} ${classes.h6}`}>
              Let us help you drive user acquisition, boost engagement, and scale your
              business—all at a fraction of the cost of a full-time hire.
            </Typography>
            <PopupButton
              url={calendlyUrl}
              rootElement={document.getElementById('root') || undefined}
              text="Book a discovery call"
              className={`${styleClasses.individualButton} ${classes.promoButton}`}
              variant="contained"
            />
          </Box>
        </Card>

        {/* Promotional Image */}
        <img
          src={promoImageSrc} // Ensure this path is correct or replace with your desired image URL
          alt="Growth Associate"
          className={classes.promoImage}
          loading="lazy" // Lazy load the image
        />
      </Box>
      {/* End of Promotional Card */}

      {/* Growth Benefits Card - Lazy Loaded */}
      <LazyLoadComponent>
        <GrowthBenefitsCard context='allExpert' />
      </LazyLoadComponent>

      {/* Trending Blogs - Lazy Loaded */}
      <Suspense fallback={<div>Loading Blogs...</div>}>
        <LazyLoadComponent>
          <TrendingBlogs blogs={blogData} />
        </LazyLoadComponent>
      </Suspense>

      {/* FAQ Section - Lazy Loaded */}
      <Suspense fallback={<div>Loading FAQs...</div>}>
        <LazyLoadComponent>
          <FAQSection context='allexpert' />
        </LazyLoadComponent>
      </Suspense>

      {/* AlertBox for general messages */}
      {alertOpen &&
        <AlertBox
          open={alertOpen}
          onClose={handleAlertClose}
          msg={alertMessage}
          msg_type={alertType}
          context="expert"
        />
      }

      {/* Popup Dialog - Lazy Loaded */}
      <Suspense fallback={<div>Loading Popup...</div>}>
        <Dialog open={showPopup} onClose={handleClosePopup} maxWidth={'sm'} fullWidth
          classes={{ paper: classes.dialogContent }}>
          <DialogTitle disableTypography className={classes.dialogTitle}>
            <Typography variant="h5" className={`${styleClasses.h5fontPara} ${classes.h5}`} align="center" gutterBottom>
              Connect with us
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleClosePopup}
              style={{ position: 'absolute', right: 8, top: 8, marginBottom: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <ExpertConnectRegister
            onClose={handleClosePopup}
            onSubmitSuccess={handleSubmitSuccess}
            onSubmitError={handleSubmitError}
          />
        </Dialog>
      </Suspense>

      {/* Footer Component (if any) */}
      {/* <Footer /> */}
    </Box>
  );
};

export default AllExperts;

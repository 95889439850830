import React, { useState, useEffect, Suspense } from 'react';
import { isMobile } from 'react-device-detect';
import { Box, Card, CardContent, CardHeader, Grid, Typography, makeStyles } from '@material-ui/core';
import { config, translateText } from '../../../components/functions/Helper';
import { ProfessorRegister, ExpertConnectRegister } from '../../functions/Routes';
import { useHistory } from 'react-router-dom';
import cookie from 'react-cookies';

const CarouselCard = React.lazy(() => import('../../../components/webinar/login/CarouselCard'));
const location = window.location.pathname;
const useStyles = makeStyles(theme => ({
  link: {
    fontWeight: 700,
    cursor: 'pointer',
    color: '#253B7C',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
const Register = () => {
  const [success, setSuccess] = useState(false);
  const [unsuccess, setUnsuccess] = useState(false);
  const [msg, setMsg] = useState('');
  const [ipAddress, setIpAddress] = useState('');
  const history = useHistory();
  const classes = useStyles();
  let config_res = cookie.load('config');

  useEffect(() => {
    fetch('https://ipapi.co/json/')
      .then(res => res.json())
      .then(data => {
        setIpAddress(data.ip);
      })
      .catch(console.log);
  }, []);

  const ImgBox = () => {
    return (
      <Card elevation={0}>
        <CardContent>
          {location === '/register/user' ? (
            <Box paddingTop={6} paddingBottom={6}>
              <img src="/assets/images/register.svg" alt="" height="400px" width="400px" />
            </Box>
          ) : (
            <Box paddingTop={10} paddingBottom={10}>
              <img src="/assets/images/data-entr.gif" alt="" height="300px" width="500px" />
            </Box>
          )}
        </CardContent>
      </Card>
    );
  };

  const StudentForm = () => {
    return (
      <Card>
        {/*<CardHeader title={translateText('SIGN_UP_TITLE2')} />*/}
        <CardHeader title={config.project.key === 'cimp' ? 'Signup on LMS' : 'Connect With Us'} />
        <CardContent>
          <Suspense fallback={<div> </div>}>
            <ExpertConnectRegister />
          </Suspense>
        </CardContent>
      </Card>
    );
  };

  return (
    <Box padding={1}>
      {/* --shrithi: Wrapped the Grid container inside a Card with box shadow */}
      <Card elevation={4}>
        <Box paddingRight={2}>
          <Grid container spacing={3} justifyContent="center" alignItems="center">
            {/* --shrithi: Set CarouselCard to take left half */}
            <Grid item xs={12} md={6}>
              <Suspense fallback={<div> </div>}>
                <CarouselCard />
              </Suspense>
            </Grid>

            {/* --shrithi: Set StudentForm to take right half */}
            <Grid item xs={12} md={6}>
              <Box marginBottom={2} display="flex" justifyContent="end">
                <Typography color="textSecondary" variant="subtitle2">
                  Already Registered?
                  <span
                    className={classes.link}
                    onClick={() => {
                      window.location.pathname = "/";
                    }}
                  >
                    {" "}
                    Login Now!
                  </span>
                </Typography>
              </Box>
              <Box style={{boxShadow: '0 4px 6px rgba(0,0,0,0.1)', border: 'none', paddingBottom: '16px', borderRadius: '8px'}}>
                <Suspense fallback={<div> </div>}>
                  <StudentForm />
                </Suspense>

                <Box marginTop={2} display="flex" justifyContent="center">
                  <Typography color="textSecondary" variant="subtitle2">
                    Are you interested in becoming an expert?
                    <span
                      className={classes.link}
                      onClick={() => {
                        history.push("/register/expert");
                      }}
                    >
                      {" "}
                      Sign up here!
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Box>
  );
};

export default Register;

// RegisterComplete.js

import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Import necessary functions
import { generateID, api } from '../../../Helper';
import axios from 'axios';
import { browserName, browserVersion, osName, osVersion } from 'react-device-detect';

const RegisterComplete = () => {
  const history = useHistory();
  const location = useLocation();

  const { formData, additionalData } = location.state || {};

  React.useEffect(() => {
    if (formData && additionalData) {
      // Call registerUser function
      registerUser(formData, additionalData);
    } else {
      // If no data, redirect to registration page
      history.replace('/register/user');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const registerUser = (formData, additionalData) => {
    const user_view = 'academy'; // or 'webinar', depending on your application logic

    // Obtain img from formData
    const img = formData.img;
    let param_img = formData.img;
    if (user_view === "webinar" && ![null, "", undefined].includes(param_img)) {
      param_img = img;
    }

    // Include data from additionalData
    const { userType, page2Data } = additionalData;

    let t = {
      'user_view': user_view,
      'about': formData.about,
      'img': param_img,
      'fname': formData.fname,
      'lname': formData.lname,
      'email': formData.email,
      'password': formData.password,
      'dob': formData.dob,
      'gender': formData.gender,
      'interest': formData.interest_values,
      'industry': formData.inds,
      'edu': formData.edu,
      'employer': formData.emp,
      'country': formData.country,
      'type': 'student',
      'status': 'student',
      'ip': formData.ip_address,
      'os': osName + " " + osVersion,
      'browser': browserName + " " + browserVersion,
      'signing_as': userType, // Added by shrithi on 2023-10-11
      'courses': page2Data.courses, // Added by shrithi on 2023-10-11
      'experts': page2Data.experts, // Added by shrithi on 2023-10-11
    };

    // Save 'signingAs' to localStorage
    localStorage.setItem('signingAs', userType);

    axios.post(api + '/addUser', t)
      .then((response) => {
        if (response.data['msg'] === 'student') {
          t.id = response.data['id'].toString();
          t.password = '';
          t.user_view = 'academy';
          t.interest = formData.interest_values.toString();

          localStorage.setItem('ac_login_user', JSON.stringify(t));

          let p_info = [
            { 'profile_mail': formData.email },
            { 'profile_type': 'student' },
            { 'profile_id': response.data['id'].toString() },
          ];
          localStorage.setItem('profile_info', JSON.stringify(p_info));

          localStorage.setItem('login_token', generateID());

          // Get 'signingAs' from localStorage
          const signingAs = localStorage.getItem('signingAs');

          // Determine the dashboard URL based on 'signingAs'
          let dashboardUrl = '/dashboard'; // default

          if (signingAs === 'learner') {
            dashboardUrl = '/dashboard';
          } else if (signingAs === 'entrepreneur') {
            dashboardUrl = '/dashboard-entrepreneur';
          } else if (signingAs === 'expert') {
            dashboardUrl = '/dashboard-expert';
          }

          // Redirect to the appropriate dashboard
          setTimeout(() => { window.location = dashboardUrl; }, 1000);

        } else {
          // Handle error, redirect to register page
          history.replace('/register');
        }
      })
      .catch((error) => {
        // Handle error
        console.error('Registration error:', error);
        history.replace('/register');
      });
  };

  return (
    <div>
      Registering...
    </div>
  );
};

export default RegisterComplete;

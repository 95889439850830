// src/data/faqData.js

export const defaultFaqData = [
    {
      question: 'What is online learning at OpenGrowth Startup Academy like?',
      answer:
        'Online learning at OpenGrowth allows you to study from anywhere using your computer or device. You can access videos, reading materials, and interactive activities. This format encourages self-motivation and flexibility, enabling you to balance your studies with work or personal commitments.',
    },
    {
      question: 'What types of courses does OpenGrowth offer?',
      answer:
        'At OpenGrowth, we provide a diverse range of courses designed to meet the needs of both businesses and individuals. For businesses, our offerings include leadership development, team management, digital transformation, project management, and employee upskilling in technical and soft skills. For individuals, we offer courses in personal growth, professional skills, leadership, digital marketing, business management, and more. Each program is carefully crafted to enhance skills, drive career growth, and unlock potential.',
    },
    {
      question: 'Are all the courses available online?',
      answer:
        'Yes. Once enrolled, you have lifetime access to all course materials, allowing you to learn at your own pace and revisit the content whenever you need.',
    },
    {
      question: 'How can students access the course materials?',
      answer:
        'Enrolling in a course is simple! Click on the "Get Started" button on any course card, fill in your details, and complete the payment process to gain immediate access to the course materials.',
    },
    {
      question: 'Will I receive a certificate upon completion of the course?',
      answer:
        'Yes, upon successful completion of any course, you will receive a certificate that you can share on your professional networks or with potential employers.',
    },
  ];
  
  export const contextFaqData = {
    allexpert: [
      {
        question: 'What is a fractional expert, and how can they benefit my business?',
        answer: 'A fractional expert is a highly skilled professional who offers part-time or project-based services, providing businesses with access to expert-level skills and experience without the cost of a full-time hire. This flexibility allows companies to scale quickly, save costs, and gain strategic insights.',
      },
      {
        question: 'What types of fractional experts does Opengrowth offer?',
        answer: 'We provide fractional experts in various fields, including marketing (CMO), finance (CFO), technology (CTO), human resources (CHRO), operations (COO), and more. Our experts are seasoned professionals ready to offer strategic guidance and hands-on support.',
      },
      {
        question: 'How do I know if my business needs a fractional expert? ',
        answer: 'If your business requires expert-level insight or leadership in a particular area but doesn’t need a full-time executive, or if you are looking for specialized skills to support growth, product launches, or financial planning, a fractional expert may be a great fit.',
      },
      {
        question: 'What is the process of working with a fractional expert?',
        answer: 'After an initial consultation, we’ll match you with the right fractional expert based on your business needs. The expert will work with you on a flexible, agreed-upon schedule to provide the necessary support. They can work remotely or on-site as needed.',
      },
      {
        question: 'How is the pricing structured for fractional experts? ',
        answer: 'Pricing depends on the expertise level, scope of work, and hours required. Our fractional experts typically work on an hourly, daily, or project-based fee structure, allowing you to access top-tier talent in a cost-effective way tailored to your budget.',
      },
      // Add more FAQs for AllExpert as needed
    ],
    businessCourse: [
      {
        question: 'What courses are available for business?',
        answer: 'We offer a range of courses tailored for businesses, including leadership development, team management, digital transformation, project management, and employee upskilling in various technical and soft skills.',
      },
      {
        question: 'Do you offer corporate discounts for multiple employees/ teams?',
        answer: 'Yes, we offer special pricing for businesses enrolling multiple employees. Contact us to learn more about bulk enrollment discounts.',
      },
      {
        question: 'How can we measure the impact of training on employee performance?',
        answer: 'We provide detailed reporting on employee progress, completion rates, and post-training assessments to help you evaluate the effectiveness of the courses.',
      },
      {
        question: 'Can our team access courses on different devices or platforms?',
        answer: 'Absolutely. Our courses are accessible on desktops, laptops, tablets, and smartphones, so your team can learn anytime, anywhere.',
      },
      {
        question: 'What kind of post-training support do you provide for businesses?',
        answer: 'We offer continued access to course materials and provide support for follow-up queries. We also offer refresher sessions and additional resources to ensure long-term retention of skills.',
      },
      {
        question: 'Do you offer certifications for employees who complete the courses?',
        answer: 'Yes, employees who complete our courses receive certification, which they can use to enhance their professional profiles.',
      },
      {
        question: 'What industries do your business courses cater to?',
        answer: 'Our courses are designed to be adaptable to various streams like AI, startups, marketing, and entrepreneurship.',
      },
      {
        question: 'How do we get started with Opengrowth’s business training?',
        answer: 'Simply contact us through our website or sign up directly for the business courses of your choice. We will guide you through enrolling your team and setting up the right training program for your organization.',
      },
      // Add more FAQs for Business Course as needed
    ],
    individualCourse: [
      {
        question: 'What types of courses do you offer for individuals?',
        answer: 'Opengrowth offers various courses for personal development, professional skills, leadership, digital marketing, business management, and more. Our courses are designed to enhance your skills and career prospects.',
      },
      {
        question: 'What makes Opengrowth courses different from others?',
        answer: 'Open growth courses stand out because of our practical approach. We focus on real-world applications and current industry trends, ensuring that what you learn is relevant. Additionally, our instructors are industry professionals, providing insights you might not get elsewhere.',
      },
      {
        question: 'Are the courses self-paced or instructor-led?',
        answer: 'We offer both self-paced and instructor-led courses, allowing you to choose the format that best fits your learning style and schedule.',
      },
      {
        question: 'Who are the course instructors?',
        answer: 'The courses are led by experienced experts with industry experience, ensuring you learn from the best.',
      },
      {
        question: 'Do I need any prior knowledge to enroll in your courses?',
        answer: 'Most of our courses are designed for all skill levels, but some advanced courses may require prior knowledge. Check the course details for specific prerequisites.',
      },
      {
        question: 'How can I access the course materials?',
        answer: 'All course materials are available online. Once you enroll, you’ll have access to videos, readings, and assignments in the form of activities and quizzes through our learning platform.',
      },
      {
        question: 'What is the typical duration of a course?',
        answer: 'Course duration varies depending on the subject and format. The courses can be completed at your own speed, typically lasting between 4 to 12 weeks.',
      },
      {
        question: 'Will I receive a certificate from Opengrowth Academy upon completion?',
        answer: 'Yes, upon successful completion of any course, you will receive a certificate that you can share on your professional networks or with potential employers.',
      },
      {
        question: 'Can I retake lessons if I need extra help?',
        answer: 'Yes, you can revisit lessons and materials as many times as you need while you are enrolled in the course.',
      },
      {
        question: 'How do I pay for a course, and are there any payment plans available?',
        answer: 'You can pay for courses via credit card, PayPal, or bank transfer. We also offer flexible payment plans for select courses to make learning more accessible.',
      },
      // Add more FAQs for Individual Course as needed
    ],
    contentMarketing: [
      {
        question: 'What services do you offer for startups? ',
        answer: 'We provide a wide range of services tailored to startups, including content marketing, social media marketing, personal branding, recruitment, payroll management, and overall startup support to help you scale efficiently.',
      },
      {
        question: 'How can content marketing benefit my startup?',
        answer: "Content marketing helps establish your brand's voice, build trust with your audience, and drive organic traffic to your website. It’s a cost-effective way to generate leads and nurture customer relationships.",
      },
      {
        question: 'Why is personal branding important for startup founders?',
        answer: 'Personal branding helps founders establish authority in their industry, build a strong professional network, and attract investors, clients, and top talent to their startup.',
      },
      {
        question: 'Do you handle recruitment for all roles, or only specific ones?',
        answer: 'We handle recruitment for various roles, including entry-level, mid-level, and senior positions. We also specialize in finding talent that aligns with your startup’s culture and vision.',
      },
      {
        question: 'What does your payroll management service include?',
        answer: 'Our payroll service includes end-to-end management of employee salaries, ensuring accurate and timely payments to your team.',
      },
      {
        question: 'Can I customize a package of services to fit my startup’s needs?',
        answer: 'Absolutely! We offer flexible packages that can be tailored to meet your specific business needs and goals, ensuring you get the most value from our services.',
      },
      // Add more FAQs for Content Marketing as needed
    ],
  };
  
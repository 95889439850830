// src/components/AlertBox.jsx

import React from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import useGlobalStyles from '../styles/CourseStyles';

// Define custom styles
const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius * 2,
        backgroundColor: '#ffffff', // White background
    },
    dialogContent: {
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        },
        overflowX: 'hidden',
    },
    dialogActions: {
        justifyContent: 'center',
    },
    messageText: {
        color: theme.palette.text.primary,
        fontSize: '1rem',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

/**
 * AlertBox Component
 * 
 * Props:
 * - open (bool): Controls whether the alert is open.
 * - onClose (func): Callback function to handle closing the alert.
 * - msg (string): The message to display.
 * - msg_type (string): The type of message ('error', 'warning', 'info', 'success').
 *    Currently unused, but can be utilized for future enhancements.
 */
const AlertBox = ({ open, onClose, msg, msg_type }) => {
    const classes = useStyles();
    const styleClasses = useGlobalStyles();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{ paper: classes.dialogPaper }}
        >
            {/* Close Icon Button */}
            <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={onClose}
            >
                <CloseIcon />
            </IconButton>

            <DialogContent className={classes.dialogContent}>
                <Typography variant="subtitle1" className={styleClasses.h6banner} align='center'>
                    {msg}
                </Typography>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button onClick={onClose} color="primary" variant="contained" className={styleClasses.businessButton}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

// PropTypes for type checking
AlertBox.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    msg: PropTypes.string.isRequired,
    msg_type: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
};

export default AlertBox;

// src/components/LazyLoadComponent.js
import React, { useState, useEffect, Suspense } from 'react';
import { CircularProgress, Box } from '@material-ui/core';

const LazyLoadComponent = ({ children, fallback = <CircularProgress /> }) => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = React.useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.disconnect();
                    }
                });
            },
            {
                threshold: 0.1
            }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);

    return (
        <div ref={ref}>
            {isVisible ? (
                <Suspense fallback={fallback}>
                    {children}
                </Suspense>
            ) : (
                <Box display="flex" justifyContent="center" alignItems="center" height={200}>
                    {fallback}
                </Box>
            )}
        </div>
    );
};

export default LazyLoadComponent;

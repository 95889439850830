// src/components/FeaturesSection.jsx

import React from 'react';
import { Box, Typography, makeStyles, useTheme, useMediaQuery } from '@material-ui/core';
import useGlobalStyles from '../styles/CourseStyles';

const useStyles = makeStyles((theme) => ({
    sectionContainer: {
        padding: theme.spacing(8, 5, 0, 5),
        backgroundColor: '#ffff',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(4, 1),
        },
    },
    paragraph: {
        maxWidth: '800px',
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            padding: theme.spacing(0, 2),
        },
    },
    boldText: {
        fontWeight: 'bold',
    },
    h6 : {
        width: '100%'
    }
}));

const FeaturesSection = () => {
    const classes = useStyles();
    const styleClasses = useGlobalStyles();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box className={classes.sectionContainer}>
            <Typography
                variant={isSmall ? "h5" : "h4"}
                align="center"
                gutterBottom
                className={styleClasses.h4font}
            >
                Introducing Growth Copilot:
            </Typography>
            <Typography
                variant="h6"
                align="center"
                className={`${styleClasses.description} ${classes.h6}`}
            >
                Your on-demand advisor for expert guidance. Access personalized insights and
                recommendations anytime, even when your trusted experts are unavailable,
                ensuring no delays in your decision-making. Growth Copilot is designed to fill in
                the gaps left by your unavailable experts.
            </Typography>
        </Box>
    );
};

export default FeaturesSection;

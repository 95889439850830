// src/components/AboutUs.jsx

import React from 'react';
import {
  Box,
  Typography,
  Card,
  Grid,
  makeStyles,
  useTheme,
  useMediaQuery,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

// Import your images here
import aboutUsImage from '../assets/aboutus1.jpg'; // Replace with your actual image
import missionImage from '../assets/mission.png'; // Replace with your actual image
import visionImage from '../assets/vision.png'; // Replace with your actual image
import useGlobalStyles from '../styles/CourseStyles';
import ImageCarousel from './Aboutus Carousel';

const useStyles = makeStyles((theme) => ({
  section: {
    padding: theme.spacing(0, 1),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 0),
    },
  },
  cardContainer: {
    marginBottom: theme.spacing(4),
  },
  // Removed the 'card' class to eliminate 50-50 layout
  card: {
    padding: theme.spacing(4),
    boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  // New style for subheading with blue background
  subheading: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width: '98%',
    display: 'inline-block',
    padding: theme.spacing(0.5, 2),
    borderRadius: '4px',
    fontSize: "33px",
    lineHeight: '60px',
    marginBottom: theme.spacing(2),
  },
  h6: {
    fontWeight: '600',
    color: theme.palette.primary.main,
  },
  // Removed 'content' and 'image' related styles as they're no longer needed
  smallCard: {
    marginTop: '24px',
    padding: theme.spacing(2),
    borderRadius: '8px',
    textAlign: 'center',
    height: '100%',
  },
  missionContent: {
    width: '50%',
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingRight: 0,
      textAlign: 'center',
    },
  },
  visionContent: {
    width: '50%',
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingRight: 0,
      textAlign: 'center',
    },
  },
  missionVisionImage: {
    width: '50%',
    height: 'auto',
    borderRadius: '8px',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
  },
  h4font: {
    color: "#f9bb02",
  },
  // Styles for the dotted list
  listItem: {
    alignItems: 'center',
    paddingLeft: 0,
    paddingRight: 0,
  },
  listItemIcon: {
    minWidth: 'auto',
  },
  listText: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  listTitle: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0),
  },
}));

const OpengrowthAboutUs = () => {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      {/* First Card: About Us */}
      <Box className={`${classes.section} ${classes.cardContainer}`}>
        <Card className={classes.card} elevation={3}>
          <Typography variant="h4" className={`${styleClasses.h4font} ${classes.h4font}`} gutterBottom>
            About Us
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.subheading} ${styleClasses.h3font}`}
            gutterBottom
          >
            Navigate Growth Challenges with Our Expert-Driven, Full-Stack Solutions for Every Startup Challenge.
          </Typography>
          <Typography variant="h6" className={styleClasses.description} paragraph>
          Startup owners often feel overwhelmed having to juggle several roles and responsibilities, wearing far too many hats for their liking. Sometimes we all need a helping hand, and Growth Experts can be critical during a challenging phase of your startup journey. Getting the right expert with the necessary expertise at the right time is usually difficult, not to mention expensive. 
Experts can save you a lot of time and resources through the wisdom and knowledge they have garnered over the years solving similar problems to yours.
          </Typography>
          {/* <Typography
            variant="h6"
            className={`${styleClasses.h5heading} ${classes.h6}`}
            paragraph
          >
            Sound Familiar?
          </Typography>
          <Typography variant="h6" className={styleClasses.description} paragraph>
            At this point, startup founders usually seek expert help to accelerate growth turning to anyone
            they can get their hands on, burning more cash than making it. Finding themselves back
            where they started, they frantically search through the same list of unorganized and
            untargeted service providers leaving them stumped.
          </Typography>
          <Typography variant="h6" className={styleClasses.description} paragraph>
            You've invested in people and burned through a ton of cash, yet your goals aren’t realized.
            You get the feeling that real growth might be out of reach.
          </Typography> */}
        </Card>

        <Card className={classes.card} elevation={3}>
          <Typography
            variant="subtitle1"
            className={`${classes.subheading} ${styleClasses.h3font}`}
            gutterBottom
          >
            What drives us?
          </Typography>
          <Typography variant="h6" className={styleClasses.description} paragraph>
          We started OpenGrowth envisioning a Startup Venture Assistance company to equip startups with battle-tested experts who’ve been there and done that, with the ready resources and guidance they need.
          </Typography>
          <Typography variant="h6" className={styleClasses.description} paragraph>
          We’ve built a network of seasoned CXOs, growth strategists, and startup specialists, who know how to drive real results.
          </Typography>
          <Typography
            variant="h6"
            className={`${styleClasses.h5heading} ${classes.h6}`}
            paragraph
          >
            What’s new you may ask?
          </Typography>
          <Typography variant="h6" className={styleClasses.description} paragraph>
          They aren't your typical “consultants”, they are Hands-on Experts who know how to build, scale, and grow consistently at a fraction of your previous costs. We are a full-stack partner in your growth journey, offering expert-driven growth services covering everything from branding, digital marketing, content strategy, website design, and payroll services. 
          </Typography>
          <Typography variant="h6" className={styleClasses.description} paragraph>
          We stitch together an expert-driven bouquet of services to ensure your startup is never left in the lurch and we are there to support you at every step of your startup journey.
          </Typography>
        </Card>

        {/* Our Services */}
        <Card className={classes.card} elevation={3}>
          <Typography
            variant="subtitle1"
            className={`${classes.subheading} ${styleClasses.h3font}`}
            gutterBottom
          >
            Our Services
          </Typography>
          <List>
            {/* Growth Experts */}
            <ListItem className={classes.listItem}>
              <ListItemIcon className={classes.listItemIcon}>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <Box className={classes.listText}>
                <Typography variant="h6" className={`${styleClasses.h6font} ${classes.listTitle}`}>
                  Growth Experts -
                </Typography>
                <Typography variant="h6" className={`${styleClasses.description}`}>
                  Expert-level insights to address your core challenges
                </Typography>
              </Box>
            </ListItem>

            {/* Growth Associates */}
            <ListItem className={classes.listItem}>
              <ListItemIcon className={classes.listItemIcon}>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <Box className={classes.listText}>
                <Typography variant="h6" className={`${styleClasses.h6font} ${classes.listTitle}`}>
                  Growth Associates -
                </Typography>
                <Typography variant="h6" className={`${styleClasses.description}`}>
                  Dedicated support to implement expert strategies
                </Typography>
              </Box>
            </ListItem>

            {/* Startup Services */}
            <ListItem className={classes.listItem}>
              <ListItemIcon className={classes.listItemIcon}>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <Box className={classes.listText}>
                <Typography variant="h6" className={`${styleClasses.h6font} ${classes.listTitle}`}>
                  Startup Services -
                </Typography>
                <Typography variant="h6" className={`${styleClasses.description}`}>
                  Complement our experts and associates perfectly
                </Typography>
              </Box>
            </ListItem>

            {/* OpenGrowth Community */}
            <ListItem className={classes.listItem}>
              <ListItemIcon className={classes.listItemIcon}>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <Box className={classes.listText}>
                <Typography variant="h6" className={`${styleClasses.h6font} ${classes.listTitle}`}>
                  OpenGrowth Community -
                </Typography>
                <Typography variant="h6" className={`${styleClasses.description}`}>
                  An interactive space to gain valuable insights, ensuring that knowledge is always within reach
                </Typography>
              </Box>
            </ListItem>

            {/* Growth Studio */}
            <ListItem className={classes.listItem}>
              <ListItemIcon className={classes.listItemIcon}>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <Box className={classes.listText}>
                <Typography variant="h6" className={`${styleClasses.h6font} ${classes.listTitle}`}>
                  Growth Studio -
                </Typography>
                <Typography variant="h6" className={`${styleClasses.description}`}>
                  Provides a platform for you to learn from others and share your experiences
                </Typography>
              </Box>
            </ListItem>

            {/* Growth Co-Pilot */}
            <ListItem className={classes.listItem}>
              <ListItemIcon className={classes.listItemIcon}>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <Box className={classes.listText}>
                <Typography variant="h6" className={`${styleClasses.h6font} ${classes.listTitle}`}>
                  Growth Co-Pilot -
                </Typography>
                <Typography variant="h6" className={`${styleClasses.description}`}>
                  Provides you with an expert clone to keep your growth on track round the clock
                </Typography>
              </Box>
            </ListItem>

            {/* Startup Academy */}
            <ListItem className={classes.listItem}>
              <ListItemIcon className={classes.listItemIcon}>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <Box className={classes.listText}>
                <Typography variant="h6" className={`${styleClasses.h6font} ${classes.listTitle}`}>
                  Startup Academy -
                </Typography>
                <Typography variant="h6" className={`${styleClasses.description}`}>
                  Empower your team to bridge skills and knowledge gaps with the help of expert-driven courses
                </Typography>
              </Box>
            </ListItem>
          </List>
        </Card>
      </Box>
      <Box className={`${classes.section} ${classes.cardContainer}`}>
        <Card className={classes.card} elevation={3}>
          <Typography
            variant="subtitle1"
            className={`${classes.subheading} ${styleClasses.h3font}`}
            gutterBottom
          >
            Our Story
          </Typography>
          <ImageCarousel />

        </Card>

      </Box>

      {/* Mission Card */}
      <Box className={`${classes.section} ${classes.cardContainer}`}>
        <Card className={classes.card} elevation={3}>
          <Typography variant="h4" className={`${styleClasses.h4font} ${classes.h4font}`} gutterBottom>
            Mission
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.subheading} ${styleClasses.h3font}`}
            gutterBottom
          >
            We aim to Educate and Empower startup founders. If your startup is lost at sea consider us the lighthouse that guides you home. 
          </Typography>
          <Typography variant="h6" className={styleClasses.description} paragraph>
          We aim to demystify the process of identifying challenges that startups encounter during their journey and provide experts to decode the bottlenecks and a support ecosystem. Our mission is to help you uncover your potential and make growth sustainable one strategic move at a time.
          </Typography>

        </Card>

        {/* Our Services */}

      </Box>

      {/* Vision Card */}
      <Box className={`${classes.section} ${classes.cardContainer}`}>
        <Card className={classes.card} elevation={3}>
          <Typography variant="h4" className={`${styleClasses.h4font} ${classes.h4font}`} gutterBottom>
            Vision
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.subheading} ${styleClasses.h3font}`}
            gutterBottom
          >
            A world where expert insights and resources are just a keystroke away
          </Typography>
          <Typography variant="h6" className={styleClasses.description} paragraph>
          OpenGrowth envisions a world where startups no longer feel lost in their growth journey, but instead are equipped with the resources, experts, and insights needed to make decisions that matter.
          </Typography>

        </Card>

        {/* Our Services */}

      </Box>
      
    </Box>
  );
};

export default OpengrowthAboutUs;

// PricingCards Component
import React from 'react';
import {
  Box,
  Typography,
  Card,
  Button,
  Grid,
  makeStyles,
} from '@material-ui/core';
import useGlobalStyles from '../styles/CourseStyles'; // Ensure this path is correct

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    marginTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
  },
  titleBox: {
    padding: theme.spacing(2, 6)
  },
  gridItem: {
    display: 'flex', // Make Grid items flex containers
  },
  card: {
    flex: 1, // Allow Card to grow and fill the Grid item
    display: 'flex', // Enable Flexbox in Card
    flexDirection: 'column', // Stack children vertically
    boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
    borderRadius: '8px',
    overflow: 'hidden',
    padding: theme.spacing(3, 1),
    textAlign: 'left',
    border: 'none',
    backgroundColor: '#ffffff', // Ensure card has a white background
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  h6: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    fontWeight: '500',
    backgroundColor: '#f9bb02',
    marginBottom: theme.spacing(2),
    borderRadius: '12px',
    padding: theme.spacing(1),
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
    },
  },
  subtitle2: {
    color: '#25387c',
    marginBottom: theme.spacing(2),
  },
  price: {
    fontWeight: '500',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  includesTitle: {
    color: '#25387c',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  processTitle: {
    color: '#25387c',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  benefitsTitle: {
    color: '#25387c',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  listItem: {
    marginBottom: theme.spacing(1),
  },
  button: {
    
    marginTop: 'auto', 
    '&:hover': {
      backgroundColor: '#e6b800',
    },
  },
}));

const plans = [
  {
    title: 'Expert on Demand',
    description: 'Perfect for short-term needs or one-off consultations',
    price: 'Starting From: $100 / hour',
    process: [
      'Choose your expert',
      'Select the number of hours',
      'Schedule your appointment with our team',
    ],
    benefits: [
      'Flexible and on-demand',
      'Pay only for the hours you need',
      'Access to a wide range of experts',
    ],
  },
  {
    title: 'Expert Access Plan',
    description: 'Ideal for businesses requiring consistent guidance with flexibility.',
    price: '$2000 for 6 hours / week',
    includes: [
      '6 hours of expert consultation per week',
      'Scheduled weekly sessions',
      'Dedicated support team',
    ],
    benefits: [
      'Predictable, budget-friendly solution',
      'Focused expertise to drive results',
    ],
  },
  {
    title: 'Comprehensive Support Plan',
    description: 'Best for businesses looking for in-depth support and leadership guidance.',
    price: '$7000 for 12 hours / week',
    includes: [
      '12 hours of expert consultation per week',
      'Free dedicated associate for additional support',
      'Priority scheduling',
    ],
    benefits: [
      'Increased strategic input',
      'Team augmentation with no extra cost',
      'Ideal for scaling and long-term projects',
    ],
  },
  {
    title: 'Customized Expert Plan',
    description: 'Tailored to your specific business needs.',
    price: 'Based on requirements',
    process: [
      'Share your business requirements',
      'Build a customized plan with our team',
      'Receive a personalized quote',
    ],
    benefits: [
      'Fully adaptable to your unique challenges',
      'Access to multi-disciplinary expertise',
      'Scalable and flexible options',
    ],
  },
];

const PricingCard = ({ plan }) => {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();

  return (
    <Card className={classes.card}>
      <Typography
        variant="h6"
        className={`${styleClasses.h6font} ${classes.h6}`}
      >
        {plan.title}
      </Typography>
      <Typography
        variant="subtitle2"
        className={`${styleClasses.subtitle2font} ${classes.subtitle2}`}
      >
        {plan.description}
      </Typography>
      <Typography
        variant="h5"
        color="textPrimary"
        className={`${classes.price} ${styleClasses.h6font}`}
      >
        {plan.price}
      </Typography>

      {plan.includes && (
        <>
          <Typography
            variant="subtitle1"
            className={`${classes.includesTitle} ${styleClasses.h6font}`}
          >
            Includes
          </Typography>
          <ul>
            {plan.includes.map((item, index) => (
              <li key={index} className={classes.listItem}>
                <Typography
                  variant="body1"
                  className={styleClasses.body1font}
                >
                  {item}
                </Typography>
              </li>
            ))}
          </ul>
        </>
      )}

      {plan.process && (
        <>
          <Typography
            variant="subtitle1"
            className={`${classes.processTitle} ${styleClasses.h6font}`}
          >
            Process
          </Typography>
          <ul>
            {plan.process.map((item, index) => (
              <li key={index} className={classes.listItem}>
                <Typography
                  variant="body1"
                  className={styleClasses.body1font}
                >
                  {item}
                </Typography>
              </li>
            ))}
          </ul>
        </>
      )}

      <Typography
        variant="subtitle1"
        className={`${classes.benefitsTitle} ${styleClasses.h6font}`}
      >
        Benefits
      </Typography>
      <ul>
        {plan.benefits.map((benefit, index) => (
          <li key={index} className={classes.listItem}>
            <Typography
              variant="body1"
              className={styleClasses.body1font}
            >
              {benefit}
            </Typography>
          </li>
        ))}
      </ul>

      <Button variant="contained" className={`${styleClasses.individualButton} ${classes.button}`}>
        Get Started
      </Button>
    </Card>
  );
};

const PricingCards = () => {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();

  return (
    <Box className={`${classes.cardContainer}`}>
        <Box className={classes.titleBox}>
        <Typography variant="h4" className={styleClasses.h4font} gutterBottom>
          Pricing Plans
        </Typography>
        <Typography variant="h5" className={`${styleClasses.h5font} ${classes.subtitlefont}`} gutterBottom>
          Individual
        </Typography>
      </Box>
      <Box className={`${styleClasses.bannerContainer} ${classes.cards}`}>
      <Grid container spacing={4} justify="center" alignItems="stretch">
        {plans.map((plan, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            key={index}
            className={classes.gridItem} // Apply flex to Grid item
          >
            <PricingCard plan={plan} />
          </Grid>
        ))}
      </Grid>
      </Box>
    </Box>
  );
};

export default PricingCards;

// src/components/TrendingBlogs.jsx

import React, { useRef } from "react";
import { Link } from "react-router-dom"; 
import {
  Box,
  Typography,
  Button,
  Card,
  CardMedia,
  CardContent,
  Avatar,
  Chip,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import Slider from "react-slick";
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import useGlobalStyles from "../styles/CourseStyles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
    overflow: "hidden",
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    boxShadow: "0 4px 6px rgba(0,0,0,0.2)",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    borderRadius: theme.shape.borderRadius * 2,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      textAlign: "center",
      justifyContent: "center",
    },
  },
  allBlogsButton: {
    borderRadius: "20px",
    padding: "6px 16px",
    textTransform: "none",
    fontWeight: "bold",
    backgroundColor: "#25387c",
    color: "white",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    "&:hover": {
      backgroundColor: "#3A4A6A",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
    },
  },
  sliderWrapper: {
    position: "relative",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    "& .slick-list": {
      margin: "0 -8px",
    },
    "& .slick-slide": {
      padding: "16px 8px",
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  navigationButton: {
    minWidth: "40px",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    padding: 0,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  styledCard: {
    height: "380px",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0 4px 6px rgba(0,0,0,0.2)",
    borderRadius: "8px",
    overflow: "hidden",
    transition: "0.3s",
    "&:hover": {
      backgroundColor: "#0000000a",
      boxShadow: "0 8px 16px rgba(0,0,0,0.2)",
      transform: "translateY(-2px)",
    },
  },
  styledCardMedia: {
    height: "200px",
    width: "100%",
  },
  styledCardContent: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "16px",
  },
  styledTitle: {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  body2: {
    marginTop: 8,
  },
  authorBox: {
    display: "flex",
    alignItems: "center",
    marginTop: "8px",
    marginBottom: "1.5rem",
  },
  categoryChip: {
    backgroundColor: '#e0e0e0',
    color: theme.palette.common.black,
    borderRadius: "15px",
  },
  chipContainer: {
    marginBottom: '1rem',
    marginTop: '0rem',
  },
  slideBox: {
    transition: "0.3s",
    "&:hover": {
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    },
  },
  h6font: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  }
}));

const TrendingBlogs  = ({
  blogs,
  context,
  subTitle = "Read blogs recommended by OpenGrowth Expert Community",
  showViewAllButton = true,
}) => {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();
  const sliderRef = useRef(null);
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up("lg"));
  const isMedium = useMediaQuery(theme.breakpoints.between('md', 'lg')); // For tablet
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  // Define title based on context
  const getTitle = () => {
    if (context === "copilot") {
      return (
        <Typography
          variant={isSmall ? "h5" : "h4"}
          gutterBottom
          className={styleClasses.h4font}
        >
          While You Wait for Growth Copilot, Dive Into Our Expert Blogs
        </Typography>
      );
    } else {
      return (<Typography
        variant={isSmall ? "h5" : "h4"}
        gutterBottom
        className={styleClasses.h4font}
      >
        Trending Blogs by Experts
      </Typography>
      );
    }
  };

  // Define subTitle based on context if needed
  // Currently, using the default subTitle passed as prop

  const getSlidesToShow = () => {
    if (isLarge) return 4;    // Desktop
    if (isMedium) return 2;   // Tablet
    if (isSmall) return 1;    // Mobile
    return 3;                 // Other sizes (optional)
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: getSlidesToShow(),
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: theme.breakpoints.values.lg, // 1280px
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: theme.breakpoints.values.md, // 960px
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: theme.breakpoints.values.sm, // 600px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.headerContainer}>
        <Box style={{ textAlign: isSmall ? "center" : "left" }}>
          {getTitle()}

          {subTitle && (
            <Typography
              variant="h6"
              className={`${styleClasses.description} ${classes.h6font}`}
            >
              {subTitle}
            </Typography>
          )}
        </Box>

        {showViewAllButton && !isSmall && (
          <Button
            variant="contained"
            className={`${styleClasses.businessButton} ${classes.allBlogsButton}`}
            component="a"
            href="https://blogs.opengrowth.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            View all blogs
          </Button>
        )}
      </Box>

      <Box className={classes.sliderWrapper}>
        <Slider ref={sliderRef} {...settings}>
          {blogs.map((blog, index) => (
            <Box key={index} className={classes.slideBox}>
              <BlogCard {...blog} />
            </Box>
          ))}
        </Slider>

        {blogs.length > getSlidesToShow() && (
          <>
            <Box
              style={{
                position: "absolute",
                top: "50%",
                left: 0,
                transform: "translateY(-50%)",
                zIndex: 1,
              }}
            >
              <Button className={classes.navigationButton} onClick={handlePrev}>
                <ArrowBackIosIcon />
              </Button>
            </Box>
            <Box
              style={{
                position: "absolute",
                top: "50%",
                right: 0,
                transform: "translateY(-50%)",
                zIndex: 1,
              }}
            >
              <Button className={classes.navigationButton} onClick={handleNext}>
                <ArrowForwardIosIcon />
              </Button>
            </Box>
          </>
        )}
      </Box>

      {showViewAllButton && isSmall && (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: theme.spacing(2),
          }}
        >
          <Button
            variant="contained"
            className={`${styleClasses.businessButton} ${classes.allBlogsButton}`}
            component="a"
            href="https://www.blogs.opengrowth.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            View All Blogs
          </Button>
        </Box>
      )}
    </Box>
  );
};

const BlogCard = ({ image, title, author, authorImage, category, link }) => {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();
  const theme = useTheme();

  return (
    <Card
      component="a"
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className={classes.styledCard}
      style={{ textDecoration: 'none', color: 'inherit' }} // Ensure no underline and inherit text color
    >
      <CardMedia
        className={classes.styledCardMedia}
        image={image}
        title={title}
      />
      <CardContent className={classes.styledCardContent}>
        <Box>
          <Typography
            gutterBottom
            variant="body2"
            className={styleClasses.body2font}
          >
            {title}
          </Typography>
          <Box className={classes.authorBox}>
            <Avatar
              src={authorImage}
              alt={author}
              style={{ width: 32, height: 32, marginRight: theme.spacing(1) }}
            />
            <Typography variant="body2" noWrap className={`${styleClasses.body2font} ${classes.body2}`}>
              {author}
            </Typography>
          </Box>
        </Box>
        <Box className={`${styleClasses.filterChipsContainer} ${classes.chipContainer}`} >
          <Chip
            label={category}
            size="small"
            className={styleClasses.filterChip}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default TrendingBlogs;

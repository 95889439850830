import {  Box,CircularProgress,ThemeProvider,Typography } from "@material-ui/core";
import React, { useMemo,Suspense,useState,useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import cookie from 'react-cookies';
import {
  BrowserRouter as Router, Route, Switch 
} from 'react-router-dom';
import axios from 'axios';
import CryptoJS from 'crypto-js';

/* admin starts*/
// import GetImages from './components/admin/images/GetImages';
// import PopupUpload from './components/admin/images/PopupUpload';
import AdminLogin from './components/admin/login/AdminLogin';
import AdminLogs from './components/admin/logs/AdminLogs';
import WebinarLogs from './components/admin/logs/WebinarLogs';
import Logs from './components/admin/logs/Logs';
import GeneralSettings from './components/admin/settings/GeneralSettings';
import UpdateSpeakers from './components/admin/speakers/UpdateSpeakers';
import Attendees from './components/admin/users/Attendees';
import ModeratorsAdminList from './components/admin/users/ModeratorsAdminList';
import AcademyUsers from './components/admin/users/AcademyUsers';
import AllUsers from './components/admin/users/AllUsers';
import RegisteredUserList from './components/admin/users/RegisteredUserList';
import WebinarRegistration from './components/admin/users/WebinarRegistration';
import ScheduleWebinar from './components/admin/webinar/ScheduleWebinar';
import NotFound from './components/error/NotFound';

/* admin ends*/


/* academy starts*/
import { CalenderCard, CartStepForm, CourseEnrolled, CoursesBookmarked, CourseSort,TrendingCourses, CourseTagsSearch, MyCourses, PopularCourse, ViewedRecently } from './components/functions/Routes';
import { Calender_Skeleton,  Horizontal_Course_Skeleton,  Vertical_Course_Skeleton,ViewMentors_Skeleton } from './components/functions/Skeleton';
import { Academytheme,Ogtheme } from "./components/functions/useStyles";

//import OG_Homepage from './components/functions/homepage/home/og';

//import Dashboard from './components/functions/dashboard';
import Dashboard from './components/functions/dashboard/Dashboard.js';
import CourseDashboard from './components/functions/dashboard';
import Homepage from './components/functions/homepage/home';
import WomensDay from './components/functions/homepage/womensday';
import Event from './components/functions/homepage/events';
import About from './components/functions/homepage/aboutus';
import PrivacyPolicy from './components/functions/homepage/privacy';
import ManageModules from './components/admin/module/ManageModules';
import ManageSettings from './components/admin/settings';
import Test from './components/test/Test';



// jan 9 
//import Test from './components/functions/og/dummy';
import GoogleTest from './components/test/Test.js';

import EditProfile from './components/functions/users/register/EditProfile';
import ProfessorRegister from './components/functions/users/register/professor';
import StudentRegister from './components/functions/users/register/student';
import ForgotPassword from './components/functions/users/forgot';
import OpenGrowthLogin from './components/functions/users/login/OpenGrowthLogin';
import Profile from './components/functions/profile';

import Subscription from './components/functions/subscription';
import University from './components/functions/university';
import Coupon from './components/functions/coupon';
import Courses from './components/functions/courses';
import Quiz from './components/functions/quiz';
import Assignment from './components/functions/assignment/learner/AssignmentRender';

//import Questions from './components/functions/quiz/question';
//import CourseView from './components/functions/courses/courseshow/CourseView';
import CourseViewNew from './components/functions/courses/courseshownew/CourseView';
import { UserProvider } from './components/functions/courses/UserContext';
import Chat from './components/functions/chat';

import Slots from './components/functions/mentors/slots';
import MentorshipDashboard from './components/functions/mentors/dashboard';
import OurMentors from './components/functions/mentors/pages/OurMentors';
import MyMentors from './components/functions/mentors/pages/MyMentors';
import RequestMentor from './components/functions/mentors/pages/RequestMentor';
import MentorshipTimeline from './components/functions/mentors/pages/MentorshipTimeline';
import GetMentors from './components/functions/mentors/pages/GetMentors';
// import Appointments from './components/functions/mentors/appointments';
import Appointments from './components/functions/mentors/appointments_demo';
import MentorsPayment from './components/functions/mentors/pages/MentorsPayment';

import DiscordPage from './components/functions/pages/DiscordPage';
import Blogs from './components/functions/blogs/BlogMain';
import Channels from './components/functions/blogs/Channels';
import AllBlogs from './components/functions/blogs/AllBlogs';

import Speakers from './components/webinar/dashboard/Speakers';
//import OpenGrowth from './components/opengrowth';

/* academy ends*/

/* webinar starts*/
import WebinarLanding from './components/webinar/content/landing/WebinarLanding';
import WebinarThanks from './components/webinar/content/thankyou/WebinarThanks';
import ForgotForm from './components/webinar/forgot/ForgotLogin';
import WebinarLogin_EmailOnly from './components/webinar/login/WebinarLogin_EmailOnly';
import RegisterForm from './components/webinar/register/RegisterLogin';
import WebinarWaiting from './components/webinar/waiting';
import WebinarRunning from './components/webinar/webinar';
import WebinarDashboard from './components/webinar/dashboard';
import {user_view,freshPages,course_nested,mentor_nested,blog_nested,webinar_nested,community_nested,quiz_nested,admin_nested,config} from  './components/functions/Helper';
 
import DashboardHub from './components/functions/hub';
import AddCommunity from './components/functions/community/stepper';
import DashboardCommunity from './components/functions/community/Dashboard.js';
import ListCommunity from './components/functions/community/ListCommunity.js';
import JoinedCommunity from './components/functions/community/JoinedCommunity.js';
import Connections from './components/functions/connections';
import CommunityChat from './components/functions/community/chat';
import CommunityJoinRequest from './components/functions/community/request';
import Community from './components/functions/community';
import DiscussionForum from './components/functions/community/forum';
import GroupChat from './components/functions/community/groupchat';


import OgHomepage from "./components/opengrowth/Homepage/OpengrowthHome.js";
import AllExperts from "./components/opengrowth/Experts/AllExperts.js";
import AllCourses from "./components/opengrowth/Courses/Courses.js";
import ExpertProfile from "./components/opengrowth/Experts/ProfilePage.js";
import IndividualCourse from "./components/opengrowth/Courses/IndividualCourse.js";
import BusinessCourse from "./components/opengrowth/Courses/BusinessCourse.js";
import GrowthStudioHome from "./components/opengrowth/Growths/GrowthStudioHome.js";
import GrowthAssociate from "./components/opengrowth/Growths/GrowthAssociateHome.js";
import ContentMarketingHome from "./components/opengrowth/Growths/ContentMarketingHome.js";
import CourseDescription from "./components/opengrowth/Courses/CourseDescription.js";
import OpengrowthAboutUs from "./components/opengrowth/Growths/OpengrowthAbout.js";
import ExpertConnectRegister from "./components/opengrowth/Experts";
import CourseRegister from "./components/opengrowth/Courses";
import GrowthCopilot from "./components/opengrowth/Growths/GrowthCopilot.js";
import Page1 from "./components/functions/users/register/student/Page1.js";
import Page2 from "./components/functions/users/register/student/Page2.js";
import DashboardPopupsPage from "./components/functions/users/register/student/DashboardPopups.js";
import RegistrationDetails from "./components/functions/users/register/student/DashboardPopups.js";
import RegisterComplete from "./components/functions/users/register/student/PostRegistration.js";
import EntrepreneurDashboard from "./components/functions/dashboard/Entrepreneur/EntrepreneurDashboard.js";
import ExpertDashboard from "./components/functions/dashboard/Expert/ExpertDashboard.js";
import AdminDashboard from "./components/functions/profile/AdminCallTracker.js";
import { useLocation } from "react-router-dom";
// import EntrepreneurDashboard from "./components/functions/mentors/entrepreneur_dashboard/EntrepreneurDashboard.js";
// import ExpertDashboard from "./components/functions/mentors/expert_dashboard/ExpertDashboard.js";
// import DashboardPopups from "./components/functions/users/register/student/DashboardPopups.js";
// import RegistrationComplete from "./components/functions/users/register/student/PostRegistration.js";
// import StudentDashboard from "./components/functions/mentors/learner_dashboard/StudentDashboard.js";


let Wrapper = React.lazy(() => import('./components/functions/menus/Wrapper'));
const pathname = window.location.pathname;
const loginToken = localStorage.getItem('login_token');
if (pathname === '/' && loginToken !== null) {
  if (user_view === 'admin') {
    window.location.pathname = '/schedule-webinar';
  } else if (user_view === 'webinar') {
    window.location.pathname = '/og-webinars';
  } else if (user_view === 'academy') {
    window.location.pathname = '/dashboard';
  }
}

if (loginToken === 'admin') {
  Wrapper = React.lazy(() => import('./components/admin/sidebar/Wrapper'));
  // alert("admin")
} else if (loginToken !== null) {
  Wrapper = React.lazy(() => import('./components/functions/menus/Wrapper'));
  // alert("academy")
} else {
  Wrapper = React.lazy(() => import('./components/functions/homepage/wrapper/Wrapper'));
//   alert("homepage")
  // jan 9
  if (freshPages.includes(pathname)) {
    Wrapper = React.lazy(() => import('./components/functions/og/dummy/wrapper/Wrapper'));
  }
}


function get_routes (config_res){
	console.log(cookie.load('config'));
	console.log(config_res)
	let routes = [ 
		/* Community Starts */ 
	 { path: "/dashboard-hub",enable:community_nested['joined-community'].value,main: () =><DashboardHub /> },
	 { path: "/dashboard-community",enable:community_nested['joined-community'].value,main: () =><DashboardCommunity /> },
	 { path: "/joined-community",enable:community_nested['joined-community'].value,main: () =><JoinedCommunity /> },
	 { path: "/our-communities",enable:community_nested['our-communities'].value,main: () =><ListCommunity /> },
	 { path: "/manage-community",enable:community_nested['manage-community'].value,main: () =><AddCommunity /> },
	 { path: "/community-chat",enable:community_nested['community-chat'].value,main: () =><CommunityChat /> },
	 { path: "/community-join",enable:1,main: () =><CommunityJoinRequest /> },
	 { path: "/community",enable:community_nested['community'].value,main: () =><Community /> },
	 { path: "/connections",enable:community_nested['connections'].value,main: () =><Connections /> },
	// { path: "/discussion-forum",enable:community_nested['discussion-forum'].value,main: () =><DiscussionForum /> },
	 { path: "/discussion-forum",enable:community_nested['discussion-forum'].value,main: () =><GroupChat /> },
	// { path: "/group-chat",enable:community_nested['group-chat'].value,main: () =><GroupChat /> },
		/* Community Ends */
	 {exact:true, path: "/homepage",enable:true,main: () => <Homepage/>},
	 {exact:true, path: "/dashboard-popup",enable:true,main: () => <RegistrationDetails/>},
	 {exact:true, path: "/register-complete",enable:true,main: () => <RegisterComplete/>},
	//  {exact:true, path: "/",enable:1>0 || config_res && (config_res.filter(item => item.component === "signin").map(item => item.enable)[0])||false,main: () => <Homepage/> },
	 {exact:true, path: "/login",enable:1>0 || config_res && (config_res.filter(item => item.component === "signin").map(item => item.enable)[0])||false,main: () => <Homepage/> },
	 {exact:true, path: "/signin",enable:1>0 || config_res && (config_res.filter(item => item.component === "signin").map(item => item.enable)[0])||false,main: () => <OpenGrowthLogin/> },
	 { path: "/register/expertconnect",exact: true,enable:1>0 || config_res && (config_res.filter(item => item.component === "signup-learner").map(item => item.enable)[0])||false,main: () => <ExpertConnectRegister/> },
	 { path: "/register/courseconnect",exact: true,enable:1>0 || config_res && (config_res.filter(item => item.component === "signup-learner").map(item => item.enable)[0])||false,main: () => <CourseRegister/> },
	 { path: "/profile",enable:1>0 || config_res && (config_res.filter(item => item.component === "profile").map(item => item.enable)[0])||false,main: () => <Profile/> },
	 { path: "/my-profile",enable:1>0 || config_res && (config_res.filter(item => item.component === "profile").map(item => item.enable)[0])||false,main: () => <Profile/> },
	 { path: "/editprofile",enable:1>0 || config_res && (config_res.filter(item => item.component === "update-profile").map(item => item.enable)[0])||false,main: () => <EditProfile/> },
	 { path: "/linkedin", exact: true,enable:true,main: () => <LinkedInCallback/> },
	 { path: "/forgotpassword",enable:1>0 || config_res && (config_res.filter(item => item.component === "forgot-password").map(item => item.enable)[0])||false,main: () => <ForgotPassword/> },
	 { path: "/register/user",exact: true,enable:1>0 || config_res && (config_res.filter(item => item.component === "signup-learner").map(item => item.enable)[0])||false,main: () => <StudentRegister/> },
	 { path: "/register/expert",exact: true,enable:1>0 || config_res && (config_res.filter(item => item.component === "signup-mentor").map(item => item.enable)[0])||false,main: () => <ProfessorRegister/> },
	 { path: "/dashboard",exact: true,enable:true,main: () => <Dashboard/> },
	 { path: "/dashboard-entrepreneur",exact: true,enable:true,main: () => <EntrepreneurDashboard/> },
	 { path: "/dashboard-expert",exact: true,enable:true,main: () => <ExpertDashboard/> },
		/* course start*/
	 { path: "/dashboard-courses",exact: true,enable:true,main: () => <CourseDashboard/> },
	 { path: "/courses",enable:config.course.value,main: () => <Suspense fallback={<div><Vertical_Course_Skeleton /></div>}> <CourseViewNew/> </Suspense> },
	 { path: "/bookmarked",enable:true,main: () => <Suspense fallback={<div><Vertical_Course_Skeleton /></div>}> <CoursesBookmarked /> </Suspense> },
	 { path: "/popularcourses",enable:true,main: () => <Suspense fallback={<div><Vertical_Course_Skeleton /></div>}> <PopularCourse /> </Suspense>},
	 { path: "/recently-viewed",enable:true,main: () => <Suspense fallback={<div><Vertical_Course_Skeleton /></div>}> <ViewedRecently /> </Suspense> },
	 { path: "/findcourse",enable:true,main: () => <Suspense fallback={<div><Horizontal_Course_Skeleton /></div>}> <CourseSort /> </Suspense> },
	 { path: "/trending-courses",enable:true,main: () => <Suspense fallback={<div><Vertical_Course_Skeleton /></div>}> <TrendingCourses /> </Suspense> },
	 { path: "/searchcourse",enable:true,main: () => <Suspense fallback={<div><Vertical_Course_Skeleton /></div>}> <CourseTagsSearch /> </Suspense> },
	 { path: "/enrolledcourses",enable:true,main: () => <Suspense fallback={<div><Vertical_Course_Skeleton /></div>}> <CourseEnrolled/> </Suspense>},
	 { path: "/mycourses",enable:true,main: () => <MyCourses/>},
	 { path: "/timeline",enable:true,main: () => <Suspense fallback={<div><Calender_Skeleton /></div>}> <CalenderCard/> </Suspense> },
	 { path: "/orders", exact: course_nested["orders"].value,enable:true,main: () => <CartStepForm /> },
	 { path: "/course/add",enable:true,main: () => <Courses/> },
	 { path: "/course/update",enable:true,main: () => <Courses/> },
	 { path: "/course/view",enable:true,main: () => <Courses/> },
	 { path: "/build-course-structure",enable:true,main: () => <Courses/>},
	 { path: "/course-modules",enable:true,main: () => <Courses/>},
	 { path: "/module-assigments",enable:true,main: () => <Assignment/>},	/* course end */
		/* course end */
 
		// Outer pages --Shrithi 06/12/2024

	 {exact:true, path: "/expertpage",enable:false,main: () => <AllExperts/>},
	 {exact:false, path: "/allcourses",enable:1>0 || config_res && (config_res.filter(item => item.component === "allcourses").map(item => item.enable)[0])||false,main: () => <AllCourses/>},
	 {exact:true, path: "/expert-profile/:profile_url",enable:false,main: () => <ExpertProfile/>},
	 {exact:true, path: "/individualcourse",enable:false,main: () => <IndividualCourse/>},
	 {exact:true, path: "/businesscourse",enable:false,main: () => <BusinessCourse/>},
	 {exact:true, path: "/growthstudio",enable:false,main: () => <GrowthStudioHome/>},
	 {exact:true, path: "/growthassociate",enable:false,main: () => <GrowthAssociate/>},
	 {exact:true, path: "/growthcopilot",enable:false,main: () => <GrowthCopilot/>},
	 {exact:true, path: "/contentmarketing",enable:false,main: () => <ContentMarketingHome/>},
	 {exact:true, path: "/oghomepage",enable:true,main: () => <OgHomepage/>},
	 {exact:true, path: "/",enable:true,main: () => <OgHomepage/>},
	 {exact:true, path: "/ogaboutus",enable:true,main: () => <OpengrowthAboutUs/> },
	 {
		path: "/admin-call",
		exact: true,
		enable: admin_nested['call-requests']?.value, // Ensure 'call-requests' is defined in your config
		main: () => (
		  <Suspense fallback={<CircularProgress />}>
			<AdminDashboard />
		  </Suspense>
		)
	},

	//   Outer Pages route end --Shrithi 06/12/2024
	 // Inside the get_routes function, add the following route

	 
	 { path: "/course/:id", enable: true, main: () => <CourseDescription /> },

		/* mentor start */
	 { path: "/dashboard-mentorship",enable:mentor_nested && mentor_nested['dashboard-mentorship'].value,main: () => <MentorshipDashboard/>},
	 { path: "/request-to-be-mentor",enable:mentor_nested && mentor_nested['request-to-be-mentor'].value,main: () => <RequestMentor/>},
	 { path: "/manage-slots",enable:mentor_nested && mentor_nested['manage-slots'].value,main: () => <Slots/>},
	 { path: "/mentors-timeline",enable:mentor_nested && mentor_nested['mentors-timeline'].value,main: () => <MentorshipTimeline/>},
	 { path: "/recent-mentors",enable:mentor_nested && mentor_nested['recent-mentors'].value,main: () => <GetMentors action="recent" />},
	 { path: "/popular-mentors",enable:mentor_nested && mentor_nested['popular-mentors'].value,main: () => <GetMentors action="popular"/>},
	 { path: "/mentors-followed",enable:mentor_nested && mentor_nested['mentors-followed'].value,main: () => <GetMentors action="followed"/>},
	//  { path: "/appointments",exact:false,enable:mentor_nested && mentor_nested['appointments'].value,main: () => <Appointments/>}, //commented this --shrithi 05/12/2024
	{ path: "/appointment",exact:false,enable:mentor_nested && mentor_nested['appointments'].value,main: () => <Appointments/>},  //added this line --shrithi 05/12/2024
	 { path: "/my-mentors",enable:mentor_nested && mentor_nested['my-mentors'].value,main: () => <Suspense fallback={<div><ViewMentors_Skeleton /></div>}><MyMentors /></Suspense>},
	 { path: "/mentors-and-experts",enable:mentor_nested && mentor_nested['our-mentors'].value,main: () => <Suspense fallback={<div><ViewMentors_Skeleton /></div>}><OurMentors/></Suspense>},
	 { path: "/chatbox",enable:mentor_nested && mentor_nested['chatbox'].value,main: () => <Chat/> },
	 { path: "/payment",enable:true,main: () => <MentorsPayment/> },
		/* mentor end*/
	 
		/* Webinar Starts */ 
	 { path: "/dashboard-webinars",enable:config_res && (config_res.filter(item => item.component === "webinar").map(item => item.enable)[0])||false,main: () =><WebinarDashboard /> },
	 { path: "/og-webinars", exact:true,enable:config_res && (config_res.filter(item => item.component === "webinar").map(item => item.enable)[0])||false,main: () =><WebinarWaiting /> },
	 { path: "/og-webinars",enable:config_res && (config_res.filter(item => item.component === "webinar").map(item => item.enable)[0])||false,main: () =><WebinarRunning /> },
	 { path: "/webinars",enable:config_res && (config_res.filter(item => item.component === "webinar").map(item => item.enable)[0])||false,main: () =><Homepage /> },
	 { path: "/webinar-description",enable:config_res && (config_res.filter(item => item.component === "webinar").map(item => item.enable)[0])||false,main: () =><WebinarLanding/> },
	 { path: "/webinar-user",enable:config_res && (config_res.filter(item => item.component === "webinar").map(item => item.enable)[0])||false,main: () =><WebinarThanks /> },
	 { path: "/all-speakers",enable:config_res && (config_res.filter(item => item.component === "webinar").map(item => item.enable)[0])||false,main: () =><Speakers /> },
	 { path: "/workshop-login",exact:true,enable:config_res && (config_res.filter(item => item.component === "webinar").map(item => item.enable)[0])||false,main: () =><WebinarLogin_EmailOnly /> },
	 { path: "/webinar-register",enable:config_res && (config_res.filter(item => item.component === "webinar").map(item => item.enable)[0])||false,main: ()=><RegisterForm />},
	 { path: "/webinar-password",enable:config_res && (config_res.filter(item => item.component === "webinar").map(item => item.enable)[0])||false,main: () =><ForgotForm />},
	 /*{ path: "/webinar-login",exact:true,enable:true,main: () =><WebinarLogin />},*/ 
		/* webinar end */
		/* blog start */
	 { path: "/view-blogs",enable:blog_nested['view-blogs'].value,main: () => <AllBlogs/>},
	 { path: "/dashboard-blogs",enable:blog_nested['channels'].value,main: () => <Channels/>},
	 { path: "/channels",enable:blog_nested['channels'].value,main: () => <Channels/>},
	 { path: "/hub",enable:blog_nested['blogs'].value,main: () => <DashboardHub/>},
	 { path: "/blogs",enable:blog_nested['blogs'].value,main: () => <Blogs/>},
		/* blog end */
		/* Quiz Statrt */ 
	 { path: "/quiz/start",enable:quiz_nested['quiz/start'].value,main: () => <Quiz />},
	 { path: "/quiz/view",enable:quiz_nested['quiz/view'].value,main: () => <Quiz />},
	 { path: "/quiz/update",enable:quiz_nested['quiz/update'].value,main: () => <Quiz />},
	 { path: "/quiz/progress",enable:quiz_nested['quiz/progress'].value,main: () => <Quiz />},
	 { path: "/quiz",enable:quiz_nested['quiz'].value,main: () => <Quiz />},
		 /* Quiz Ends */ 
		 /* Admin Starts */
	 { path: "/growth-admin",exact: true,enable:true,main: () =><AdminLogin /> },
	 { path: "/manage-otp-module",enable:true,main: () => <ManageSettings />},
	 { path: "/manage-captcha-module",enable:true,main: () => <ManageSettings />},
	 { path: "/manage-signin-module",enable:true,main: () => <ManageSettings />},
	 { path: "/manage-signup-module",enable:true,main: () => <ManageSettings />},
	 { path: "/manage-forgot-password-module",enable:true,main: () => <ManageSettings />},
	 { path: "/manage-course-module",enable:true,main: () => <ManageSettings />},
	 { path: "/manage-mentorship-module",enable:true,main: () => <ManageSettings />},
	 { path: "/manage-community-module",enable:true,main: () => <ManageSettings />},
	 { path: "/manage-blogs-module",enable:true,main: () => <ManageSettings />},
	 { path: "/manage-messages-module",enable:true,main: () => <ManageSettings />},
	 { path: "/manage-webinar-module",enable:true,main: () => <ManageSettings />},
	 { path: "/execute-query",enable:admin_nested['settings'].value,main: () => <GeneralSettings />},
	 { path: "/university/add",enable:admin_nested['university'].value,main: () =><University/> },
	 { path: "/university/update",enable:admin_nested['university'].value,main: () => <University/> },
	 { path: "/university/view",enable:admin_nested['university'].value,main: () => <University/> },
	 { path: "/subscription/add",enable:admin_nested['subscription'].value,main: () => <Subscription/>},
	 { path: "/subscription/update",enable:admin_nested['subscription'].value,main: () => <Subscription/> },
	 { path: "/subscription/view",enable:admin_nested['subscription'].value,main: () => <Subscription/> },
	 { path: "/coupon/add",enable:admin_nested['coupon'].value,main: () => <Coupon/>},
	 { path: "/coupon/update",enable:admin_nested['coupon'].value,main: () => <Coupon/> },
	 { path: "/coupon/view",enable:admin_nested['coupon'].value,main: () => <Coupon/> },
	 { path: "/schedule-webinar",enable:admin_nested['webinar'].value,main: () =><ScheduleWebinar /> },
	 { path: "/update-speakers",enable:admin_nested['webinar'].value,main: () => <UpdateSpeakers /> },
	 { path: "/webinar-registration",enable:admin_nested['webinar'].value,main: () =><WebinarRegistration /> },
	 { path: "/webinar-visitors",enable:admin_nested['webinar'].value,main: () => <WebinarLogs /> },
	 { path: "/view-master-user",enable:admin_nested['logs'].value,main: () =><ModeratorsAdminList /> },
	 { path: "/view-attendees",enable:admin_nested['logs'].value,main: () =><Attendees /> },
	 { path: "/logs", exact: true,enable:admin_nested['logs'].value,main: () => <Logs />},
	 { path: "/admin-logs",exact: true,enable:admin_nested['logs'].value,main: () =><AdminLogs /> },
	 { path: "/view-users",enable:admin_nested['logs'].value,main: () =><RegisteredUserList /> },
	 { path: "/academy-users",enable:admin_nested['logs'].value,main: () =><AcademyUsers /> },
	 { path: "/all-users",enable:admin_nested['logs'].value,main: () =><AllUsers /> },
	//  { path: "/get-images",exact: true,enable:admin_nested['images'].value,main: () =><GetImages /> },
	//  { path: "/upload-og-images",exact: true,enable:admin_nested['images'].value,main: () =><PopupUpload /> },
		  /* Admin Ends */ 
		 /* Other Page */ 
	 { path: "/discord",exact: true,enable:community_nested['discord'].value,main: () =><DiscordPage /> },
	 { path: "/homepage-old",enable:true,main: () =><Homepage/> },
	 { path: "/about-us",enable:true,main: () =><About/> },
	 { path: "/privacy-policy",enable:true,main: () =><PrivacyPolicy/> },
	 { path: "/events",enable:true,main: () =><Event/> },
	 { path: "/womens-day",enable:true,main: () =><WomensDay/> },
	 { path: "/test",enable:true,main: () =><Test/> },
	 { path: "/manage-modules",enable:true,main: () =><ManageModules/> },
	 { path: "/googletest",enable:true,main: () =><GoogleTest/> },
	 {path: "",enable:true,main: () =><NotFound /> },
	 ]
	 return routes;
};


const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [config_res, setConfigRes] = useState(null);
  const [routes, setRoutes] = useState([]);
	 useEffect(() => {
		const handleBeforeUnload = () => {
		  cookie.remove('config');
		};
		window.addEventListener('beforeunload', handleBeforeUnload);
		return () => {
		  window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	  }, []);
	  	  
	  const getConfiguration = async () => {
	  try {
		const response = await axios.post('/api/get_configuration', { action: 'fetch' });
		const data = response.data;
		 console.log(data);

		if (typeof data === 'string') {
		  try {
			const jsonData = JSON.parse(data);
			console.log('Data is a string and has been converted to JSON:', jsonData);
			//const encryptedData = CryptoJS.AES.encrypt(jsonData, 'ac-key').toString();
		//	sessionStorage.setItem('config', encryptedData);
			cookie.save('config', jsonData, { path: '/' });
			console.log(jsonData);
			cookie.load('config');
		  } catch (error) {
			console.error('Failed to parse data as JSON:', error);
		  }
		} else {
		  console.log('Data is not a string');
		}
	  } catch (error) {
		console.error(error);
	  }
	};


  useEffect(() => {
   cookie.remove('config');
   const loadData = async () => {
      if (!cookie.load('config')) {
        await getConfiguration();
      } else {
        console.log("already have data in config cookie");
      }

      const configRes = cookie.load('config');
	  console.log(configRes)
	  setRoutes(get_routes(configRes))
      setConfigRes(configRes);
	  
	  console.log(config_res)
	 // alert(config_res && (config_res.filter(item => item.component === "profile").map(item => item.enable)[0])?11:12)
    };

    loadData();
  }, []);

  const ScrollToTop = () => {
	const { pathname } = useLocation();
  
	useEffect(() => {
	  // Scroll to the top of the window whenever the pathname changes
	  window.scrollTo(0, 0);
	}, [pathname]);
  
	return null; // This component doesn't render anything
  };
    
	//console.log(routes)
	const filteredRoute = useMemo(() => routes.filter(item => item.enable === true), []);
    return (
	<div>
			
     
    <CookiesProvider>

    <UserProvider >
    <ThemeProvider theme={Academytheme}>
  	<Suspense fallback={<div></div>}>
		<Router> 
		<ScrollToTop />   
		<Wrapper>
		<Switch>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                children={route.main}
              />
            ))}
          </Switch>
		  </Wrapper>
	  </Router>
	  </Suspense>
	</ThemeProvider >
	</ UserProvider >
   </CookiesProvider>
	</div>
  );
}
export default App;

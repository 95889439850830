// src/components/GrowthStudioHome.jsx

import React, { useState } from 'react';
import {
    Box,
    Typography,
    Card,
    Button,
    useMediaQuery,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    FormControl,
    CircularProgress,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { PopupButton } from 'react-calendly';
import useGlobalStyles from '../styles/CourseStyles';
import promoImageSrc from '../assets/expert1.webp';
import bannerImage from '../assets/Growth Copilot-coming soon.png';
import FeaturesSection from './FeatureSectionPilot';
import WhyHireGrowthAssociate from './WhyHireGrowthAssociate';

// Import slick-carousel CSS
import 'slick-carousel/slick/slick.css';
import TrendingBlogs from '../Experts/TrendingBlogs';
import { blogData } from '../Homepage/BlogData';

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
    mainCard: {
        boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
        borderRadius: '8px',
        overflow: 'hidden',
        margin: 'auto',
        border: 'none',
        padding: theme.spacing(3),
        paddingTop: theme.spacing(8),
        marginTop: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            padding: 8,
        },
    },
    bannerContainer: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        border: 'none',
        paddingLeft: '0rem',
        borderRadius: "4px",
        alignItems: 'flex-start',
        overflow: "hidden",
        width: '100%',
        [theme.breakpoints.up("md")]: {
            height: "auto",
        },
        [theme.breakpoints.down("sm")]: {
            height: "100%",
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    imageBox: {
        width: '100%',
        height: '500px',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            height: '700px'
        },
        position: 'relative',
    },
    bannerImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    contentOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        padding: theme.spacing(8),
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        zIndex: 2,
        [theme.breakpoints.down("sm")]: {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            padding: theme.spacing(2),
            flexDirection: 'column',
            textAlign: 'center',
        },
    },
    contentBox: {
        backgroundColor: 'transparent',
        color: "white",
        maxWidth: '40%',
        [theme.breakpoints.down("sm")]: {
            maxWidth: '100%',
            textAlign: 'left',
        },
    },
    bannerButton: {
        marginTop: theme.spacing(2),
        borderRadius: "50px",
        backgroundColor: "#f9bb02",
        color: "black",
        fontWeight: 600,
        fontSize: '1rem',
        padding: '8px 24px',
        textTransform: "none",
        cursor: 'pointer',
        "&:hover": {
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
            backgroundColor: "#f9bb02",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "0.8rem",
            padding: "6px 12px",
        },
    },
    subscribeRibbon: {
        backgroundColor: '#f9bb02',
        width: '100%',
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        bottom: 24,
        left: 0,
        zIndex: 4,
        overflow: 'hidden',
    },
    scrollText: {
        display: 'inline-block',
        whiteSpace: 'nowrap',
        animation: `$scroll 15s linear infinite`,
        color: "#25387c",
        // fontSize: '28px',
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.2rem',
        },
    },
    "@keyframes scroll": {
        "0%": {
            transform: "translateX(100%)",
        },
        "100%": {
            transform: "translateX(-100%)",
        },
    },
    h3font: {
        fontWeight: "500",
        color: '#fff',
        border: 'none',
        width: '713px',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            width: '100%', // Adjust width for smaller screens
        },
    },
    promoCardContainer: {
        display: 'flex',
        backgroundColor: '#25387c',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        borderRadius: '4px',
        border: 'none',
        marginTop: theme.spacing(6),
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(0),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    promoCard: {
        display: 'flex',
        border: 'none',
        flexDirection: 'column',
        backgroundColor: '#25387c',
        color: '#fff',
        padding: theme.spacing(4),
        borderRadius: '12px',
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: theme.spacing(1.5),
            textAlign: 'center',
        },
    },
    promoImage: {
        width: '400px',
        height: '100%',
        marginLeft: theme.spacing(4),
        borderRadius: '8px',
        [theme.breakpoints.down('sm')]: {
            width: '200px',
            marginLeft: 0,
            marginTop: theme.spacing(3),
        },
        objectFit: 'cover',
    },
    promoTitle: {
        fontSize: '1.8rem',
        fontWeight: 'bold',
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.4rem',
        },
    },
    promoSubtitle: {
        fontSize: '1rem',
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem',
        },
    },
}));

// BannerSlide Component
const BannerSlide = () => {
    const classes = useStyles();
    const styleClasses = useGlobalStyles();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

    // State for subscribe modal
    const [openSubscribe, setOpenSubscribe] = useState(false);
    const [email, setEmail] = useState('');
    const [subscriptionStatus, setSubscriptionStatus] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleOpenSubscribe = () => {
        setOpenSubscribe(true);
    };

    const handleCloseSubscribe = () => {
        setOpenSubscribe(false);
        setSubscriptionStatus(null);
        setEmail('');
        setIsLoading(false);
    };

    const handleSubscribe = async () => {
        setIsLoading(true);
        try {
            // Simulate API call
            await new Promise((resolve) => setTimeout(resolve, 1000));
            setSubscriptionStatus('success');
            setEmail('');
        } catch (error) {
            console.error('Subscription error:', error);
            setSubscriptionStatus('error');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Box className={`${styleClasses.bannerContainer} ${classes.bannerContainer}`}>
                {/* Banner Image */}
                <Box className={classes.imageBox}>
                    <img
                        src={bannerImage}
                        alt="Growth Studio Banner"
                        className={classes.bannerImage}
                    />

                    {/* Content Overlay */}
                    <Box className={classes.contentOverlay}>
                        <Box className={classes.contentBox}>
                            <Typography
                                variant={isSmall ? "h4" : "h1"}
                                gutterBottom
                                className={`${styleClasses.h3font} ${classes.h3font}`}
                            >
                                <span className={styleClasses.span}>Growth Co-pilot</span>
                            </Typography>
                            <Typography variant="h6" className={`${styleClasses.h6banner} ${classes.h6}`}>
                                Your Ultimate AI Partner for
                                Business Growth and Scaling
                                Solutions
                            </Typography>
                            {/* Optional: Add a button inside the overlay if needed */}
                            {/* <Button className={classes.bannerButton} onClick={handleOpenSubscribe}>
                                Subscribe Now
                            </Button> */}
                        </Box>
                    </Box>

                    {/* Subscribe Ribbon with Scrolling Text */}
                    <Box className={classes.subscribeRibbon}>
                        <Typography className={`${classes.scrollText} ${styleClasses.h5fontPara}`}>
                            COMING SOON • COMING SOON • COMING SOON • COMING SOON • COMING SOON •
                        </Typography>
                    </Box>
                </Box>

                {/* Subscribe Modal */}
                <Dialog open={openSubscribe} onClose={handleCloseSubscribe}>
                    <DialogTitle>Subscribe to Our Newsletter</DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth>
                            <TextField
                                label="Email Address"
                                type="email"
                                variant="outlined"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                aria-label="Email Address"
                            />
                        </FormControl>
                        {isLoading && (
                            <Box display="flex" justifyContent="center" mt={2}>
                                <CircularProgress />
                            </Box>
                        )}
                        {subscriptionStatus === 'success' && (
                            <Typography color="primary" align="center" style={{ marginTop: theme.spacing(2) }}>
                                Successfully subscribed!
                            </Typography>
                        )}
                        {subscriptionStatus === 'error' && (
                            <Typography color="error" align="center" style={{ marginTop: theme.spacing(2) }}>
                                Subscription failed. Please try again.
                            </Typography>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseSubscribe} color="primary">
                            Cancel
                        </Button>
                        <Button
                            onClick={handleSubscribe}
                            color="primary"
                            variant="contained"
                            disabled={!email || isLoading}
                        >
                            Subscribe
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </>
    );
};

// GrowthCopilot Component
const GrowthCopilot = () => {
    const classes = useStyles();
    const styleClasses = useGlobalStyles();
    const theme = useTheme();
    const history = useHistory();
    const [calendlyUrl, setCalendlyUrl] = useState('https://calendly.com/vedika-5m0y/30min?month=2024-10');

    return (
        <Box style={{ padding: '8px', paddingTop: 0 }}>
            {/* Banner Carousel */}
            <BannerSlide calendlyUrl={calendlyUrl} />

            <FeaturesSection />
            {/* Promotional Card */}

            <TrendingBlogs context="copilot" blogs={blogData} />
            
        </Box>
    );
};

export default GrowthCopilot;

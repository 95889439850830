// RegistrationDetails.js

import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import Page1 from './Page1'; // Ensure correct paths
import Page2 from './Page2';
import { useHistory, useLocation } from 'react-router-dom';

// Added by shrithi on 2023-10-11

/**
 * RegistrationDetails Component
 *
 * This component displays Page1 and Page2 sequentially.
 * After Page2 is completed, it calls the registerUser function
 * and redirects to the dashboard.
 */
const RegistrationDetails = () => {
  const [step, setStep] = useState(1);
  const [userType, setUserType] = useState('');
  const [page2Data, setPage2Data] = useState({ courses: [], experts: [] });
  const history = useHistory();
  const location = useLocation();

  const formData = location.state?.formData || {};

  const handleNext = () => {
    if (step < 2) {
      setStep(step + 1);
    } else {
      // All steps completed, proceed to registerUser
      registerUser();
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleSkipAll = () => {
    // Skip to the end and register user
    registerUser();
  };

  const handleDataChange = (value) => {
    if (step === 1) {
      setUserType(value);
    } else if (step === 2) {
      setPage2Data(value);
    }
  };

  const registerUser = () => {
    // Call the registerUser function from StudentRegister
    // You can use the history state to pass data back
    history.replace('/register-complete', {
      formData: formData,
      additionalData: { userType, page2Data }
    });
  };

  return (
    <Box style={{ backgroundColor: '#ffffff', minHeight: '100vh' }}>
      {step === 1 && (
        <Page1
          data={userType}
          onDataChange={handleDataChange}
          onNext={handleNext}
          onSkip={handleSkipAll}
          showBackButton={false}
        />
      )}
      {step === 2 && (
        <Page2
          data={page2Data}
          onDataChange={handleDataChange}
          onNext={handleNext}
          onBack={handleBack}
          onSkip={handleSkipAll}
          showBackButton={true}
        />
      )}
    </Box>
  );
};

export default RegistrationDetails;

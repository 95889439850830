// src/components/ImageCarousel.js

import React from 'react';
import Slider from 'react-slick';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

// Import your images
import card1 from "../assets/About us/about us 1.png";
import card2 from "../assets/About us/about us 2.png";
import card3 from "../assets/About us/about us 3.png";
import card4 from "../assets/About us/about us 4.png";
import card5 from "../assets/About us/about us 5.png";
import card6 from "../assets/About us/about us 6.png";
import card7 from "../assets/About us/about us 7.png";
import card8 from "../assets/About us/about us 8.png";
// Add more images as needed

const useStyles = makeStyles((theme) => ({
    sliderContainer: {
        position: 'relative',
        padding: theme.spacing(0, 0),
        backgroundColor: '#ffffff',
        // marginTop: '64px',
        borderRadius: '8px',
        overflow: 'hidden', // Hide any overflowing content
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2, 0),
        },
        '& .slick-list': {
            overflow: 'visible', // Allow slides to overflow for partial visibility
            padding: '0 20px', // Optional: Add padding to the slick list
            [theme.breakpoints.down("sm")]: {
                overflow: 'hidden', // Reset overflow for small screens
                padding: '0 10px 0 0', // Adjust padding for smaller screens
            },
        },
        '& .slick-slide': {
            padding: '0 15px', // Increased space between slides
            transition: 'transform 0.3s ease, opacity 0.3s ease',
            opacity: 0.6,
            [theme.breakpoints.down("sm")]: {
                padding: '0 10px', // Reduce padding on small screens
                opacity: 1, // Remove transparency on small screens
                transform: 'none', // Remove any transformations
            },
        },
        '& .slick-center': {
            transform: 'translateY(-10px) scale(1.1)',
            opacity: 1,
            [theme.breakpoints.down("sm")]: {
                transform: 'none', // Remove transformation on small screens
            },
        },
        '& .slick-prev, & .slick-next': {
            zIndex: 1, // Ensure arrows are above other elements
            [theme.breakpoints.down("sm")]: {
                display: 'none', // Hide arrows on small screens
            },
        },
    },
    imageContainer: {
        paddingLeft: '20px', // Optional: Add padding around each image
        paddingRight: '20px', // Optional: Add padding around each image
        [theme.breakpoints.down("sm")]: {
            paddingLeft: '10px',
            paddingRight: '10px',
        },
    },
    image: {
        width: '100%',
        height: 'auto',
        borderRadius: '8px',
        objectFit: 'cover',
        transition: 'transform 0.3s ease',
        margin: '24px auto', // Center the image
        display: 'block',
        [theme.breakpoints.down("sm")]: {
            borderRadius: '5px',
            height: '100%',
        },
    },
    link: {
        textDecoration: 'none', // Remove underline from links
    },
}));

const ImageCarousel = () => {
    const classes = useStyles();

    // Carousel data with images and corresponding links
    const carouselData = [
        {
            image: card1,
            alt: "Content Marketing",
            link: "/contentmarketing", // Route for card1
        },
        {
            image: card2,
            alt: "Associate",
            link: "/expertpage", // Route for card2
        },
        {
            image: card3,
            alt: "Expert Page",
            link: "/growthassociate", // Route for card3
        },
        {
            image: card4,
            alt: "All Courses",
            link: "/contentmarketing", // Route for card4
        },
        {
            image: card5,
            alt: "All Courses",
            link: "/growthstudio", // Route for card4
        },
        {
            image: card6,
            alt: "All Courses",
            link: "/growthcopilot", // Route for card4
        },
        {
            image: card7,
            alt: "All Courses",
            link: "/allcourses", // Route for card4
        },
        {
            image: card8,
            alt: "All Courses",
            link: "/oghomepage", // Route for card4
        },
        // Add more items as needed
    ];

    const settings = {
        dots: false, // Disable dots initially; we'll handle dots in styles
        infinite: true,
        speed: 500,
        slidesToShow: 1, // Show one image at a time
        slidesToScroll: 1,
        centerMode: true, // Enable center mode
        centerPadding: '15%', // Show 15% of previous and next slides
        autoplay: true, // Enable autoplay
        autoplaySpeed: 3000, // 3 seconds
        arrows: true, // Show next and prev arrows
        pauseOnHover: true, // Pause autoplay on hover
        responsive: [
            {
                breakpoint: 768, // Tablet breakpoint
                settings: {
                    slidesToShow: 1,
                    centerMode: false, // Disable center mode on smaller screens
                    arrows: false, // Hide arrows on smaller screens
                    centerPadding: '0px',
                }
            },
            {
                breakpoint: 480, // Mobile breakpoint
                settings: {
                    slidesToShow: 1,
                    centerMode: false,
                    arrows: false,
                    centerPadding: '0px',
                }
            }
        ],
        // Optional: Customize dots styling
        appendDots: dots => (
            <div>
                <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
        ),
        customPaging: i => (
            <div
                style={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    background: "#f9bb02",
                    display: "inline-block",
                }}
            ></div>
        )
    };

    return (
        <div className={classes.sliderContainer}>
            <Slider {...settings}>
                {carouselData.map((item, index) => (
                    <div key={index} className={classes.imageContainer}>
                        <Link to={item.link} className={classes.link}>
                            <img
                                src={item.image}
                                alt={item.alt}
                                className={classes.image}
                            />
                        </Link>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default ImageCarousel;
